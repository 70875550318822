<template>
  <DefaultLayout>
    <div class="w-full mx-auto max-w-4xl">
      <h1 class="main-heading text-left md:text-center pt-16">
        {{ t("profileSettings") }}
      </h1>
      <p class="w-full md:w-1/2 mx-auto sub-heading text-left md:text-center py-4">
        {{ t("profilesubTitle") }}
      </p>
      <div class="w-full grid gap-8 pb-12">
        <ProfileLoginData />
        <ProfilePersonalData />
        <ProfileBankData />
        <ProfilePayTime />
        <ProfileMarkting />
        <ProfileTwoFactor />
        <ProfileSessions />
        <ProfileFeedback />
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import {useStore} from "vuex";

import translateMixin from "@/mixins/translate.js";
import DefaultLayout from "@/components/layouts/Default";
import ProfileLoginData from "@/components/profile/ProfileLoginData";
import ProfilePersonalData from "@/components/profile/ProfilePersonalData";
import ProfileBankData from "@/components/profile/ProfileBankData";
import ProfilePayTime from "@/components/profile/ProfilePayTime";
import ProfileMarkting from "@/components/profile/ProfileMarkting";
import ProfileTwoFactor from "@/components/profile/ProfileTwoFactor";
import ProfileSessions from "@/components/profile/ProfileSessions";
import ProfileFeedback from "@/components/profile/ProfileFeedback";
export default {
  mixins: [translateMixin],
  components: {
    DefaultLayout,
    ProfileLoginData,
    ProfilePersonalData,
    ProfileBankData,
    ProfilePayTime,
    ProfileMarkting,
    ProfileTwoFactor,
    ProfileSessions,
    ProfileFeedback,
  },
  setup() {
    const store = useStore();
    store.dispatch("getUser");
    store.dispatch("payOut/getPayout");
    store.dispatch("marketingChannels/getMarketingChannels");
    store.dispatch("profile/getSessions");
    store.dispatch("twoFactorAuth/getTwoFactor");

    return {
    };
  },
};
</script>
