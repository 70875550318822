<template>
  <div class="w-full rounded flex border border-gray-400 bg-gray-50 p-4 lg:p-6 flex-col">
    <div class="w-full flex justify-between">
      <div class="w-9/12 text-lg font-semibold">{{ title }}</div>
      <div v-if="editbar" @click="openEditModal" class="w-2/12 flex justify-end">
        <Icon name="edit-pin" class="w-5 md:w-6 h-5 md:h-6 flex cursor-pointer" />
      </div>
    </div>
    <slot name="content"></slot>
    <Teleport to="#global-modal" v-if="showEditModal">
      <div class="w-full global-modal-wrapper">
        <div
          class="w-11/12 my-2 py-4 md:w-1/2 2xl:w-1/3 max-h-screen  text-center flex flex-col relative overflow-auto"
        >
        <div class="w-full h-full rounded bg-white shadow-lg flex p-4 md:p-6 relative">
          <div
            @click="closeEditModal"
            class="w-4 h-4 absolute top-4 right-4 cursor-pointer"
          >
            <Icon name="close" class="w-4 h-4 flex" />
          </div>
          <slot name="modal"></slot>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { ref } from "vue";
import Icon from "@/components/atoms/Icon";
export default {
  components: {
    Icon,
  },
  props: {
    title: { type: String, default: "" },
    editbar: { type: Boolean, default: false },
  },
  setup() {
    const showEditModal = ref(false);
    function openEditModal() {
      showEditModal.value = true;
      document.body.style.overflow = "hidden";
    }
    function closeEditModal() {
      showEditModal.value = false;
      document.body.style.overflow = "auto";
    }

    return {
      showEditModal,
      openEditModal,
      closeEditModal,
    };
  },
};
</script>

<style></style>
