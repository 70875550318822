<template>
  <Teleport to="#global-notification" class="transform ease-out duration-300 transition">
    <div
      class="fixed inset-0 z-50 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
    >
      <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
        <div class="rounded-lg shadow-xs overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <div v-show="type == 'success'">
                  <Icon
                    name="success"
                    stroke="currentColor"
                    fill="none"
                    class="w-6 h-6 text-green-500"
                  />
                </div>
                <div v-show="type == 'error'">
                  <Icon name="warning" class="w-6 h-6 text-red-500" />
                </div>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  v-html="message"
                  class="text-sm leading-5 font-medium text-gray-800"
                ></p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="$emit('close')"
                  class="flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                >
                  <Icon name="x" class="w-3 h-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { onMounted } from "vue";
import Icon from "@/components/atoms/Icon";
export default {
  components: {
    Icon,
  },
  props: {
    // two types: error & success 
    type: { type: String, default: "" },
    message: { type: String, default: "" },
  },
  setup(props, { emit }) {
    // will be closed automatically after 3,5 seconds
    onMounted(() => {
      setTimeout(() => {
        emit("close");
      }, 3500);
    });
  },
};
</script>

