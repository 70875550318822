<template>
    <label :for="value" class="radio-input">
        <input
            :aria-label="value"
            @change="(e)=> $emit('update:modelValue', e.target.value)"
            @input="(e)=> $emit('update:modelValue', e.target.value)"
            :checked="isChecked"
            :id="value"
            :value="value"
            :name="name"
            type="radio"
        >
        <span v-html="label" class="pl-8 flex" />
        <span class="radio-checkmark flex">
            <div v-show="isChecked" class="w-5 h-5 p-1 mt-px text-white items-center flex justify-center">
                <Icon name="check" class="w-full stroke-current"/>
            </div>
        </span>
    </label>
</template>
<script>
import Icon from "@/components/atoms/Icon";
import { computed } from 'vue'
export default {
    components: {
        Icon
    },
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        value: { type: [Object, String, Array], default: () => {} },
        label: { type: [Number, String], default: 0 },
        name: { type: String, default: '' },
        error: { type: Boolean, default: false },
        modelValue: [Number, String],
    },
    setup(props) {
        const isChecked = computed(() => {
            return props.modelValue == props.value
        })

        return {
            isChecked
        }
    }
}
</script>
