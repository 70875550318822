<template>
  <div class="w-full grid justify-center items-center gap-2 grid-flow-col auto-cols-max">
    <div
      @click="setPage(1)"
      class="pagination-button"
      :class="currentPage == 1 ? 'disabled' : 'enabled'"
    >
      <Icon name="double-left-chev" class="w-5" />
    </div>
    <div
      @click="previousPage"
      class="pagination-button"
      :class="currentPage == 1 ? 'disabled' : 'enabled'"
    >
      <Icon name="cheveron-left" />
    </div>
    <div
      v-show="
        (page >= currentPage && page <= currentPage + 2) ||
        (page <= currentPage && page >= currentPage - 2)
      "
      v-for="(page, index) in pages"
      :key="index"
      @click="setPage(page)"
      class="pagination-button"
      :class="page == currentPage ? 'active' : 'enabled'"
    >
      {{ page }}
    </div>
    <div
      @click="nextPage"
      class="pagination-button"
      :class="currentPage == pages ? 'disabled' : 'enabled'"
    >
      <Icon name="cheveron-right" />
    </div>
    <div
      @click="setPage(pages)"
      class="pagination-button"
      :class="currentPage == pages ? 'disabled' : 'enabled'"
    >
      <Icon name="double-right-chev" class="w-5" />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import Icon from "@/components/atoms/Icon";
export default {
emits: ["setCurrentPage"],
  components: {
    Icon,
  },
  props: {
    currentPage: { type: [String, Number], default: 1 },
    pages: {
      type: [String, Number, Array],
      default: () => 10,
    },
  },
  setup(props, { emit }) {
    const activePage = ref(1);
    function setPage(val) {
      activePage.value = val;
    }
    function nextPage() {
      if (activePage.value < props.pages) {
        activePage.value++;
      }
    }
    function previousPage() {
      if (activePage.value > 1) {
        activePage.value--;
      }
    }
    watch(
      () => activePage.value,
      () => {
        emit("setCurrentPage", activePage.value);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
      }
    );
    watch(
        () => props.currentPage,
        () => {
           activePage.value = props.currentPage;
        }
    );
    return {
      activePage,
      setPage,
      nextPage,
      previousPage,
    };
  },
};
</script>

<style>
.pagination-button {
  @apply w-8 h-8 flex rounded border items-center justify-center text-sm font-semibold;
}
.pagination-button.enabled:hover {
  @apply bg-gray-100;
}
.pagination-button.active {
  @apply border-gray-600 cursor-not-allowed;
}
.pagination-button.disabled {
  @apply bg-gray-300 border-gray-300 text-gray-50 cursor-not-allowed;
}
.pagination-button.enabled {
  @apply cursor-pointer;
}
</style>
