<template>
  <div :class="background" class="w-auto text-center py-1 px-4 rounded-full select-none">
    <p class="font-bold"><slot></slot></p>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: "bg-secondary-200",
    },
  },
};
</script>
