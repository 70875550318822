import axios from 'axios'
import Router from "../../router";
import {logIn, logOut} from "../auth/auth";

const baseURL = process.env.VUE_APP_BASE_URL
const codeUuid = process.env.VUE_APP_CODE_UUID

const User = {
    state: {
        user: {},
        userReports: [],
        currentCode: localStorage.currentCode ? localStorage.currentCode : "",
    },
    getters: {
        user: state => {
            return state.user
        },
        userReports: state => {
            return state.userReports
        },
        currentCode: state => {
            return state.currentCode
        }, 
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setMarketingChannels(state, channels) {
            state.marketingChannels = channels
        },
        setPayOutData(state, payload) {
            state.payOutData = payload;
        },
        setUserReports(state, payload) {
            state.userReports = payload; 
        },
        setCurrentCode(state, payload) {
            state.currentCode = payload;
            localStorage.setItem('currentCode', payload)
        }
    },
    actions: {
        getUser({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/spaApi/user`)
                    .then((response) => {
                        const user = response.data.data
                        commit('setUser', user)
                        const config = {
                            isVerify: String(user.is_verify),
                            isCompleted: String(user.is_completed),
                            isLoggedIn: String(true)
                        }
                        logIn(config);
                        resolve(response.data)
                    }).catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        logOut();
                        Router.push({path: '/login'})
                    }
                    reject(error);
                })
            })
        },
        setPersonalData({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`${baseURL}/spaApi/user`, {
                    company: payload['company'],
                    street: payload.street,
                    house_no: payload.house_no,
                    zip: payload.zip,
                    city: payload.city,
                    additional_address: payload.additional_address,
                    vat_no: payload.vat_no,
                }).then((response) => {
                    commit('setUser', response.data.data)
                    resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = {};
                    if ('company' in errors) {
                        errorMessages['company'] = errors['company'][0]
                    }
                    if ('street' in errors) {
                        errorMessages['street'] = errors['street'][0]
                    }
                    if ('house_no' in errors) {
                        errorMessages['house_no'] = errors['house_no'][0]
                    }
                    if ('zip' in errors) {
                        errorMessages['zip'] = errors['zip'][0]
                    }
                    if ('city' in errors) {
                        errorMessages['city'] = errors['city'][0]
                    }
                    if ('additional' in errors) {
                        errorMessages['addressLine2'] = errors['additional'][0]
                    }
                    reject(errorMessages)
                })

            })
        },
        setMoreInfo({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`${baseURL}/spaApi/user`, {
                    self_intro: payload.self_intro,
                    marketing_plan: payload.marketing_plan,
                    industry: payload.industry,
                }).then((response) => {
                    commit('setUser', response.data.data)
                    resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if ('self_intro' in errors) {
                        errorMessages['self_intro'] = errors['self_intro'][0]
                    }
                    if ('marketing_plan' in errors) {
                        errorMessages['marketing_plan'] = errors['marketing_plan'][0]
                    }
                    if ('industry' in errors) {
                        errorMessages['industry'] = errors['industry'][0]
                    }
                    reject(errorMessages)
                })

            })
        },
        sendRegistrationCode({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/spaApi/codes`, {
                    code_uuid: codeUuid,
                    code: payload.code,
                }).then((response) => {
                    commit("setCurrentCode", payload.code);
                    resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if (errors && 'code' in errors) {
                        errorMessages = errors['code'];
                    }
                    if (errors && 'code_type' in errors) {
                        errorMessages = errors['code_type'];
                    }
                    if ('code_uuid' in errors) {
                        errorMessages = errors['code_uuid'];
                    }
                    reject(errorMessages)
                })
            })
        },
        async getUserReports({commit}, payload) {
            try {
                const response = await axios.get(`${baseURL}/spaApi/user-report`, {
                    params: {
                        date_from: payload.date_from,
                        date_to: payload.date_to,
                        page:payload.page,
                        perPage:payload.perPage,
                    }
                });
                const reports = {
                    meta:response.data.meta,
                    data:response.data.reports.data,
                }
                commit('setUserReports', reports);
                return response.data
            } catch (e) {
                return e.response;
            }
        },
        async getUserCode({commit}) {
            try {
                const response = await axios.get(`${baseURL}/spaApi/codes`);
                commit('setCurrentCode', response.data.data.code);
            } catch (e) {
                commit('setCurrentCode', null);
                return e.response;
            }
        },
        sendCodeGenerate() {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/spaApi/generate-code`, {
                }).then((response) => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setAccepted({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`${baseURL}/spaApi/user`, {
                    terms_accepted: payload['terms_accepted'],

                }).then((response) => {
                    commit('setUser', response.data.data)
                    const config = {
                        isVerify: String(true),
                        isCompleted: String(true),
                        isLoggedIn: String(true)
                    }
                    logIn(config);
                    resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if ('terms_accepted' in errors) {
                        errorMessages['terms_accepted'] = errors['terms_accepted'][0]
                    }
                    reject(errorMessages)
                })

            })
        },
    },

}

export default User
