<template>
  <DefaultLayout>
    <div class="w-full pb-16 text-sm md:text-base">
      <div class="py-8">
        <h1 class="text-3xl font-bold text-gray-800 leading-tight py-8">Impressum</h1>
        <div class="-spacing-outer-30">
          <h3 class="text-2xl font-semibold text-gray-800 leading-tight py-1">
            Angaben gemäß § 5 TMG
          </h3>
          <p>
            BKF Online-Schulungs GmbH<br />Carl-Friedrich-Gauß Str. 8<br />72202 Nagold
          </p>
          <p>Amtsgericht: Stuttgart HRB 77 53 68</p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <p><strong>Vertreten durch:</strong><br />Christian Stickel</p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <h3 class="text-2xl font-semibold text-gray-800 leading-tight py-1">Kontakt</h3>
          <p>
            Telefon: +49 (0) 7452 8479 – 0<br />Telefax: +49 (0) 7452 8479 – 29<br />E-Mail:
            info@bkf-online-schulungen.de
          </p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <h3 class="text-2xl font-semibold text-gray-800 leading-tight py-1">
            Umsatzsteuer-ID
          </h3>
          <p>
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />DE338311583
          </p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <h3 class="text-2xl font-semibold text-gray-800 leading-tight py-1">
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV
          </h3>
          <p>Christian Stickel</p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <h3 class="text-2xl font-semibold text-gray-800 leading-tight py-1">
            EU-Streitschlichtung
          </h3>
          <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
            (OS) bereit:
            <a
              href="https://ec.europa.eu/consumers/odr"
              target="_blank"
              rel="noreferrer noopener"
              >https://ec.europa.eu/consumers/odr</a
            >.<br />Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <h3 class="text-2xl font-semibold text-gray-800 leading-tight py-1">
            Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
          </h3>
          <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
            einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <h4 class="text-xl font-semibold text-gray-800 leading-tight py-1">
            Haftung für Inhalte
          </h4>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
            Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
            wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
            forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          </p>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
            nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
            Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <h4 class="text-xl font-semibold text-gray-800 leading-tight py-1">
            Haftung für Links
          </h4>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte
            wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
            keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
            jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
            Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
            überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
            konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
            Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
            entfernen.
          </p>
          <div aria-hidden="true" class="w-full h-5"></div>
          <h4 class="text-xl font-semibold text-gray-800 leading-tight py-1">
            Urheberrecht
          </h4>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
            unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
            Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
            Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
            bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
            nicht kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
            werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
            als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
            Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
            umgehend entfernen.
          </p>
          <div aria-hidden="true" class="h-20 w-full"></div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>
<script>
import translateMixin from "@/mixins/translate.js";
import DefaultLayout from "@/components/layouts/Default";
export default {
  mixins: [translateMixin],
  components: {
    DefaultLayout,
  },
};
</script>
