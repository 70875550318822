<template>
  <Header />
  <div class="w-full flex min-h-layout content">
<!--    <transition name="fade">-->
<!--      <router-view />-->
<!--    </transition>-->
    <router-view v-slot="{ Component }">
      <transition name="slide">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <Footer />
  <CookieBanner />
</template>
<script>
import Footer from "@/components/layouts/Footer";
import Header from "@/components/layouts/Header";
import CookieBanner from "@/components/atoms/CookieBanner";
import { useRoute } from "vue-router";
import { watch } from "vue";

export default {
  name: "Layout",
  components: {
    Footer,
    Header,
    CookieBanner,
  },
  setup() {
    const route = useRoute();
    watch(
      () => route.name,
      () => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      }
    );
  },
};
</script>


<style>
  .wrapper {
    width: 100%;
    min-height: 100vh;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease-out;
  }


  .slide-enter-to {
    position: absolute;
    right: 0;
  }


  .slide-enter-from {
    position: absolute;
    right: -100%;
  }


  .slide-leave-to {
    position: absolute;
    left: -100%;
  }


  .slide-leave-from {
    position: absolute;
    left: 0;
  }
</style>
