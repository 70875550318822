<template>
  <DefaultLayout>
    <div class="w-full flex flex-col max-w-lg mx-auto">
      <h1 class="main-heading text-left md:text-center pt-16 md:pt-32">
        {{ t("contact") }}
      </h1>
      <p class="sub-heading text-left md:text-center pt-1 md:pt-3">
        {{ t("contactText") }}
      </p>
      <div v-if="contactSent" class="w-full py-2 text-sm text-green-500">
        <p>{{ t("yourQuestionSent") }}</p>
        <p class="font-semibold">{{ t("weWillContactYouSoon") }}</p>
      </div>
      <form v-show="!loading" @submit.prevent="send" class="w-full grid gap-4 grid-cols-1 pt-8 pb-20">
        <div class="w-full grid gap-4 grid-cols-1 md:grid-cols-2">
          <TextInput
            type="email"
            :placeholder="t('emailAddress')"
            :label="t('emailAddress')"
            required
            v-model="contactForm.email"
            name="email"
            :errorMessage="errors['email']"
          />
          <TextInput
            :placeholder="t('name')"
            :label="t('name')"
            required
            v-model="contactForm.name"
            name="name"
            :errorMessage="errors['name']"
          />
        </div>
        <Textarea
          :placeholder="t('howCanWeHelpYou')"
          required
          v-model="contactForm.message"
          :maxlength="500"
          name="message"
          :rows="6"
          :errorMessage="errors['message']"
        />
        <div class="w-full">
          <CheckboxInput
            v-model="contactForm.policy"
            :value="true"
            name="policy"
            :errorMessage="errors['policy']"
            class="w-auto block"
          >
            <p class="text-sm block items-center">
              Ich habe die
              <router-link to="/policy" class="text-blue-500 underline inline-block">
                Datenschutzbestimmungen
              </router-link>
              gelesen und akzeptiere sie.
            </p>
          </CheckboxInput>
        </div>
        <div class="w-full sm:w-1/2 mx-auto mt-8">
          <Button type="submit" primary :text="t('sendMessage')" class="w-full" />
        </div>
      </form>
      <div v-if="loading" class="w-full pb-48 pt-6">
        <Loader />
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import Loader from "@/components/atoms/Loader";
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex"
import DefaultLayout from "@/components/layouts/Default";
import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/TextInput";
import CheckboxInput from "@/components/forms/CheckboxInput";
import Textarea from "@/components/forms/Textarea";
import {isLoggedIn} from "@/store/auth/auth";
export default {
  mixins: [translateMixin],
  components: {
    DefaultLayout,
    TextInput,
    Textarea,
    CheckboxInput,
    Button,
    Loader
  },
  setup() {
    const store = useStore();
    const contactSent = ref(false);
    const loading = ref(false)
    const contactForm = ref(
      { name: "" },
      { email: "" },
      { message: "" },
      { policy: false }
    );
    const {
      errors,
      hasErrors,
      validateEmail,
      validateText,
      validateMinLength,
      validateCheckBox,
    } = useValidation();

    function validateAll() {
      errors.value = {};
      validateEmail(contactForm.value.email);
      validateText("name", contactForm.value.name);
      validateMinLength("message", 25, contactForm.value.message);
      validateCheckBox("policy", contactForm.value.policy, "errors.policyMustAccepted");
    }
    onBeforeMount(() => {
      let user = null
      // if user logged in, then fill the email & name automatically
      if(isLoggedIn())
        store.dispatch("checkUser").then((response) => {
          user = response;
          if(user && user.email && user.first_name && user.last_name) {
            contactForm.value.name = user.first_name + ' ' + user.last_name;
            contactForm.value.email = user.email;
          }
        })
    })

    async function send() {
      validateAll();
      // if there is no error sent the contact-form
      if (!hasErrors.value) {
        loading.value = true
        try {
          await store.dispatch("sendContact", {
            name: contactForm.value.name,
            email: contactForm.value.email,
            message: contactForm.value.message,
          }).then(() => {
            // clear the inputs after submit
          contactForm.value.message = "";
          contactForm.value.policy = false;
          contactSent.value = true;
          loading.value = false
          })
          } catch (errors) {
            this.errors = errors
            loading.value = false
          }
      }
    }
    return {
      contactSent,
      contactForm,
      send,
      validateEmail,
      errors,
      hasErrors,
      validateAll,
      validateCheckBox,
      loading
    };
  },
};
</script>
