<template>
    <EditBox ref="editBox" :title="t('personalData')" editbar>
        <template v-slot:content>
            <div class="w-full flex flex-col py-4">
                <div class="w-full pt-4">
                    <div class="w-full text-sm py-2">
                        <p class="text-gray-600 py-1">{{ t("company") }}</p>
                        <p>{{ personalData.company }}</p>
                    </div>
                    <div class="w-full text-sm py-2">
                        <p class="text-gray-600 py-1">{{ t("streetAndBuildingNr") }}</p>
                        <p>{{ personalData.street }}</p>
                    </div>
                    <div class="w-full text-sm py-2">
                        <p class="text-gray-600 py-1">{{ t("city") }}</p>
                        <p>{{ personalData.city }}</p>
                    </div>
                    <div class="w-full text-sm py-2">
                        <p class="text-gray-600 py-1">{{ t("zipCode") }}</p>
                        <p>{{ personalData.zip }}</p>
                    </div>
                    <div class="w-full text-sm py-2">
                        <p class="text-gray-600 py-1">{{ t("addressLine2") }}</p>
                        <p>{{ personalData.additional }}</p>
                    </div>
                    <div class="w-full text-sm py-2">
                        <p class="text-gray-600 py-1">{{ t("umstNr") }}</p>
                        <p>{{ personalData.vat_no }}</p>
                    </div>
                </div>
            </div>
        </template>

        <!-- Edit Modal -->
        <template v-slot:modal>
            <div class="w-full">
                <form @submit.prevent="saveData"
                      class="w-full grid gap-4 grid-cols-1 pt-8 pb-20 text-left">
                    <div class="w-full grid gap-4 grid-cols-1 py-8">
                        <TextInput
                                :placeholder="t('company')"
                                required
                                :label="t('company')"
                                v-model="editPersonalData.company"
                                name="company"
                                :errorMessage="errors.company"
                        />
                        <div class="w-full grid gap-4 grid-cols-1 md:grid-cols-2">
                            <TextInput
                                    :placeholder="t('streetAndBuildingNr')"
                                    required
                                    :label="t('streetAndBuildingNr')"
                                    v-model="editPersonalData.street"
                                    name="street"
                                    :errorMessage="errors.street"
                            />
                            <TextInput
                                    :placeholder="t('city')"
                                    required
                                    :label="t('city')"
                                    v-model="editPersonalData.city"
                                    name="city"
                                    :errorMessage="errors.city"
                            />
                        </div>
                        <div class="w-full grid gap-4 grid-cols-1 md:grid-cols-2">
                            <TextInput
                                    :placeholder="t('zipCode')"
                                    required
                                    :label="t('zipCode')"
                                    v-model="editPersonalData.zip"
                                    name="zip"
                                    :errorMessage="errors.zip"
                            />
                            <TextInput
                                    :placeholder="t('addressLine2')"
                                    :label="t('addressLine2')"
                                    v-model="editPersonalData.additional"
                                    name="addressLine2"
                                    :errorMessage="errors.additional"
                            />
                        </div>
                        <TextInput
                                :placeholder="t('umstNr')"
                                :label="t('umstNr')"
                                v-model="editPersonalData.vat_no"
                                name="vat_no"
                                :errorMessage="errors.vat_no"
                        />

                    </div>
                    <div class="w-full sm:w-1/2 mx-auto mt-8">
                        <Button type="submit" primary :text="t('confirm')" class="w-full" />
                        <Button
                                type="button"
                                @click="closeEditModal"
                                :text="t('cancel')"
                                class="w-full text-center underline mt-2"
                        />
                    </div>
                </form>
            </div>
        </template>
    </EditBox>
</template>

<script>
    import EditBox from "@/components/atoms/EditBox";
    import translateMixin from "@/mixins/translate.js";
    import useValidation from "@/mixins/useValidation.js";
    import { ref, computed } from "vue";
    import TextInput from "@/components/forms/TextInput";
    import Button from "@/components/atoms/Button";

    import {useStore} from "vuex";
    export default {
        mixins: [translateMixin],
        components: {
            EditBox,
            Button,
            TextInput,
        },
        setup() {
            const store = useStore();
            // refs
            const editBox = ref(null);
            function closeEditModal() {
                editBox.value.closeEditModal();
            }
            let editPersonalData = ref(
                {
                    company: "",
                    street: "",
                    city: "",
                    zip: "",
                    additional: "",
                    vat_no: "",
                }
            );

            const personalData = computed(() => {
                const user = store.state.user.user;
                let data = {
                    company: "",
                    street: "",
                    city: "",
                    zip: "",
                    additional: "",
                    vat_no: "",
                };
                if(user){
                    data = {
                        company: user.company,
                        street: user.street,
                        city: user.city,
                        zip: user.zip,
                        additional: user.additional,
                        vat_no: user.vat_no,
                    }
                    setEditPersonalData(data);
                }
                return data;
            });

            function setEditPersonalData(data) {
                editPersonalData.value = data;
            }
            const {
                errors,
                hasErrors,
                validateNumber
            } = useValidation();

            function validateAll() {
                errors.value = {};
                validateNumber('zip', personalData.value.zip)
            }

            async function saveData() {
                this.errors = {};
                validateAll();
                if (!hasErrors.value) {
                    try {
                        const personalDataForm = editPersonalData.value;
                        await store.dispatch("setPersonalData",
                            {
                                company: personalDataForm.company,
                                street: personalDataForm.street,
                                city: personalDataForm.city,
                                zip: personalDataForm.zip,
                                additional_address: personalDataForm.additional,
                                vat_no: personalDataForm.vat_no,
                            }
                        )
                        closeEditModal();
                    } catch (errors) {
                        this.errors = errors;
                    }
                }
            }
            return {
                saveData,
                errors,
                hasErrors,
                validateAll,
                editBox,
                closeEditModal,
                personalData,
                editPersonalData,
                validateNumber,
            };
        },
    };
</script>
