import axios from 'axios'
const baseURL = process.env.VUE_APP_BASE_URL
const serviceId = process.env.VUE_APP_SERVICE_ID

const contact = {
    state: {},
    mutations: {
    },
    actions: {
        async sendContact(commit, payload) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
                    axios.post(`${baseURL}/spaApi/contact-form`, {
                        service_uuid: serviceId,
                        email: payload.email,
                        name: payload.name,
                        message: payload.message
                    }).then((response) => {
                        resolve(response)
                        return true
                    }).catch(error => {
                        const {errors} = error.response.data;
                        let errorMessages = {};
                        if (errors && 'email' in errors) {
                            errorMessages['email'] = errors['email'][0]
                        }
                        if (errors && 'name' in errors) {
                            errorMessages['name'] = errors['name'][0]
                        }
                        if (errors && 'message' in errors) {
                            errorMessages['message'] = errors['message'][0]
                        }
                        reject(errorMessages)
                    })
                })
            })
        },
        async checkUser() {
            try {
                const response = await axios.get(`${baseURL}/spaApi/user`);
                return response.data.data
            } catch (e) {
                return e.response;
            }
        },
    },
    getters: {}
}


export default contact
