<template>
  <div
    ref="mixedContainer"
    class="w-full flex flex-col-reverse md:flex-row max-w-5xl 2xl:max-w-6xl mx-auto justify-between pt-8"
  >
    <div class="w-full md:w-1/2 flex overflow-hidden" :style="`height:${imageHeight}px;`">
      <img
        :src="src"
        alt="mixed-image"
        height="100%"
        width="100%"
        class="w-full h-full -ml-12 sm:ml-0 flex object-cover md:object-contain lg:object-cover 2xl:object-contain object-top pt-4"
      />
    </div>
    <div class="w-full md:w-1/2 flex overflow-hidden px-4 md:px-2">
        <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeMount, onUnmounted} from "vue";

export default {
props: { 
    src: { type: String, default: "@/assets/images/illu/person-walk.svg"}
},
  setup() {
    const imageHeight = ref(570)
    const mixedContainer = ref(null)
    function setImageHeight() {
    const mainContainerHeight = mixedContainer.value.offsetHeight
    if(document.documentElement.clientWidth < 640) {
        imageHeight.value = 320
    }
    else if(mainContainerHeight < 620) {
        imageHeight.value = mainContainerHeight
    } else {
        imageHeight.value = 620
    }
    }
    onMounted(() => {
        setImageHeight()
    })
    onBeforeMount(() => {
        window.addEventListener("resize", setImageHeight);
    })
    onUnmounted(() => {
        window.removeEventListener("resize", setImageHeight);
    })
    return {
        imageHeight,
        setImageHeight,
        mixedContainer
    };
  },
};
</script>

