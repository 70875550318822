<template>
  <DefaultLayout>
    <div class="w-full flex flex-col py-8">
      <p v-if="userData.first_name" class="text-xl font-medium py-8">
        Hi, {{ userData.first_name }}!
      </p>
      <div class="w-full flex flex-col lg:flex-row py-2">
        <div
          v-if="userReports && userReports.meta"
          class="w-full lg:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-4"
        >
          <DashboardCard :title="total" :text="t('currentBonusCredit')" />
          <DashboardCard :title="position || 0" :text="t('yourPosition')" />
          <DashboardCard
            v-if="userReports.meta.payout_minimum_reached"
            :title="nextPayment"
            :text="t('nextPayment')"
          />
          <DashboardCard v-else :title="nextPayment" :text="t('toGetNextPayout')" />
          <DashboardCard
            :title="successfullRecommendations"
            :text="t('successfullRecommendations')"
          />
        </div>
        <div
          v-if="userData"
          class="w-full lg:w-1/2 flex flex-col text-center px-4 sm:px-16 pt-12 lg:pt-0"
        >
          <div class="w-full flex justify-center items-center">
            <div class="w-28 h-28 bg-cover object-fit rounded-full overflow-hidden">
              <img
                class="w-full h-full flex object-cover bg-secondary-100"
                :src="userData.user_level_image || levelFallbackImg"
                @error="replaceByDefaultImg"
                alt="level-image"
                widht="122"
                height="112"
              />
            </div>
          </div>
          <div class="w-full flex justify-center relative">
            <p class="pt-3 text-lg font-bold">
              <span class="capitalize"> {{ userData.user_level_name || "Basic" }} </span>
            </p>
            <div
              @mouseover="toggleLevelNotePopup(true)"
              @mouseleave="toggleLevelNotePopup(false)"
              class="w-auto flex items-center mx-2 cursor-pointer select-none"
            >
              <Icon name="info-rounded" class="w-2 h-6 text-gray-700 mt-3 select-none" />
            </div>
            <div>
              <transition name="slide-fade">
                <div
                  v-show="LevelNotePopup"
                  class="absolute top-12 w-64 rounded bg-white z-10 right-4 p-4 border flex shadow"
                >
                  <p class="text-sm lg:text-base">
                    {{ t("levelsNoteBefore") }} {{commissionForNextLevel}}{{ t("levelsNoteAfter") }}
                  </p>
                </div>
              </transition>
            </div>
          </div>
          <p class="pt-3 text-sm">{{ t("basicLevelText") }}</p>
        </div>
      </div>
      <div class="w-full flex flex-col py-16">
        <div class="w-auto flex">
          <Badge>{{ t("developmentInLastThreeMonths") }}</Badge>
        </div>
        <div class="w-full flex flex-col pt-12">
          <ChartSection
            :ranges="
              userReports && userReports.meta
                ? userReports.meta.registrations_per_week_in_date_range
                : []
            "
          />
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import { computed, ref } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import translateMixin from "@/mixins/translate.js";
import DefaultLayout from "@/components/layouts/Default";
import DashboardCard from "@/components/atoms/DashboardCard";
import Badge from "@/components/atoms/Badge";
import Icon from "@/components/atoms/Icon";
import ChartSection from "@/components/atoms/ChartSection";
import useFormat from "@/mixins/useFormat.js";

export default {
  mixins: [translateMixin],
  components: {
    DashboardCard,
    DefaultLayout,
    Icon,
    Badge,
    ChartSection,
  },
  setup() {
    const {currencyFromat} = useFormat();
    const total = computed(() => {
      const meta = userReports.value.meta;
      if (meta) {
        return `${currencyFromat(meta.total_unpaid_commissions)}€`;
      } else {
        return "";
      }
    });
    const position = computed(() => {
      const meta = userReports.value.meta;
      if (meta) {
        return meta.global_user_commission_rank;
      } else {
        return "";
      }
    });
    const nextPayment = computed(() => {
      const meta = userReports.value.meta;
      if (meta) {
        if (meta.payout_minimum_reached) {
          return `In ${meta.next_payment_in_days} Tagen`;
        } else {
          return `${currencyFromat(meta.commission_value_needed_to_reach_payout_minimum)}€`;
        }
      } else {
        return "";
      }
    });
    const successfullRecommendations = computed(() => {
      const meta = userReports.value.meta;
      if (meta) {
        return meta.total_registered_recommendations;
      } else {
        return "";
      }
    });
    const commissionForNextLevel = computed(() => {
      const meta = userReports.value.meta;
      if (meta && meta.commission_value_needed_for_next_condition_level) {
        return currencyFromat(meta.commission_value_needed_for_next_condition_level);
      } else {
        return "";
      }
    })

    const store = useStore();
    store.dispatch("getUser");
    // for now we should show last 3 monthes
    // later the maybe will add filter for it
    const dateFrom = moment().subtract(3, "months").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");
    store.commit('setLoggedIn', true);
    store.dispatch("getUserReports", { date_from: dateFrom, date_to: dateTo });
    const userReports = computed(() => {
      return store.getters.userReports;
    });
    const userData = computed(() => {
      return store.getters.user;
    });

    const LevelNotePopup = ref(false);

    function toggleLevelNotePopup(val) {
      LevelNotePopup.value = val;
    }

    const levelFallbackImg = computed(() => {
      return require('@/assets/images/level-fallback.png')
    })
    function replaceByDefaultImg(e) {
      e.target.src = levelFallbackImg.value
    }

    return {
      total,
      position,
      nextPayment,
      successfullRecommendations,
      LevelNotePopup,
      toggleLevelNotePopup,
      userReports,
      userData,
      levelFallbackImg,
      replaceByDefaultImg,
      commissionForNextLevel,
      currencyFromat,
    };
  },
};
</script>
