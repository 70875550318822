import translation from '@/assets/texts/translation.json'
 const translate ={
    methods: {
        t(index) {
            let text = ''
            Object.entries(translation).forEach(([key, value]) => {
                if(key === index){
                    text = value
                } else if(Array.isArray(value) && typeof index !== "undefined" && index.includes('.')) {
                    const newIndex = index.split('.')[1]
                    if(key === index.split('.')[0]) {
                        text = value.filter(item => item[newIndex])[0][newIndex]
                    }
                }
            });
            return text;
        }
    }
}
export default translate