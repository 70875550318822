import moment from "moment";

export default function useFormat() {
    const currencyFromat = function(val) {
      const num = Number(val).toFixed(2)
      if(Number(val) === 0) {
        return "0"
      } else {
        return String(num).replace('.', ',')
      }
    }
    const dateFormat = function(val) {
      return moment(val).format("DD.MM.YYYY");
    }
    return {
      currencyFromat,
      dateFormat
    }
}