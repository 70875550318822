<template>
  <div class="w-auto flex grid-cols-6">
    <a
      v-for="(item, index) in finalUserChannels"
      v-show="index < 5"
      :key="index"
      :href="item.link"
      :id="item.name + index"
      target="_blank"
      rel="noopener noreferrer"
      class="w-10 h-10 flex justify-center items-center p-2 flex-shrink-0 rounded-full hover:opacity-75 hover:shadow hover-scale-102 cursor-pointer mr-2 sm:mr-3"
      :class="'bg-' + item.name"
    >
      <Icon :name="item.name" class="w-full text-white" />
      <span class="absolute w-0 opacity-0">
        {{ item.name }}
      </span>
    </a>
    <div
      @click="toggleShowAll()"
      v-click-outside="closeShowAll"
      v-show="finalUserChannels.length > 5"
      class="w-10 h-10 flex justify-center flex-shrink-0 items-center p-2 rounded-full bg-gray-300 hover:shadow hover-scale-102 cursor-pointer relative"
    >
      <div class="w-auto">
        <Icon name="3-dots" class="w-full text-white" />
      </div>
      <div
        v-show="showAll"
        class="absolute w-auto p-4 border rounded bg-white shadow top-12 right-0 flex"
      >
        <div
          v-for="(item, index) in finalUserChannels"
          v-show="index >= 5"
          :key="index"
          class="w-10 h-10 ml-2 sm:ml-3"
        >
          <a
            :href="item.link"
            :id="item.name + index"
            target="_blank"
            rel="noopener noreferrer"
            class="w-10 h-10 flex justify-center items-center p-2 rounded-full hover:opacity-75 hover:shadow hover-scale-102 cursor-pointer"
            :class="'bg-' + item.name"
          >
            <Icon :name="item.name" class="w-full text-white" />
            <span class="absolute w-0 opacity-0">
              {{ item.name }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <p v-show="finalUserChannels && finalUserChannels.length > 0" class="py-4 text-sm md:text-base">
    {{ t("shareYourCode") }}
  </p>
</template>

<script>
import { ref, computed } from "vue";
import vClickOutside from "click-outside-vue3";
import Icon from "@/components/atoms/Icon";
import translateMixin from "@/mixins/translate.js";
import { useStore } from "vuex";
export default {
  components: {
    Icon,
  },
  mixins: [translateMixin],
  setup() {
    const trans = translateMixin.methods.t;
    const store = useStore();
 

    // the sharing items, that user have added them
    const userChoiseItems = computed(() => {
      const result = store.getters["marketingChannels/marketingChannels"];
      if (result && result.length > 0) {
        return result.map((item) => {
          if (item.name !== "") {
            return item.name;
          }
          return null;
        });
      } else {
        return [];
      }
    });
    const parsedSharingItems = ref(userChoiseItems.value)
    // show all socialmedia items by toggle button
    const showAll = ref(false);
    const currentCode = computed(() => {
      return store.getters.currentCode;
    });

    const quote = ref(trans("hereIsRecommendedCode"));
    const sharingText = ref(trans("registerAt"));
    const registerLink = ref("https://app.bkf.online/register");

    // available socialmedia and its sharing links
    const staticSharingLinks = ref([
      {
        name: "facebook",
        link: `https://www.facebook.com/sharer.php?u=${registerLink.value}&quote=${quote.value}%0a${currentCode.value}%0a${sharingText.value}%0a${registerLink.value}`,
      },
      {
        name: "twitter",
        link: `https://twitter.com/intent/tweet?text=${quote.value}%0a${currentCode.value}%0a${sharingText.value} %0a&url=${registerLink.value}`,
      },
      {
        name: "whatsapp",
        link: `https://wa.me/?text=${quote.value}%0a${currentCode.value}%0a${sharingText.value}%0a${registerLink.value}`,
      },
      {
        name: "telegram",
        link: `https://t.me/share/url?url=${registerLink.value}&text=${quote.value} ${currentCode.value}%0a${sharingText.value}%0a`,
      },
      {
        name: "linkedin",
        link: `https://www.linkedin.com/shareArticle?mini=true&url=${registerLink.value}&title=${quote.value}${currentCode.value}&summary=${sharingText.value}%0a`,
      },
      {
        name: "xing",
        link: `https://www.xing.com/spi/shares/new?url=${registerLink.value}&title=${currentCode.value}%0a${sharingText.value}%0a${registerLink.value}`,
      },
    ]);

    // the sharing items , that should be showen 
    const finalUserChannels = computed(() => {
      const result = staticSharingLinks.value.filter(function(arr1){
      return parsedSharingItems.value.some(function(arr2){
        return arr1.name.includes(arr2);
      });}).map(function(newArr){
        return newArr
      });
      return result
    });

    function toggleShowAll() {
      showAll.value = !showAll.value;
    }
    function closeShowAll() {
      showAll.value = false;
    }

    return {
      showAll,
      toggleShowAll,
      closeShowAll,
      userChoiseItems,
      trans,
      staticSharingLinks,
      currentCode,
      finalUserChannels,
      parsedSharingItems
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
};
</script>
