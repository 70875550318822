export function isLoggedIn() {
    let userConfig = getConfig();
    return userConfig && userConfig.isLoggedIn == "true";
}
export function isCompleted() {
    let userConfig = getConfig();
    return userConfig && userConfig.isCompleted == "true";
}
export function isVerify() {
    let userConfig = getConfig();
    return userConfig && userConfig.isVerify == "true";
}
export function authRegister() {
    localStorage.setItem("userConfig", JSON.stringify({
        isLoggedIn:"true",
        isCompleted:"false",
        isVerify:"false",
    }));
}
export function logIn(config) {
    localStorage.setItem("userConfig", JSON.stringify(config));
}

export function logOut() {
    localStorage.setItem("userConfig", JSON.stringify({
        isLoggedIn:"false",
        isCompleted:"false",
        isVerify:"false",
    }));
    localStorage.removeItem("currentCode")
}

function getConfig() {
    let userConfig = localStorage.getItem("userConfig");
    return  userConfig ? JSON.parse(userConfig): null
}

