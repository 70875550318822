import axios from 'axios'
const baseURL = process.env.VUE_APP_BASE_URL
const payoutUuid = process.env.VUE_APP_PAYOUT_SERVICE_UUID

const payOut = {
    state: {
        payOutData: {},
    },
    getters: {
        payOutData: state =>{
            return state.payOutData
        },
    },
    mutations: {
        setPayOutData(state, payload) {
            state.payOutData = payload;
        }
    },
    actions: {
        setPayouts({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/spaApi/user-payout-service`, {
                    payout_service_uuid: payoutUuid,
                    bank_name: payload.bank_name,
                    iban: payload.iban,
                    bic: payload.bic,
                    payout_interval: payload.payout_interval
                }).then((response) => {
                    dispatch('getPayout')
                    resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if ('data' in errors) {
                        errorMessages['data'] = errors['data'][0]
                    }
                    if ('iban' in errors) {
                        errorMessages['iban'] = errors['iban'][0]
                    }
                    if ('bic' in errors) {
                        errorMessages['bic'] = errors['bic'][0]
                    }
                    if ('bank_name' in errors) {
                        errorMessages['bank_name'] = errors['bank_name'][0]
                    }
                    if ('payout_interval' in errors) {
                        errorMessages['payout_interval'] = errors['payout_interval'][0]
                    }
                    reject(errorMessages)
                })

            })
        },

        updatePayouts({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`${baseURL}/spaApi/user-payout-service`, payload
                ).then((response) => {
                    dispatch('getPayout')
                    resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if ('data' in errors) {
                        errorMessages['data'] = errors['data'][0]
                    }
                    if ('iban' in errors) {
                        errorMessages['iban'] = errors['iban'][0]
                    }
                    if ('bic' in errors) {
                        errorMessages['bic'] = errors['bic'][0]
                    }
                    if ('payout_interval' in errors) {
                        errorMessages['payout_interval'] = errors['payout_interval'][0]
                    }
                    reject(errorMessages)
                })

            })
        },

        async getPayout({commit}) {
            try {
                const response = await axios.get(`${baseURL}/spaApi/user-payout-service`);
                commit('setPayOutData', response.data);
            } catch (e) {
                return e.response;
            }
        },
    },
    namespaced: true,
}

export default payOut
