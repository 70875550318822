<template>
  <EditBox ref="editBox" :title="t('marktingChannels')" editbar>
    <template v-slot:content>
      <div class="w-full flex flex-col py-4">
        <div class="w-full">
          <div
            v-show="!item.disabled"
            v-for="(item, index) in mediaData"
            :key="index"
            class="w-full text-sm py-2 flex"
          >
            <p class="text-gray-600 pr-4">{{ item.label }}:</p>
            <p>{{ item.value }}</p>
          </div>
        </div>
      </div>
    </template>

    <!-- Edit Modal -->
    <template v-slot:modal>
      <div class="w-full">
        <form
          @submit.prevent="saveMediaChannels"
          class="w-full grid gap-4 grid-cols-1 pt-8 pb-4 text-left"
        >
          <MediaInput
            v-for="(platform, index) in mediaData"
            v-show="index <= showListOfMedia"
            :key="index"
            v-model="platform.value"
            :label="platform.label"
            :name="platform.name"
            :checked="!platform.disabled"
            placeholder="enterProfileURL"
            @changeCheckbox="toggleMediaItem"
          />
          <div @click="toggleMediaList" class="w-full cursor-pointer">
            <p class="underline">
              {{ showListOfMedia > 2 ? t("showLess") : t("showMore") }}
            </p>
          </div>
          <p v-if="errors[0]" class="text-xs py-0.5 text-red-500 text-center">{{t('errors.channelsProfileUrl')}}</p>

          <div class="w-full sm:w-1/2 mx-auto mt-8">
            <Button type="submit" primary :text="t('confirm')" class="w-full" />
            <Button
              type="button"
              @click="closeEditModal"
              :text="t('cancel')"
              class="w-full text-center underline mt-2"
            />
          </div>
        </form>
      </div>
    </template>
  </EditBox>
</template>

<script>
import EditBox from "@/components/atoms/EditBox";
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import { computed, ref } from "vue";
import Button from "@/components/atoms/Button";
import MediaInput from "@/components/forms/MediaInput";
import { useStore } from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    EditBox,
    MediaInput,
    Button,
  },
  setup() {
    const store = useStore();
    const trans = translateMixin.methods.t;
    // refs
    const editBox = ref(null);
    function closeEditModal() {
      editBox.value.closeEditModal();
    }
    const showListOfMedia = ref(2);
    let channelsData = [
    { name: "facebook", label: "Facebook", value: "", disabled: true },
    { name: "whatsapp", label: "WhatsApp", value: "", disabled: true },
    { name: "twitter", label: "Twitter", value: "", disabled: true },
    { name: "xing", label: "XING", value: "", disabled: true },
    { name: "linkedin", label: "LinkedIn", value: "", disabled: true },
    { name: "instagram", label: "Instagram", value: "", disabled: true },
    { name: "snapchat", label: "Snapchat", value: "", disabled: true },
    { name: "telegram", label: "Telegram", value: "", disabled: true },
    { name: "tiktok", label: "TikTok", value: "", disabled: true },
    ];
    function toggleMediaList() {
      if (showListOfMedia.value === 2) {
        showListOfMedia.value = mediaData.value.length;
      } else {
        showListOfMedia.value = 2;
      }
    }

    function toggleMediaItem(mediaItem) {
      channelsData.map((media) => {
        if (media.name === mediaItem.name) {
          media["disabled"] = !mediaItem.disabled;
        }
        return media;
      });
    }
    // inputs & errors initial

    const { errors, hasErrors } = useValidation();
    const mediaData = computed(() => {
      const channels = store.state.marketingChannels.marketingChannels;
      let channelNames = [];
      if (channels && channels.length > 0) {
        channels.forEach((item) => {
          if (item.name) {
            channelNames[item.name] = item.profile_url;
          }
        });
      }
      channelsData.map((item) => {
        item.value = "";
        item.disabled = true;
        if (channelNames[item.name] !== undefined) {
          item.value = channelNames[item.name];
          item.disabled = false;
        }
        return item;
      });
      return channelsData;
    });

    function validateAll() {
      errors.value = {};
    }

    async function saveMediaChannels() {
      this.errors = {};
      validateAll();
      // if there is no error get saveData api
      if (!hasErrors.value) {
        try {
          const channelsForm = {
            online: [],
          };
          channelsData.forEach((media) => {
            if (!media["disabled"]) {
              media["profile_url"] = media.value;
              channelsForm["online"].push(media);
            }
          });
          await store.dispatch("marketingChannels/setMarketingChannel", {
            channels: channelsForm,
          });
          closeEditModal();
        } catch (errorMessages) {
          this.errors = errorMessages.online;
        }
      }
    }
    return {
      mediaData,
      toggleMediaList,
      showListOfMedia,
      saveMediaChannels,
      errors,
      hasErrors,
      validateAll,
      editBox,
      closeEditModal,
      trans,
      toggleMediaItem,
    };
  },
};
</script>
