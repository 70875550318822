<template>
  <div class="w-full" v-if="ranges && ranges.length">
    <apexcharts height="350" type="line" :series="values" :options="options"></apexcharts>
  </div>
</template>

<script>
// for more options visit https://apexcharts.com/
import VueApexCharts from "vue3-apexcharts";

import { computed } from "vue";
import translateMixin from "@/mixins/translate.js";

export default {
  mixins: [translateMixin],
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    ranges: { type: [Array, Object], default: () => [] },
  },
  setup(props) {
    const trans = translateMixin.methods.t;
    const values = computed(() => {
      if (props.ranges && props.ranges.length) {
        const data = props.ranges.map((range) => {
          return range.value;
        });
        return [{ name: "Anzahl: Code eingelöst", data }];
      } else {
        return [];
      }
    });
    const dates = computed(() => {
      if (props.ranges && props.ranges.length) {
        return props.ranges.map((range) => {
          return `KW${range.label.slice(-3)}`;
        });
      } else {
        return [];
      }
    });

    const options = computed(() => {
      return {
        chart: {
          type: "line",
          stacked: true,
          toolbar: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#4C5370"],
        xaxis: {
          categories: dates.value,
        },
        yaxis: {
          title: {
            text: trans("numberOfRecommendations"),
          },
        },
      };
    });

    return {
      options,
      values,
      dates,
    };
  },
};
</script>
