<template>
  <MixedLayout :src="require('@/assets/images/illu/person-run.svg')">
    <div class="w-full flex flex-col md:py-10">
      <h1 class="text-3xl sm:text-4xl lg:text-5xl font-black mt-8 md:mt-0">
        {{ t("renewEmailHeading") }}
      </h1>
      <p class="pt-8 text-xl lg:text-2xl">{{ t("renewEmailSubheading") }}</p>
      <div class="w-full sm:w-1/2 md:w-full lg:w-1/2 my-8">
        <Button
          @click="renewEmail"
          primary
          :text="t('renewEmailButton')"
          class="w-full"
        />
        <p class="text-sm py-0.5 text-green-500 text-center" v-if="successfullySent">
          {{ t("emailSent") }}
        </p>
      </div>
    </div>
  </MixedLayout>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import Button from "@/components/atoms/Button";
import MixedLayout from "@/components/layouts/Mixed";
import { ref } from "vue";

import { useStore } from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    Button,
    MixedLayout,
  },
  setup() {
    const store = useStore();
    const successfullySent = ref(false);

    async function renewEmail() {
      try {
        await store.dispatch("resendEmail");
        successfullySent.value = true;
      } catch (errors) {
        this.errors = errors;
      }
    }
    return {
      successfullySent,
      renewEmail,
    };
  },
};
</script>
