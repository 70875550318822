<template>
  <EditBox ref="editBox" :title="t('feedbackAndDeleteAccount')">
    <template v-slot:content>
      <div class="w-full flex flex-col py-4">
        <div class="w-full flex flex-col">
          <p class="text-sm pb-2">{{ t("feedbackSubTitle") }}</p>
          <p class="text-sm pb-6">{{ t("feedbackText") }}</p>
          <div class="w-full flex py-2">
            <p class="inline text-sm pb-2">
              {{ t("deleteAccountText") }}
              <router-link to="/delete-account" class="underline px-1">{{
                t("deleteAccount")
              }}</router-link>
            </p>
          </div>
        </div>
        <div class="w-auto flex mt-4">
          <Button primary to="/contact" :text="t('sendFeedback')" />
        </div>
      </div>
    </template>

    <!-- Edit Modal -->
    <template v-slot:modal> </template>
  </EditBox>
</template>

<script>
import EditBox from "@/components/atoms/EditBox";
import translateMixin from "@/mixins/translate.js";
import Button from "@/components/atoms/Button";
export default {
  mixins: [translateMixin],
  components: {
    EditBox,
    Button,
  },
  setup() {
    return {};
  },
};
</script>
