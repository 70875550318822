<template>
  <MixedLayout :src="require('@/assets/images/illu/person-run.svg')">
    <div class="w-full flex flex-col md:py-10">
      <div class="w-full">
        <h1 class="text-4xl md:text-6xl font-bold pt-16 text-center">404</h1>
        <h2 class="text-2xl md:text-3xl font-semibold text-center py-4">
          {{ t("pageNotFound") }}
        </h2>
        <div class="w-full flex justify-center py-8">
          <Button primary to="/" :text="t('toStartPage')" class="px-16" />
        </div>
      </div>
    </div>
  </MixedLayout>
</template>
<script>
import translateMixin from "@/mixins/translate.js";
import Button from "@/components/atoms/Button";
import MixedLayout from "@/components/layouts/Mixed";
export default {
  mixins: [translateMixin],
  components: {
    Button,
    MixedLayout,
  },
};
</script>
