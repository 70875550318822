<template>
  <Teleport v-if="showBanner" to="#global-modal">
    <div class="w-full global-modal-wrapper">
      <div
        class="w-11/12 md:w-1/2 2xl:w-1/3 p-4 md:p-6 rounded bg-white shadow-lg text-center flex flex-col relative"
      >
        <div @click="closeModal" class="w-4 h-4 absolute top-4 right-4 cursor-pointer">
          <Icon name="close" class="w-4 h-4 flex" />
        </div>
        <div class="w-full flex justify-center">
          <h1 class="text-xl md:text-2xl font-black py-2 flex">
            {{ t("cookies") }}
            <Icon name="cookie" class="mx-1 w-8 h-8 text-black" />
          </h1>
        </div>
        <p class="py-4 text-lg px-2 sm:px-8 text-gray-700">
          {{ t("cookiesText")
          }}<router-link to="/policy" class="text-blue-500 underline mx-px">{{
            t("policy")
          }}</router-link>
        </p>
        <div class="w-full flex flex-col py-4">
          <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 mt-4 mx-auto">
            <Button
              @click="setCookieBanner('_bkf_cookies', 'inserted')"
              primary
              :text="t('okUnderstand')"
              class="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script>
import { ref, onBeforeMount, watch } from "vue";
import { useRoute } from "vue-router";
import translateMixin from "@/mixins/translate.js";
import Icon from "@/components/atoms/Icon";
import Button from "@/components/atoms/Button";
export default {
  name: "CookieBanner",
  components: {
    Button,
    Icon,
  },
  mixins: [translateMixin],
  setup() {
    const route = useRoute();
    onBeforeMount(() => {
      getCookieBanner("_bkf_cookies");
    });
    watch(
      () => route.name,
      () => {
        if (route.name === "Policy") {
          showBanner.value = false;
        } else {
          showBanner.value = true;
          getCookieBanner("_bkf_cookies");
        }
      }
    );

    // as default if there is no saved cookies (first time for the user)
    const showBanner = ref(true);

    function setCookieBanner(cname, cvalue) {
      closeModal();
      const d = new Date(9999, 9);
      const expires = "expires=" + d.toGMTString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    // close it temporarily without setting the cookie
    function closeModal() {
      document.body.style.overflow = "auto";
      showBanner.value = false;
    }

    function getCookieBanner(cname) {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0 || c.includes(cname)) {
          closeModal();
        } else if (cname == "_bkf_cookies" && !c) {
          // to prevent scoll in background while the modal is on
          document.body.style.overflow = "hidden";
        }
      }
    }
    return {
      route,
      showBanner,
      closeModal,
      setCookieBanner,
      getCookieBanner,
    };
  },
};
</script>
