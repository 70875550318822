<template>
  <MixedLayout :src="require('@/assets/images/illu/person-walk.svg')">
    <div class="w-full">
      <h1 class="main-heading text-center pt-16 md:pt-32">
        {{ t("login") }}
      </h1>
      <Notification
        v-if="passwordResetNotify"
        @close="closeNotification"
        type="success"
        :message="t('yourPasswordReset')"
      />
      <form @submit.prevent="login" class="w-full grid gap-6 grid-cols-1 pt-8 pb-20">
        <TextInput
          type="email"
          :placeholder="t('emailAddress')"
          :label="t('emailAddress')"
          v-model="loginForm.email"
          :errorMessage="errors.email"
          required
          name="email"
        />
        <TextInput
          type="password"
          :placeholder="t('password')"
          :label="t('password')"
          v-model="loginForm.password"
          :errorMessage="errors.password"
          required
          name="password"
        />
        <div class="w-full sm:w-1/2 mx-auto mt-1">
          <Button type="submit" primary :text="t('login')" class="w-full" />
        </div>
        <div class="flex flex-col text-center -mt-3">
          <router-link class="underline text-secondary-400" to="/forget-password">{{
            t("forgetPassword")
          }}</router-link>
          <p class="text-lg mt-12">
            {{ t("registerQuestion") }}
            <router-link class="px-px underline text-secondary-400" to="/register">{{
              t("registration")
            }}</router-link>
          </p>
        </div>
      </form>
    </div>
  </MixedLayout>
</template>

<script>
import { useStore } from "vuex";
import translateMixin from "@/mixins/translate.js";
import { ref } from "vue";
import Button from "@/components/atoms/Button";
import Notification from "@/components/atoms/Notification";
import MixedLayout from "@/components/layouts/Mixed";
import TextInput from "@/components/forms/TextInput";
import useValidation from "@/mixins/useValidation.js";
export default {
  mixins: [translateMixin],
  components: {
    TextInput,
    Button,
    Notification,
    MixedLayout,
  },
  setup() {
    const store = useStore();

    const { errors, hasErrors, validateEmail } = useValidation();

    // after reset password the browser will navigate to login page with this noftify message
    const passwordResetNotify = ref(false);

    const loginForm = ref({ email: "" }, { password: "" });

    async function login() {
      this.errors = {};
      validateEmail(loginForm.value.email);

      // if there is no error get login api
      if (!hasErrors.value) {
        const form = loginForm.value;
        try {
          await store.dispatch("login", {
            email: form.email,
            password: form.password,
          });
        } catch (errors) {
          this.errors = errors;
        }
      }
    }

    function closeNotification() {
      passwordResetNotify.value = false;
    }
    return {
      loginForm,
      login,
      errors,
      hasErrors,
      validateEmail,
      passwordResetNotify,
      closeNotification,
    };
  },
};
</script>
