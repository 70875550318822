<template>
  <div class="flex flex-col">
    <p class="text-lg text-left md:text-center pt-1 md:pt-3">
      {{ t("payText") }}
    </p>
    <h2 class="text-lg font-bold text-left md:text-center pt-8">
      {{ t("bankData") }}
    </h2>
    <div class="grid gap-4 py-4">
      <TextInput
        :placeholder="t('bankName')"
        required
        :label="t('bankName')"
        v-model="payData.bank_name"
        name="bank_name"
        :errorMessage="errors['bank_name']"
      />
      <TextInput
        :placeholder="t('iban')"
        required
        :label="t('iban')"
        v-model="payData.iban"
        name="iban"
        :errorMessage="errors['iban']"
      />
      <TextInput
        :placeholder="t('bic')"
        required
        :label="t('bic')"
        v-model="payData.bic"
        name="bic"
        :errorMessage="errors['bic']"
      />
    </div>
    <p class="text-lg text-left md:text-center pt-6">
      {{ t("payTimes") }}<span class="-m-0.5 pl-1">*</span>
    </p>
    <div class="grid gap-4 py-4">
      <RadioInput
        name="payTime"
        :label="t('payMonthly')"
        v-model="payData.payout_interval"
        value="monthly"
      />
      <RadioInput
        name="payTime"
        :label="t('payQuarterly')"
        v-model="payData.payout_interval"
        value="quarterly"
      />
      <RadioInput
        name="payTime"
        :label="t('payEverySixMonth')"
        v-model="payData.payout_interval"
        value="halfYearly"
      />
      <RadioInput
        name="payTime"
        :label="t('payYearly')"
        v-model="payData.payout_interval"
        value="yearly"
      />
      <p v-if="errors.payout_interval" class="text-xs py-0.5 text-red-500">{{errors.payout_interval}} </p>
    </div>
    <p class="text-base text-gray-800 pt-1 pb-4">
      <span class="-m-0.5 pr-1">*</span>{{ t("payNote") }}
    </p>
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import {computed} from "vue";
import TextInput from "@/components/forms/TextInput";
import RadioInput from "@/components/forms/RadioInput";

export default {
  mixins: [translateMixin],
  components: {
    TextInput,
    RadioInput,
  },
  props : {
    modelValue: {
      type: Object,
    },
    errors:{}
  },
  setup(props, {emit}) {
    // *** Data
    const payData = computed({
      get: () => props.modelValue ? props.modelValue : {},
      set: (value) => emit("update:modelValue", value),
    });

    return {
      payData,
    };
  },
};
</script>
