<template>
    <div class="w-full flex mx-auto flex-col max-w-lg  p-5">
                <div class="w-full  flex flex-col lg:max-w-md xl:max-w-lg">
                    <div class="mb-1" >
                        <p class="pt-16 md:pt-32" v-if="!recovery">
                            {{ t('twoFactorLogin.codeText') }}
                        </p>
                        <p class="pt-16 md:pt-32" v-else>
                            {{ t('twoFactorLogin.recoveryText') }}
                         </p>
                    </div>
                    <form @submit.prevent="login" class="w-full grid gap-4 grid-cols-1 pt-8 pb-20">
                        <TextInput
                                v-if="!recovery"
                                type="text"
                                :placeholder="t('code')"
                                :label="t('code')"
                                v-model="code"
                                :errorMessage="errors.code"
                                required
                                name="code"
                        />
                        <TextInput
                                v-else
                                type="text"
                                :placeholder="t('recovery_code')"
                                :label="t('recovery_code')"
                                v-model="recovery_code"
                                :errorMessage="errors.code"
                                required
                                name="recovery_code"
                        />
                        <div class="w-full mx-auto mt-8">
                            <button v-if="!recovery" @click="toggleRecovery" type="button" class="text-center w-full text-stratos-100 px-4 py-2 px-8 bg-white rounded-full font-semibold hover:shadow-md border border-stratos-100">
                                {{t('twoFactorLogin.useRecoveryCode')}}
                            </button>
                            <button v-else  @click="toggleRecovery" type="button"  class="text-center w-full text-stratos-100 px-4 py-2 px-8 bg-white rounded-full font-semibold hover:shadow-md border border-stratos-100">
                                {{t('twoFactorLogin.use2FactorCode')}}
                            </button>
                        </div>
                        <div class="w-full sm:w-1/2 mx-auto mt-8">
                            <Button type="submit" primary :text="t('twoFactorLogin.login')" class="w-full" />
                        </div>
                    </form>
                </div>
            </div>
</template>

<script>
    import { useStore } from "vuex";
    import translateMixin from "@/mixins/translate.js";
    import { ref } from "vue";
    import Button from "@/components/atoms/Button";
    import TextInput from "@/components/forms/TextInput";
    import useValidation from "@/mixins/useValidation.js";
    import Router from "../../router";
    export default {
        mixins: [translateMixin],
        components: {
            TextInput,
            Button,
        },
        setup() {
            const store = useStore();
            const { errors, hasErrors } = useValidation();
            const recovery = ref(false);
            const code = ref();
            const recovery_code = ref();

            function toggleRecovery() {
                errors.value = {};
                code.value = "";
                recovery_code.value = "";
                recovery.value = !recovery.value;
            }

            async function login() {
                this.errors = {}
                if (!hasErrors.value) {
                    try {
                        await store.dispatch("twoFactorAuth", {
                                code:code.value,
                                recovery_code:recovery_code.value
                            });
                    } catch (errors) {
                        this.errors = errors;
                        setTimeout(function () {
                            Router.push({path: '/login'})
                        },1000);
                    }
                }
            }

            return {
                login,
                errors,
                recovery,
                toggleRecovery,
                code,
                recovery_code
            };
        },
    };
</script>
