<template>
  <div
    class="w-full h-screen md:h-full min-h-layout flex bg-secondary-100 flex-col justify-center bg-none md:bg-two-persons bg-contain md:bg-right-top bg-no-repeat"
  >
    <div
      class="w-full flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto px-4 xl:px-8 2xl:px-0 bg-two-persons md:bg-none bg-contain bg-bottom bg-no-repeat"
    >
      <div class="w-full md:w-1/2 flex flex-col md:py-10">
        <h1 class="text-3xl sm:text-4xl lg:text-5xl font-black pt-16">
          {{ t("welcomeHeading") }}
        </h1>
        <p class="pt-4 text-2xl lg:text-3xl">{{ t("welcomeSubheading1") }}</p>
        <p class="pt-6 text-2xl lg:text-3xl">{{ t("welcomeSubheading2") }}</p>
        <div class="w-full flex justify-start">
          <div class="w-full sm:w-auto pt-20 mb-16 md:pb-0 flex-col">
            <Button primary to="/register" :text="t('welcomeButton')" />
            <p class="w-full justify-center flex pt-3">
              {{ t("loginQuestion") }}
              <router-link
                to="/login"
                class="pl-1 font-semibold underline"
                :text="t('loginButton')"
              />
            </p>
          </div>
        </div>
      </div>
      <div
        class="w-full md:w-1/2 block overflow-hidden flex-col h-full min-h-layout mt-64 md:mt-16"
      ></div>
    </div>
    <div class="w-full flex bg-secondary-100 md:bg-white pb-16 mt-12 sm:mt-0">
      <div class="w-full flex max-w-screen-xl mx-auto px-4 xl:px-8 2xl:px-0 gab-8">
        <div class="w-full xl:w-4/5 flex -mt-12 flex-col md:flex-row">
          <div
            v-for="(box, index) in boxes"
            class="w-full md:w-1/3 flex h-full"
            :key="index"
          >
            <WelcomeBox
              :to="box.to"
              :href="box.href"
              :text="box.text"
              :icon="box.icon"
              class="mb-8 md:mb-0 md:mr-8"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import { ref } from "vue";
import Button from "@/components/atoms/Button";
import WelcomeBox from "@/components/atoms/WelcomeBox";
export default {
  mixins: [translateMixin],
  components: {
    Button,
    WelcomeBox,
  },
  setup() {
    const boxes = ref([
      { href: "#", to: "/register", text: "userBox", icon: "user" },
      { href: "#", to: "", text: "shareCodeBox", icon: "speech-bubble" },
      { href: "#", to: "", text: "rewardCreditsBox", icon: "wallet" },
    ]);
    return {
      boxes,
    };
  },
};
</script>
