import axios from 'axios'
import Router from '../../router/index'
import {authRegister} from "../auth/auth";

const baseURL = process.env.VUE_APP_BASE_URL;
const serviceId = process.env.VUE_APP_SERVICE_ID;

const Register = {
    state: {
        status: 'idle', // idle, loading, rejected or success
        email: '',
        first_name: '',
    },
    mutations: {
        setUserData(state, payload) {
            const {email, first_name} = payload;
            state.email = email;
            state.first_name = first_name;
            state.status = 'success';
        },
        rejectRequest(state) {
            state.status = 'rejected';
        },
        fulfillRequest(state) {
            state.status = 'success';
        },
        setStatusLoading(state) {
            state.status = 'loading';
        }
    },
    actions: {
        register({commit}, payload) {
            commit('setStatusLoading');
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
                    const data = {
                        service_uuid: serviceId,
                        first_name: payload.firstName,
                        last_name: payload.lastName,
                        email: payload.email,
                        password: payload.password,
                        password_confirmation: payload.confirmPassword,
                    };

                    axios.post(`${baseURL}/spaApi/register`, data).then((res) => {
                        if (res.status !== 201) {
                            commit('rejectRequest');
                            return 0;
                        }
                        authRegister();
                        Router.push({path: '/resend-email'});
                        commit('setUserData', data);
                    }).catch(error => {
                        commit('rejectRequest');
                        const {errors} = error.response.data;
                        let errorMessages = [];
                        if ('first_name' in errors) {
                            errorMessages['firstName'] = errors['first_name'][0]
                        }
                        if ('last_name' in errors) {
                            errorMessages['lastName'] = errors['last_name'][0]
                        }
                        if ('email' in errors) {
                            errorMessages['email'] = errors['email'][0]
                        }
                        if ('password' in errors) {
                            errorMessages['password'] = errors['password'][0]
                        }
                        reject(errorMessages)
                    })
                })
            })
        },
    },
    getters: {}
};

export default Register
