<template>
  <DefaultLayout>
    <div class="w-full flex flex-col max-w-xl mx-auto">
      <h1 class="main-heading text-left md:text-center pt-16 md:pt-32">
        {{ t(heading) }}
      </h1>
      <Steps
        @setCurrentStep="setCurrentStep"
        :steps="steps"
        :current="currentStep"
        class="py-8 px-12"
      />
      <PersonalDataStep
        v-model="personalData"
        :user="user"
        :errors="errors"
        v-show="currentStep === 1"
      />
      <BankDataStep v-model="payData" :errors="errors" v-show="currentStep === 2" />
      <MoreInfoStep v-model="personalData" :errors="errors" v-show="currentStep === 3" />
      <MarktingStep
        v-model="mediaData"
        :errors="errors"
        @toggleMediaItem="toggleMediaItem"
        v-show="currentStep === 4"
      />
      <SummaryStep
        v-show="currentStep === 5"
        :errors="errors"
        @setCurrentStep="setCurrentStep"
      />
      <ConditionsStep v-model="conditionsSeen" v-show="currentStep === 6" />
      <div
        class="w-full grid sm:flex sm:flex-row gap-4 sm:justify-between py-4 mb-12"
      >
        <div class="w-full flex sm:w-1/2 justify-start">
          <Button
            @click="previous"
            type="submit"
            :text="t('previous')"
            :class="currentStep < 2 ? 'opacity-0 h-0 hidden sm:flex' : ''"
            class="w-full sm:w-auto py-3 sm:px-0 xs:font-medium text-gray-800"
          />
        </div>
        <div class="w-full flex sm:w-1/2 justify-end px-12 sm:px-0">
          <Button
            @click="next"
            primary
            :text="t('next')"
            :disabled="currentStep == 6 && !conditionsSeen"
            class="w-full sm:w-auto px-20"
          />
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import { ref, computed, watch } from "vue";
import router from "@/router";
import DefaultLayout from "@/components/layouts/Default";
import Button from "@/components/atoms/Button";
import Steps from "@/components/atoms/Steps";
import PersonalDataStep from "@/components/completeData/PersonalDataStep";
import BankDataStep from "@/components/completeData/BankDataStep";
import MoreInfoStep from "@/components/completeData/MoreInfoStep";
import MarktingStep from "@/components/completeData/MarktingStep";
import SummaryStep from "@/components/completeData/SummaryStep";
import ConditionsStep from "@/components/completeData/ConditionsStep";
import { useStore } from "vuex";

export default {
  mixins: [translateMixin],
  components: {
    DefaultLayout,
    Button,
    Steps,
    PersonalDataStep,
    BankDataStep,
    MoreInfoStep,
    MarktingStep,
    SummaryStep,
    ConditionsStep,
  },

  setup() {
    // *** Data
    const store = useStore();
    store.dispatch("getUser");
    store.dispatch("payOut/getPayout");
    store.dispatch("marketingChannels/getMarketingChannels");

    const conditionsSeen = ref(false);
    const hasDone = ref(false);

    const user = computed(() => {
      return store.getters.user;
    });
    const payOutData = computed(() => {
      return store.state.payOut.payOutData;
    });
    let errors = ref({});
    // const personalData = ref(user.value);
    const personalData = computed(() => store.getters.user);
    const payData = ref(payOutData);
    const steps = ref(6);
    const currentStep = ref(1);

            let mediaDataOnLines = [
                { name: "facebook", label: "Facebook", value: "", disabled: true },
                { name: "whatsapp", label: "WhatsApp", value: "", disabled: true },
                { name: "twitter", label: "Twitter", value: "", disabled: true },
                { name: "xing", label: "XING", value: "", disabled: true },
                { name: "linkedin", label: "LinkedIn", value: "", disabled: true },
                { name: "instagram", label: "Instagram", value: "", disabled: true },
                { name: "snapchat", label: "Snapchat", value: "", disabled: true },
                { name: "telegram", label: "Telegram", value: "", disabled: true },
                { name: "tiktok", label: "TikTok", value: "", disabled: true },
            ];


            const mediaData = computed(() => {
                const channels = store.state.marketingChannels.marketingChannels;
                let description = "";
                let channelNames = [];
                if (channels && channels.length > 0) {
                    channels.forEach((item) => {
                        if (item.name) {
                            channelNames[item.name] = item.profile_url;
                        }
                        if (item.description) {
                            description = item.description;
                        }
                    });
                }
                mediaDataOnLines.map((item) => {
                    item.value = "";
                    item.disabled = true;
                  if (channelNames[item.name] !== undefined) {
                      item.value = channelNames[item.name];
                      item.disabled = false;
                    }
                    return item;
                });
                return {
                    online: mediaDataOnLines,
                    offline: { description: description },
                };
            });
            function toggleMediaItem(mediaItem) {
                mediaDataOnLines.map(media => {
                    if (media.name === mediaItem.name) {
                        media["disabled"] = !mediaItem.disabled;
                    }
                    return media;
                });
            }
            const heading = computed(() => {
                switch (currentStep.value) {
                    case 1:
                        return "personalData";
                    case 2:
                        return "payInfo";
                    case 3:
                        return "moreInfo";
                    case 4:
                        return "marktingChannels";
                    case 5:
                        return "summary";
                    case 6:
                        return "conditionsOfParticipation";
                    default:
                        return "personalData";
                }
            });

    // scroll to top everytime step changes
    watch(currentStep, () => {
      window.scrollTo(0, 0);
      if(currentStep.value === 5) {
          hasDone.value = true;
      }
        });
    // **** Methods
    // next step of completeData
    function next() {
        if (currentStep.value === 1) {
        setPersonalData();
        }
        if (currentStep.value === 2) {
        setPayouts();
        }
        if (currentStep.value === 3) {
        setMoreInfo();
        }
        if (currentStep.value === 4) {
        setMarketingChannel();
        }
        if (currentStep.value === 5) {
        setTimeout(() => {
            nextStep();
        }, 1000);
        }
        if (currentStep.value === 6) {
        setAccepted();
        }
    }

    function nextStep() {
        if (hasDone.value && currentStep.value < 5) {
            currentStep.value = 5;
        } else {
            if (currentStep.value <= steps.value) currentStep.value++;
            if (currentStep.value > steps.value) router.push({ name: "RegisterSuccess" });
        }
    }

    // previous step of completeData
    function previous() {
        if(currentStep.value < 5) {
            hasDone.value = false
        }
      if (currentStep.value > 1) currentStep.value--;
    }

    // set current step
    function setCurrentStep(step) {
      currentStep.value = step;
    }

    function setPersonalData() {
      errors.value = {};
      const personalDataForm = personalData.value;
      store
        .dispatch("setPersonalData", {
          company: personalDataForm.company,
          street: personalDataForm.street,
          city: personalDataForm.city,
          zip: personalDataForm.zip,
          additional_address: personalDataForm.additional,
          vat_no: personalDataForm.vat_no,
        })
        .then(() => {
          nextStep();
        })
        .catch((errorMessages) => {
          errors.value = errorMessages;
        });
    }

    function setPayouts() {
      errors.value = {};
      const payDataForm = payData.value;
      store
        .dispatch("payOut/setPayouts", payDataForm)
        .then(() => {
          nextStep();
        })
        .catch((errorMessages) => {
          errors.value = errorMessages;
        });
    }

    function setMoreInfo() {
      errors.value = {};
      const moreInfoForm = personalData.value;
      store
        .dispatch("setMoreInfo", {
          self_intro: moreInfoForm.self_intro,
          marketing_plan: moreInfoForm.marketing_plan,
          industry: moreInfoForm.industry,
        })
        .then(() => {
          nextStep();
        })
        .catch((errorMessages) => {
          errors.value = errorMessages;
        });
    }

            function setMarketingChannel() {
                errors.value = {};
                const offline = mediaData.value.offline
                const channelsForm = {
                    online: [],
                    offline: { description: offline.description },
                };
                mediaDataOnLines.forEach((media) => {
                    if (!media["disabled"]) {
                        media["profile_url"] = media.value;
                        channelsForm["online"].push(media);
                    }
                });

      store
        .dispatch("marketingChannels/setMarketingChannel", {
          channels: channelsForm,
        })
        .then(() => {
          nextStep();
        })
        .catch((errorMessages) => {
          errors.value = errorMessages;
        });
    }

    function setAccepted() {
      errors.value = {};
      store
        .dispatch("setAccepted", {
          terms_accepted: true,
        })
        .then(() => {
          router.push({ name: "RegisterSuccess" });
        })
        .catch((errorMessages) => {
          errors.value = errorMessages;
        });
    }

    return {
      user,
      personalData,
      payData,
      mediaData,
      steps,
      currentStep,
      heading,
      errors,
      next,
      previous,
      setCurrentStep,
      conditionsSeen,
      toggleMediaItem,
      hasDone
    };
  },
};
</script>
