<template>
  <div class="w-full flex flex-col">
    <Suspense>
      <template #default>
        <div class="w-full">
          <!-- As Desktop -->
          <div class="w-full hidden md:flex flex-col">
            <table class="w-full text-center reports-table">
              <tr class="p-4 bg-gray-300 text-lg rounded">
                <th class="rounded-l">{{ t("date") }}</th>
                <th>{{ t("action") }}</th>
                <th>{{ t("levelCredit") }}</th>
                <th class="rounded-r">{{ t("bonuses") }}</th>
              </tr>

              <!-- with real data will use v-for loop (Desktop version) -->
              <tr
                v-for="(column, index) in reports"
                :key="`desktop-${index}`"
                class="border-b py-4"
              >
                <td>{{ column.date ? dateFormat(column.date) : t('pending') }}</td>
                <td class="flex justify-center">
                  <Badge :class="column.type == 'withdraw' ? 'bg-payout' : ''">{{
                    column.type !== "withdraw" ? t("bonuses") : t("payout")
                  }}</Badge>
                </td>
                <td>
                  <span v-if="column.type" class="font-medium capitalize">
                    <span v-if="column.type !== 'withdraw'">
                      {{ column.note }}
                    </span>
                    <a :href="column.url" target="_blank" v-else class="underline">
                      {{ column.note }}
                    </a>
                  </span>
                </td>
                <td>
                  {{ column.type == "withdraw" ? "-" : ""
                  }}{{ currencyFromat(column.value) }} Euro
                </td>
              </tr>
              <!------------->
            </table>
            <div v-if="showEmptyTable" class="w-full flex py-16">
              <EmptyTable />
            </div>
          </div>
          <!-- As Mobile -->
          <div class="w-full grid gap-2 md:hidden">
            <!-- with real data will use v-for loop (Mobile version) -->
            <div
              v-for="(column, index) in reports"
              :key="`mobile-${index}`"
              class="w-full flex flex-col rounded bg-gray-50 border border-gray-400 p-4 my-1"
            >
              <div class="w-full flex justify-start py-3">
                <p class="font-semibold w-28">{{ t("date") }}</p>
                <p>{{ column.date ? dateFormat(column.date) : t('pending') }}</p>
              </div>
              <div class="w-full flex justify-start py-2">
                <p class="font-semibold w-28">{{ t("action") }}</p>
                <Badge :class="column.type == 'withdraw' ? 'bg-payout' : ''">
                  <span class="font-medium">{{
                    column.type !== "withdraw" ? t("bonuses") : t("payout")
                  }}</span>
                </Badge>
              </div>
              <div class="w-full flex justify-start py-3">
                <p class="font-semibold w-28">{{ t("level") }}</p>
                <p class="text-sm mt-px pt-px">
                  <span v-if="column.type" class="font-medium capitalize">
                    <span v-if="column.type !== 'withdraw'">
                      {{ column.note }}
                    </span>
                    <a :href="column.url" target="_blank" v-else class="underline">
                      {{ column.note }}
                    </a>
                  </span>
                </p>
              </div>
              <div class="w-full flex justify-start py-3">
                <p class="font-semibold w-28">{{ t("bonuses") }}</p>
                <p>
                  {{ column.type == "withdraw" ? "-" : ""
                  }}{{ currencyFromat(column.value) }} Euro
                </p>
              </div>
            </div>
            <!------------->
            <div v-if="showEmptyTable" class="w-full flex py-6">
              <EmptyTable />
            </div>
          </div>
        </div>
      </template>
      <template #fallback> <Loader /> </template>
    </Suspense>
  </div>
</template>

<script>
import Badge from "@/components/atoms/Badge";
import EmptyTable from "@/components/atoms/EmptyTable";
import Loader from "@/components/atoms/Loader";
import translateMixin from "@/mixins/translate.js";
import useFormat from "@/mixins/useFormat.js";
import { ref, watch } from "vue";
export default {
  components: {
    Badge,
    Loader,
    EmptyTable,
  },
  mixins: [translateMixin],
  props: {
    reports: { type: [Array,Object], default: () => [] },
    total: { type: [Number, String], default: 0 },
  },

  setup(props) {
    const {currencyFromat, dateFormat} = useFormat();
    const showEmptyTable = ref(false)
    watch(
       () => props.total,
       () => {
          showEmptyTable.value = props.total === 0;
        }
    );
    return {
      currencyFromat,
      dateFormat,
      showEmptyTable
    };
  },
};
</script>
<style scoped>
.reports-table th {
  @apply font-medium py-4;
}
.reports-table td {
  @apply py-3;
}
</style>
