<template>
  <MixedLayout :src="require('@/assets/images/illu/person-two-hands.svg')">
    <div class="w-full flex flex-col md:py-10">
      <h1 class="text-2xl lg:text-3xl font-bold mt-12">
        {{ t("sucessRegister") }}
      </h1>
      <p class="py-8 text-xl lg:text-2xl">
        {{ t("sucessRegisterText") }}
      </p>
      <div class="w-full flex">
        <div class="w-full sm:w-3/5">
          <TextInput
            v-model="code"
            icon="magic-stick"
            name="Code"
            iconPosition="end"
            :errorMessage="errors"
            type="text"
            @clickIcon="generateCode"
            @hoverIcon="hoverCodeIcon($event)"
            :placeholder="t('enterRecommendedCode')"
          />
        </div>
        <transition name="slide-fade">
          <div v-show="showCodeTooltip" class="w-2/5 hidden md:flex relative">
            <div
              class="absolute left-2 top-0 flex-col p-3 rounded bg-white shadow border"
            >
              <p class="text-sm font-semibold">{{ t("generateCode") }}</p>
              <p class="text-xs py-1">{{ t("generateCodeText") }}</p>
            </div>
          </div>
        </transition>
      </div>
      <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 mt-8">
        <Button
          @click="toggleCodeModal(true)"
          primary
          :text="t('saveCode')"
          class="w-full"
        />
      </div>
      <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 mb-8 mt-2">
        <Button
          @click="skip"
          :text="t('skip')"
          class="w-full underline text-gray-800 font-semibold"
        />
      </div>
    </div>

    <!-- Code Modal -->
    <Teleport to="#global-modal" v-if="showConfirmModal">
      <div class="w-full global-modal-wrapper">
        <div
          class="w-11/12 md:w-1/2 2xl:w-1/3 p-4 md:p-6 rounded bg-white shadow-lg text-center flex flex-col relative"
        >
          <div
            @click="toggleCodeModal(false)"
            class="w-4 h-4 absolute top-4 right-4 cursor-pointer"
          >
            <Icon name="close" class="w-4 h-4 flex" />
          </div>

          <h1 class="text-3xl md:text-5-xl font-black py-2">
            {{ t("codePopupTitle") }}
          </h1>
          <p class="py-4 text-lg">
            {{ t("codePopupText") }}
          </p>
          <p v-if="code" class=" flex w-auto mx-auto py-2 px-4 font-semibold rounded bg-gray-100">{{code}}</p>
          <div class="w-full flex flex-col mt-2">
            <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 mt-8 mx-auto">
              <Button @click="saveCode" primary :text="t('saveCode')" class="w-full" />
            </div>
            <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 mb-8 mt-4 mx-auto">
              <Button
                @click="toggleCodeModal(false)"
                :text="t('codePopupEditButton')"
                class="w-full underline text-gray-800 font-semibold"
              />
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </MixedLayout>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import { ref } from "vue";
import router from "@/router";
import Button from "@/components/atoms/Button";
import Icon from "@/components/atoms/Icon";
import TextInput from "@/components/forms/TextInput";
import MixedLayout from "@/components/layouts/Mixed";
import { useStore } from "vuex";

export default {
  mixins: [translateMixin],
  components: {
    Button,
    TextInput,
    Icon,
    MixedLayout,
  },
  setup() {
    const store = useStore();
    const code = ref("");
    const showConfirmModal = ref(false);
    const showCodeTooltip = ref(false);
    let errors = ref(false);
    function toggleCodeModal(val) {
      showConfirmModal.value = val;
    }
    async function generateCode() {
      try {
        let res = await store.dispatch("sendCodeGenerate");
        code.value = res.data.code;
      } catch (error) {
        errors.value = error.join("\n");
      }
    }
    function hoverCodeIcon(event) {
      showCodeTooltip.value = event.hover;
    }
    async function saveCode() {
      try {
        await store.dispatch("sendRegistrationCode", {
          code: code.value,
        });
        router.push({ name: "Home" });
      } catch (error) {
        errors.value = error.join("\n");
        showConfirmModal.value = false;
      }
    }
    function skip() {
      router.push({ name: "Home" });
    }

    return {
      code,
      errors,
      showConfirmModal,
      showCodeTooltip,
      saveCode,
      toggleCodeModal,
      hoverCodeIcon,
      generateCode,
      skip,
    };
  },
};
</script>
