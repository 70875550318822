<template>
    <MixedLayout :src="require('@/assets/images/illu/person-two-hands.svg')">
    <div class="w-full flex flex-col max-w-lg mx-auto pt-8">
      <h1 class="main-heading text-left md:text-center pb-8 pt-16 md:pt-20">
        {{ t("createNewPassword") }}
      </h1>
      <form
        @submit.prevent="sendPassword"
        class="w-full grid gap-4 grid-cols-1 pt-8 pb-20"
      >
      <input type="hidden" name="token" />
        <TextInput
          type="email"
          :placeholder="t('emailAddress')"
          :label="t('emailAddress')"
          disabled
          v-model="form.email"
          name="email"
        />
        <TextInput
          type="password"
          :placeholder="t('password')"
          :label="t('password')"
          required
          v-model="form.password"
          name="password"
          :errorMessage="errors['password']"
        />
        <TextInput
          type="password"
          :placeholder="t('confirmPassword')"
          :label="t('confirmPassword')"
          required
          v-model="form.confirmPassword"
          name="confirmPassword"
          :errorMessage="errors['confirmPassword']"
        />
        <div class="w-full md:w-2/3 mx-auto mt-8">
          <Button type="submit" primary :text="t('setPasswordBack')" class="w-full" />
        </div>
      </form>
    </div>
  </MixedLayout>
</template>

<script>
// import router from "@/router";
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import { ref, onMounted } from "vue";
import MixedLayout from "@/components/layouts/Mixed";
import Button from "@/components/atoms/Button";
import router from "@/router";
import { useRoute } from 'vue-router'
import TextInput from "@/components/forms/TextInput";
import {useStore} from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    MixedLayout,
    TextInput,
    Button,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const form = ref({ email: route.query.email }, { password: "" }, { confirmPassword: "" });
    const { errors, hasErrors, validateRegisterPasswords } = useValidation();

    function validateAll() {
      errors.value = {};
      validateRegisterPasswords(form.value.password, form.value.confirmPassword);
    }
    onMounted(() => {
      const str = route.fullPath
      const email = str.split('email=')[1]
      if(email.length) {
        form.value.email = email
      }
    })

   async function sendPassword() {
     this.errors = {}
     validateAll();
      if (!hasErrors.value) {
        try {
          await store.dispatch("resetPassword", {
            email: this.form.email,
            password: this.form.password,
            passwordConfirmation: this.form.confirmPassword,
            token: route.query.token
          })
          store.dispatch("logout")
          router.push({ path: "/login" });
        } catch (errors) {
          this.errors = errors
        }
      }
    }
    return {
      form,
      sendPassword,
      errors,
      hasErrors,
      validateRegisterPasswords,
      validateAll,
    };
  },
};
</script>
