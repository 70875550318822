<template>
  <EditBox ref="editBox" :title="t('twoFactorAuth')" class="relative">
    <template v-slot:content>
      <div class="w-full flex flex-col py-4">
        <div class="w-full flex flex-col">
          <ToggleButton
            v-model="isAuthTwoFactor"
            @change="toggleTwoFactor"
            class="absolute right-4 md:right-6 top-4 md:top-6"
          />
          <p class="text-sm pb-6">{{ t("twoFactorSubTitle") }}</p>

          <h3 v-if="isAuthTwoFactor" class="text-lg font-semibold py-4">{{ t("yourTwoFactorActive") }}</h3>
          <h3 v-else class="text-lg font-semibold py-4">{{ t("yourTwoFactorNotActive") }}</h3>
          <p class="text-sm pb-6">{{ t("setTwoFactorText") }}</p>
          <div v-if="isAuthTwoFactor" class="w-full py-4">
            <div class="flex object-cover bg-cover flex-col sm:flex-row">
              <div v-if="qrCode" v-html="qrCode" />
              <div class="mt-5 sm:mt-0 sm:ml-5">
                <p v-for="code in twoFactorCodes" :key="code" >
                  {{code}}
                </p>
              </div>
            </div>
            <p class="text-sm py-4 mt-3 ">{{ t("saveTwoFactorText") }}</p>
          </div>
        </div>
      </div>
    </template>

    <!-- Edit Modal -->
    <template v-slot:modal> </template>
  </EditBox>
</template>

<script>
import EditBox from "@/components/atoms/EditBox";
import translateMixin from "@/mixins/translate.js";
import {computed, onMounted, ref, watch} from "vue";
import ToggleButton from "@/components/forms/ToggleButton";
import {useStore} from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    EditBox,
    ToggleButton,
  },
  setup() {
    const store = useStore();
    // refs
    const editBox = ref(null);
    const qrCode = ref(null);
    const twoFactorCodes = ref({});
    const errors = ref(null)

    function closeEditModal() {
      editBox.value.closeEditModal();
    }
    const isAuthTwoFactor = ref(false)

    function toggleTwoFactor() {
      if(isAuthTwoFactor.value){
          enableTwoFactorAuthentication()
      }else {
          disableTwoFactorAuthentication()
      }
    }
    const isTwoFactor = computed(() => {
      return store.state.twoFactorAuth.isTwoFactor
    });
    onMounted(() => {
      isAuthTwoFactor.value = !!isTwoFactor.value;
    });
    watch(
         () => isTwoFactor.value,
         () => {
            isAuthTwoFactor.value = !!isTwoFactor.value;
         }
    );


    function enableTwoFactorAuthentication() {
      store.dispatch("twoFactorAuth/enableTwoFactorAuthentication")
         .then((response) => {
           qrCode.value = response.qr_code
           twoFactorCodes.value = response.code
         }).catch((errors) =>{
           errors.value = errors
         })
    }

    function disableTwoFactorAuthentication() {
      store.dispatch("twoFactorAuth/disableTwoFactorAuthentication")
         .then(() => {
           qrCode.value = null
           twoFactorCodes.value = {}
         }).catch((errors) =>{
           errors.value = errors
         })
    }
    return {
      isAuthTwoFactor,
      twoFactorCodes,
      qrCode,
      editBox,
      closeEditModal,
      isTwoFactor,
      toggleTwoFactor,
      enableTwoFactorAuthentication,
      disableTwoFactorAuthentication,
      errors
    };
  },
};
</script>
