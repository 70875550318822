<template>
  <div class="w-full">
    <h1 class="main-heading text-left md:text-center pt-16 md:pt-32">
      {{ t("yourRecommendedCode") }}
    </h1>
    <p class="sub-heading text-left md:text-center pt-1 md:pt-3 pb-4">
      {{ t("yourPersonalKey") }}
    </p>
    <div
      class="w-full rounded border border-gray-400 bg-secondary-50 p-4 md:p-16 my-4 mx-auto max-w-screen-md"
    >
      <p class="text-gray-800">{{ t("recommendedCodeText1") }}</p>
      <p class="text-gray-800 pt-6">{{ t("recommendedCodeText2") }}</p>
    </div>
    <p
      class="pt-6 pb-2 text-gray-700 text-sm text-center px-4 sm:px-12 mx-auto max-w-screen-md"
    >
      {{ t("creatYourKeyNote") }}
    </p>
    <div class="w-full relative flex justify-center mx-auto max-w-screen-lg">
      <div class="w-full sm:w-1/3 pt-6">
        <TextInput
          v-model="code"
          icon="magic-stick"
          name="code"
          required
          iconPosition="end"
          :errorMessage="errors && hasErrors ? errors.code : null"
          type="text"
          @clickIcon="generateCode"
          @hoverIcon="hoverCodeIcon($event)"
          :placeholder="t('enterRecommendedCode')"
        />
      </div>
      <transition name="slide-fade">
        <div
          v-show="showCodeTooltip"
          class="absolute sm:-right-2 top-20 sm:top-0 hidden md:flex flex-col w-full sm:w-1/3 p-3 rounded bg-white shadow border"
        >
          <p class="text-sm font-semibold">{{ t("generateCode") }}</p>
          <p class="text-xs py-1">{{ t("generateCodeText") }}</p>
        </div>
      </transition>
    </div>
    <div class="w-full flex flex-col text-center pb-1">
      <p class="text-xs py-0.5 text-gray-400">{{ t("codeRequirementsText") }}</p>
    </div>
    <div class="w-full flex sm:w-1/2 justify-center mx-auto pt-4 pb-16">
      <Button
        @click="submitCode"
        primary
        :text="t('saveCode')"
        class="w-full sm:w-auto px-20"
      />
    </div>
    <!-- Code Modal -->
    <Teleport to="#global-modal" v-if="showConfirmModal">
      <div class="w-full global-modal-wrapper">
        <div
          class="w-11/12 md:w-1/2 2xl:w-1/3 p-4 md:p-6 rounded bg-white shadow-lg text-center flex flex-col relative"
        >
          <div
            @click="toggleCodeModal(false)"
            class="w-4 h-4 absolute top-4 right-4 cursor-pointer"
          >
            <Icon name="close" class="w-4 h-4 flex" />
          </div>

          <h1 class="text-3xl md:text-5-xl font-black py-2">
            {{ t("codePopupTitle") }}
          </h1>
          <p class="py-4 text-lg">
            {{ t("codePopupText") }}
          </p>
          <p v-if="code" class=" flex w-auto mx-auto py-2 px-4 font-semibold rounded bg-gray-100">{{code}}</p>
          <div class="w-full flex flex-col">
            <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 mt-8 mx-auto">
              <Button @click="saveCode" primary :text="t('saveCode')" class="w-full" />
            </div>
            <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 mb-8 mt-4 mx-auto">
              <Button
                @click="toggleCodeModal(false)"
                :text="t('codePopupEditButton')"
                class="w-full underline text-gray-800 font-semibold"
              />
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import { ref } from "vue";
import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/TextInput";
import Icon from "@/components/atoms/Icon";
import { useStore } from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    Button,
    TextInput,
    Icon,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { errors, hasErrors, validateCode } = useValidation();
    const showCodeTooltip = ref(false);
    const showConfirmModal = ref(false);
    const code = ref("");

    function hoverCodeIcon(event) {
      showCodeTooltip.value = event.hover;
    }

    function toggleCodeModal(val) {
      showConfirmModal.value = val;
    }
    function submitCode() {
      errors.value = {};
      validateCode("code", "Code", code.value);
      if (!hasErrors.value) {
        toggleCodeModal(true);
      }
    }

    async function generateCode() {
      try {
        let res = await store.dispatch("sendCodeGenerate");
        code.value = res.data.code;
      } catch (error) {
        if (error) errors.value = error.join("\n");
      }
    }

    async function saveCode() {
      try {
        await store.dispatch("sendRegistrationCode", {
          code: code.value,
        });
        emit("saveCode");
      } catch (error) {
        if (error) errors.value = error.join("\n");

        showConfirmModal.value = false;
      }
    }

    return {
      showCodeTooltip,
      code,
      saveCode,
      hoverCodeIcon,
      generateCode,
      showConfirmModal,
      toggleCodeModal,
      submitCode,
      hasErrors,
      errors,
      validateCode,
    };
  },
};
</script>
