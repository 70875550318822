<template>
    <MixedLayout :src="require('@/assets/images/illu/person-run.svg')">
      <div v-if="!loading" class="w-full flex flex-col md:py-10">
        <form
          v-if="!emailSent"
          @submit.prevent="sendResetEmail"
          class="w-full grid gap-4 grid-cols-1 pt-8 pb-20"
        >
          <TextInput
            type="email"
            :placeholder="t('emailAddress')"
            :label="t('emailAddress')"
            v-model="email"
            :errorMessage="errors.email"
            required
            name="email"
          />
          <p class="pt-4 text-xl lg:text-2xl">{{ t("forgetPasswordText") }}</p>
          <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 my-8">
            <Button
              type="submit"
              primary
              :text="t('forgetPasswordButton')"
              class="w-full"
            />
          </div>
        </form>
        <div v-else class="w-full flex py-8 flex-col">
          <p class="text-green-500 font-semibold">{{ t("resetPasswordSent") }}</p>
          <div class="w-full sm:w-1/2 md:w-2/3 lg:w-1/2 my-8">
            <Button to="/login" primary>{{ t("goBackToLogin") }}</Button>
          </div>
        </div>
      </div>
      <div v-else class="w-full py-24 relative items-center justify-center">
        <div class="bkf-affiliate-loader"></div>
      </div>
  </MixedLayout>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import { ref } from "vue";
import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/TextInput";
import MixedLayout from "@/components/layouts/Mixed";
import {useStore} from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    Button,
    TextInput,
    MixedLayout,
  },
  setup() {
    const store = useStore();
    const email = ref("");
    const emailSent = ref(false);
    const loading = ref(false)

    const { errors, hasErrors, validateEmail } = useValidation();

  async function sendResetEmail() {
      this.errors = {}
      validateEmail(email.value);
      if (!hasErrors.value) {
        loading.value = true
        try {
          await store.dispatch("forgotPassword", {
            email: this.email,
          });
          emailSent.value = true;
          loading.value = false
        } catch (errors) {
          loading.value = false
          this.errors = errors;
        }
      }
    }
    return {
      email,
      emailSent,
      errors,
      hasErrors,
      validateEmail,
      sendResetEmail,
      loading,
    };
  },
};
</script>
<style scoped>
.bkf-affiliate-loader {
  @apply w-20 h-20 rounded-full inset-0 absolute m-auto shadow-sm;
  border: 18px solid #f3f3f3;
  border-top: 18px solid #3498db;
  -webkit-animation: spin 1.7s linear infinite; /* Safari */
  animation: spin 1.7s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
