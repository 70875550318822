<template>
  <div class="w-full">
    <p class="text-lg text-left md:text-center pt-1 md:pt-3">
      {{ t("conditionText") }}
    </p>
    <div class="w-full bg-gray-100 rounded p-6 my-8">
      <div
        @scroll="setScrollToBottom($event)"
        class="w-full flex flex-col py-1 pr-2 overflow-y-scroll scrollbar-area"
      >
        <TermsBody />
      </div>
    </div>
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import TermsBody from "@/components/atoms/TermsBody";
import { computed, ref } from "vue";
export default {
  mixins: [translateMixin],
  components: {
    TermsBody
  },
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const scrolledToBottom = ref(false);
    const isConditionsSeen = computed({
      get: () => (props.modelValue ? props.modelValue : scrolledToBottom.value),
      set: (value) => emit("update:modelValue", value),
    });

    // if the user scrolls to the last line of conditions, then he can go next
    function setScrollToBottom(e) {
      if (e.target.clientHeight + e.target.scrollTop >= e.target.scrollHeight - 20) {
        scrolledToBottom.value = true;
        emit("update:modelValue", scrolledToBottom.value);
      }
    }

    return {
      isConditionsSeen,
      scrolledToBottom,
      setScrollToBottom,
    };
  },
};
</script>

<style>
.scrollbar-area {
  height: 320px;
}
.scrollbar-area::-webkit-scrollbar {
  @apply w-2;
}
.scrollbar-area::-webkit-scrollbar-track {
  @apply bg-gray-300 rounded-lg;
}
.scrollbar-area::-webkit-scrollbar-thumb {
  @apply bg-primary-300 rounded;
  height: 50%;
}
.scrollbar-area::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-300 shadow;
}
</style>
