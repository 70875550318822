<template>
  <DefaultLayout>
    <div class="w-full pb-16 text-sm md:text-base">
      <div class="py-8">
        <div>
          <h1 class="text-3xl font-bold text-gray-800 leading-tight py-8">
            Datenschutzerklärung
          </h1>
          <div class="w-full flex flex-col leading-relaxed">
            <p>
              Diese Datenschutzerklärung klärt Sie darüber auf, wie wir Ihre
              personenbezogenen Daten im Zusammenhang mit unserem Angebot verarbeiten.
            </p>
            <div class="w-full flex justify-start text-left text-base pt-6">
              <div class="w-auto flex items-start pr-2">
                <p class="font-bold text-gray-800 tracking-wider">I.</p>
              </div>
              <div class="w-auto flex items-start pl-2">
                <p class="font-bold text-gray-800">Begriffsbestimmungen</p>
              </div>
            </div>
            <div class="w-full flex flex-col pl-8 pt-2">
              <p class="py-2">
                „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
                identifizierte oder identifizierbare natürliche Person beziehen; als
                identifizierbar wird eine natürliche Person angesehen, die direkt oder
                indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
                zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu
                einem oder mehreren besonderen Merkmalen identifiziert werden kann, die
                Ausdruck der physischen, physiologischen, genetischen, psychischen,
                wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
                Person sind;
              </p>
              <p class="py-2">
                „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren
                ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation,
                das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen,
                das Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
                Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder
                die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung;
              </p>
              <p class="py-2">
                „Verantwortlicher“ ist die natürliche oder juristische Person, Behörde,
                Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über
                die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                entscheidet; sind die Zwecke und Mittel dieser Verarbeitung durch das
                Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so können der
                Verantwortliche beziehungsweise die bestimmten Kriterien seiner Benennung
                nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden;
              </p>
              <p class="py-2">
                „Empfänger“ ist eine natürliche oder juristische Person, Behörde,
                Einrichtung oder andere Stelle, denen personenbezogene Daten offengelegt
                werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder
                nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach
                dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise
                personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger; die
                Verarbeitung dieser Daten durch die genannten Behörden erfolgt im Einklang
                mit den geltenden Datenschutzvorschriften gemäß den Zwecken der
                Verarbeitung;
              </p>
            </div>

            <div class="w-full flex justify-start text-left text-base pt-6">
              <div class="w-auto flex items-start pr-2">
                <p class="font-bold text-gray-800 tracking-wider">II.</p>
              </div>
              <div class="w-auto flex items-start pl-2">
                <p class="font-bold text-gray-800">Allgemeine Informationen</p>
              </div>
            </div>
            <div class="w-full flex flex-col pl-6 lg:pl-8 pt-2">
              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">1.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">
                    Verantwortlicher für die Datenverarbeitung
                  </p>
                </div>
              </div>
              <div class="w-full flex flex-col pl-4">
                <p class="pt-2">BKF Online-Schulungs GmbH</p>
                <p>Carl-Friedrich-Gauß Str. 8</p>
                <p class="pb-2">72202 Nagold</p>
                <p class="py-2">Telefon: +49 (0) 7452 8479 – 0</p>
                <p class="py-2">Telefax: +49 (0) 7452 8479 – 29</p>
                <p class="py-2">E-Mail: info@bkf-online-schulungen.de</p>
                <p class="py-2">
                  Wir haben keinen Datenschutzbeauftragten bestellt und sind auch nicht
                  zur Bestellung eines Datenschutzbeauftragten verpflichtet.
                </p>
              </div>

              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">2.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">
                    Informationen zu Verarbeitungsvorgängen
                  </p>
                </div>
              </div>
              <div class="w-full flex flex-col pl-4">
                <p class="pt-2">
                  Wir weisen auf die jeweilige Rechtsgrundlage einzelner
                  Verarbeitungsvorgänge hin. Falls wir beabsichtigen, Daten in Drittländer
                  außerhalb der Europäischen Union (EU) oder des Europäischen
                  Wirtschaftsraums (EWR) zu übermitteln, wird darauf ebenfalls
                  hingewiesen.
                </p>
              </div>

              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">3.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">Betroffenenrechte</p>
                </div>
              </div>
              <div class="w-full flex flex-col pl-4">
                <p class="pt-2">Als Betroffener haben Sie folgende Rechte:</p>

                <div class="w-full flex justify-start text-left text-base pt-4">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      gemäß Art. 15 DSGVO können Sie Auskunft über Ihre von uns
                      verarbeiteten personenbezogenen Daten verlangen; ferner können Sie
                      Auskunft verlangen bzgl. der Verarbeitungszwecke, der Kategorien der
                      verarbeiteten personenbezogenen Daten, die Empfänger oder Kategorien
                      von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder
                      noch offengelegt werden, die geplante Speicherdauer bzw. die
                      Kriterien für die Bestimmung der Speicherdauer, die Herkunft ihrer
                      Daten, sofern diese nicht bei Ihnen erhoben wurden, das Bestehen
                      einer automatisierten Entscheidungsfindung einschließlich Profiling
                      und ggf. aussagekräftigen Informationen zu deren Einzelheiten wie
                      Logik, Tragweite und Auswirkungen, das Bestehen eines Rechts auf
                      Berichtigung oder Löschung der Sie betreffenden Daten, das Recht auf
                      Einschränkung der Verarbeitung oder Widerspruch gegen diese
                      Verarbeitung, das Bestehen eines Beschwerderechts bei der
                      Aufsichtsbehörde; schließlich steht Ihnen ein Recht auf Auskunft zu,
                      ob personenbezogene Daten an ein Drittland oder an eine
                      internationale Organisation übermittelt wurden und – falls dies der
                      Fall ist – über die geeigneten Garantien im Zusammenhang mit der
                      Übermittlung;
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base pt-4">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      gemäß Art. 16 DSGVO können Sie die unverzügliche Berichtigung
                      unrichtiger oder die Vervollständigung Ihrer bei uns gespeicherten
                      personenbezogenen Daten verlangen;
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base pt-4">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      gemäß Art. 17 DSGVO können Sie die Löschung Ihrer bei uns
                      gespeicherten personenbezogenen Daten verlangen, soweit nicht die
                      Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und
                      Information, zur Erfüllung einer rechtlichen Verpflichtung, aus
                      Gründen des öffentlichen Interesses oder zur Geltendmachung,
                      Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base pt-4">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      gemäß Art. 18 DSGVO können Sie die Einschränkung der Verarbeitung
                      Ihrer personenbezogenen Daten verlangen, soweit die Richtigkeit der
                      Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist,
                      Sie aber deren Löschung ablehnen und wir die Daten nicht mehr
                      benötigen, Sie die von uns nicht mehr benötigten Daten zur
                      Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                      benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die
                      Verarbeitung eingelegt haben, aber noch nicht feststeht, ob unsere
                      berechtigten Gründe für die Datenverarbeitung Ihr Interesse
                      überwiegen;
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base pt-4">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      gemäß Art. 20 DSGVO können Sie die Überlassung Ihrer
                      personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
                      strukturierten, gängigen und maschinenlesebaren Format oder die
                      Übermittlung an einen anderen Verantwortlichen verlangen;
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base pt-4">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      gemäß Art. 21 DSGVO können Sie Widerspruch gegen die Verarbeitung
                      Ihrer personenbezogenen Daten einlegen, soweit dafür Gründe
                      vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich
                      der Widerspruch gegen Direktwerbung richtet und die Rechtsgrundlage
                      für die Verarbeitung der personenbezogenen Daten berechtigte
                      Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO sind;
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base pt-4">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      gemäß Art. 7 Abs. 3 DSGVO können Sie Ihre einmal erteilte
                      Einwilligung jederzeit gegenüber uns widerrufen. Dies hat zur Folge,
                      dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
                      für die Zukunft nicht mehr fortführen dürfen;
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base pt-4">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      gemäß Art. 77 DSGVO können Sie sich bei einer Aufsichtsbehörde,
                      insbesondere in dem Mitgliedstaat Ihres gewöhnlichen
                      Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen
                      Verstoßes, beschweren.
                    </p>
                  </div>
                </div>

                <p class="pt-6">
                  Möchten Sie die vorstehenden Betroffenenrechte geltend machen, können
                  Sie uns unter den oben genannten Kontaktdaten diesbezüglich jederzeit
                  kontaktieren.
                </p>
              </div>

              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">4.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">
                    Löschung und Einschränkung von personenbezogenen Daten
                  </p>
                </div>
              </div>
              <div class="w-full flex flex-col pl-4">
                <p class="py-2">
                  Soweit in dieser Datenschutzerklärung nicht für den Einzelfall anders
                  geregelt, werden personenbezogene Daten gelöscht, die für die Zwecke,
                  für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht
                  mehr erforderlich sind und für die keine gesetzlichen
                  Aufbewahrungspflichten mehr bestehen.
                </p>
                <p class="py-2">
                  Wir löschen die von uns verarbeiteten personenbezogenen Daten unter den
                  Voraussetzungen des Art. 17 DSGVO auf Verlangen. Personenbezogene Daten,
                  die für andere und gesetzlich zulässige Zwecke erforderlich sind, werden
                  nicht gelöscht. Das gilt beispielsweise für personenbezogene Daten, die
                  zur Verfolgung etwaiger uns zustehender Ansprüche erforderlich die aus
                  handels- oder steuerrechtlichen Gründen von uns aufbewahrt werden
                  müssen. So werden Unterlagen nach § 257 Abs. 1 Nr. 2 und 3 HGB sowie §
                  147 Abs. 1 Nr. 2, 3, 5 AO für 6 Jahre, Unterlagen nach § 257 Abs. 1 Nr.
                  1 und 4 HGB sowie nach § 147 Abs. 1 Nr. 1, 4, 4a AO für 10 Jahre
                  aufbewahrt.
                </p>
                <p class="py-2">
                  Die Verarbeitung dieser Daten wird nach Art. 18 DSGVO eingeschränkt und
                  die Daten nicht für andere Zwecke verarbeitet.
                </p>
              </div>

              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">5.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">Cookies</p>
                </div>
              </div>
              <div class="w-full flex flex-col pl-4">
                <p class="py-2">
                  Im Rahmen unseres Angebotes setzen wir Cookies ein. Cookies sind kleine
                  Textdateien, die Ihr Browser automatisch erstellt und die auf Ihrem
                  Endgerät (Laptop, Tablet, Smartphone, PC, o.ä.) speichert, wenn Sie
                  unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden
                  an, enthalten keine Viren oder sonstige Schadsoftware. In einem Cookie
                  werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem
                  spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht,
                  dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.
                  Cookies dienen hauptsächlich dazu, das Internetangebot
                  nutzerfreundlicher, effektiver und sicherer zu gestalten.
                </p>
                <p class="py-2">
                  Im Rahmen unseres Angebotes setzen wir Cookies ein. Cookies sind kleine
                  Textdateien, die Ihr Browser automatisch erstellt und die auf Ihrem
                  Endgerät (Laptop, Tablet, Smartphone, PC, o.ä.) speichert, wenn Sie
                  unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden
                  an, enthalten keine Viren oder sonstige Schadsoftware. In einem Cookie
                  werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem
                  spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht,
                  dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten.
                  Cookies dienen dazu, die Nutzung unseres Angebots zu ermöglichen und
                  unser Angebot nutzerfreundlicher, effektiver und sicherer zu gestalten.
                </p>
                <p class="py-2">
                  Die meisten Browser akzeptieren Cookies automatisch. Möchten Sie dies
                  nicht, können Sie Ihren Browser jedoch so konfigurieren, dass keine
                  Cookies auf Ihrem Endgerät gespeichert werden oder stets ein Hinweis
                  erscheint, bevor ein neues Cookie angelegt wird. Sie können dem Einsatz
                  von Cookies zu Zwecken des Onlinemarketing für eine Vielzahl von
                  Diensten z.B. auch unter http://www.youronlinechoices.com/ oder über die
                  Deaktivierungsseite der Netzwerkwerbeinitiative
                  http://optout.networkadvertising.org widersprechen. Falls Sie Cookies
                  deaktivieren, können Sie eventuell nicht alle Funktionen unseres
                  Internetangebotes nutzen.
                </p>
                <p class="py-2">
                  Bei den einzelnen Cookies ist jeweils der Name des Cookies, der Zweck,
                  den das Cookie erfüllen soll, ein eventueller Zugriff Dritter auf das
                  Cookie sowie die Funktionsdauer angegeben bzw. nach welchem Zeitraum ein
                  Cookie gelöscht wird. Session-Cookies werden nach dem Ende Ihrer
                  jeweiligen Nutzung unseres Angebots bzw. nach dem Beenden der
                  Browsersitzung gelöscht.
                </p>
                <p class="py-2">
                  Die Cookies, die wir in unserem Angebot einsetzen, sind unbedingt
                  erforderlich. Das bedeutet, dass Sie ohne diese Cookies auf Ihrem
                  Endgerät unser Angebot nicht nutzen können. Durch solche Cookies werden
                  bestimmte Funktionalitäten bereitgestellt, die wir benötigen, um unser
                  Angebot zu betreiben, insbesondere, um einen von Ihnen als Nutzer
                  unseres Angebots ausdrücklich gewünschten Dienst zur Verfügung stellen
                  zu können.
                </p>
                <div class="w-full overflow-x-scroll pr-4 md:pr-0 py-2">
                  <table class="table-auto border">
                    <thead class="font-mont-bold">
                      <tr>
                        <th class="border px-4 py-2">Name des Cookies</th>
                        <th class="border px-4 py-2">Zweck</th>
                        <th class="border px-4 py-2">Funktionsdauer</th>
                        <th class="border px-4 py-2">Zugriff von Dritten</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="border px-4 py-2">XSRF-Token</td>
                        <td class="border px-4 py-2">
                          Verhinderung einer Cross-SiteRequest-Forgery (Sicherheit)
                        </td>
                        <td class="border px-4 py-2">Zwei Stunden</td>
                        <td class="border px-4 py-2">
                          Es werden keine personenbezogenen Daten gespeichert.
                        </td>
                      </tr>
                      <tr>
                        <td class="border px-4 py-2">
                          bkf_online_schulungs_gmbh_session
                        </td>
                        <td class="border px-4 py-2">
                          Informationen und Aktivitäten einer Browsersitzung zuordnen.
                        </td>
                        <td class="border px-4 py-2">Sitzung</td>
                        <td class="border px-4 py-2"></td>
                      </tr>
                      <tr>
                        <td class="border px-4 py-2">
                          <span style="font-family: auto">__</span>cookie_consent
                        </td>
                        <td class="border px-4 py-2">
                          Speichert die vom Besucher ausgewählten Cookie-Einstellungen.
                        </td>
                        <td class="border px-4 py-2">1 Jahr</td>
                        <td class="border px-4 py-2">
                          Es werden keine personenbezogenen Daten gespeichert
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="py-2">
                  Diese durch Cookies verarbeiteten Daten sind für die genannten Zwecke
                  zur Wahrung unserer sich daraus ergebenden berechtigten Interessen sowie
                  der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
                </p>
                <p class="py-2">
                  Um deine individuellen Cookie-Einstellungen anzupassen, klicke auf:
                  <a
                    class="text-cobald-100 hover:text-cobald-80 cursor-pointer"
                    href="#"
                    onclick="window.livewire.emit('manually-load-cookie-consent')"
                    >Cookie-Einstellungen anpassen</a
                  >
                </p>
              </div>
            </div>

            <div class="w-full flex justify-start text-left text-base pt-6">
              <div class="w-auto flex items-start pr-2">
                <p class="font-bold text-gray-800 tracking-wider">III.</p>
              </div>
              <div class="w-auto flex items-start pl-2">
                <p class="font-bold text-gray-800">Einzelne Verarbeitungsvorgänge</p>
              </div>
            </div>
            <div class="w-full flex flex-col pl-6 lg:pl-8 pt-2">
              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">1.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">Hosting</p>
                </div>
              </div>
              <p class="py-2">
                Beim Aufrufen unseres Internetangebotes bzw. der einzelnen Seiten werden
                durch den Browser auf Ihrem Endgerät automatisch Informationen an den
                Server unseres Internetangebotes gesendet. Dabei werden folgende
                Informationen in Logfiles gespeichert:
              </p>
              <div class="w-full flex flex-col pt-6">
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">IP-Adresse des anfragenden Rechners,</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Datum und Uhrzeit des Zugriffs,</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Name und URL der abgerufenen Datei,</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Website, von der aus der Zugriff erfolgt (Referrer-URL),
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      verwendeter Browser und ggf. das Betriebssystem Ihres Rechners,
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Statuscodes und übertragene Datenmenge,</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Typ des verwendeten Endgeräts,</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Name Ihres Access-Providers.</p>
                  </div>
                </div>
              </div>
              <p class="pt-4">Diese Daten werden zu folgenden Zwecken verarbeitet:</p>
              <div class="w-full flex flex-col pt-2">
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Bereitstellung des Internetangebotes einschließlich sämtlicher
                      Funktionen und Inhalte
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Gewährleistung einer komfortablen Nutzung unserer Website,
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Sicherstellung der Systemsicherheit und -stabilität,
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Anonymisierte statistische Auswertung der Zugriffe,
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Optimierung der Webseite,</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Weitergabe an Strafverfolgungsbehörden, sofern ein rechtswidriger
                      Eingriff/Angriff auf unsere Systeme erfolgt ist,
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">weitere administrative Zwecke.</p>
                  </div>
                </div>
              </div>
              <p class="py-2">
                Diese Daten werden nach sechs Monaten gelöscht, soweit sie nicht mehr für
                andere Zwecke (zum Beispiel Abwehr oder Geltendmachung von Ansprüchen)
                erforderlich sind.
              </p>
              <p class="py-2">
                Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit.
                f DSGVO. Unser berechtigtes Interesse folgt aus oben dargestellten Zwecken
                zur Datenerhebung.
              </p>
              <p class="py-2">
                Zur Bereitstellung unseres Angebotes nehmen wir Leistungen von
                Hostingunternehmen in Anspruch, wie z.B. Bereitstellung von Webservern,
                Speicherplatz, Datenbankdienste, Sicherheitsleistungen und
                Wartungsleistungen. Hierbei verarbeiten wir, bzw. unsere Dienstleister in
                unserem Auftrag personenbezogene Daten der Nutzer unseres Angebotes auf
                Grundlage unserer berechtigten Interessen an einer effizienten und
                sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1
                lit. f DSGVO.
              </p>
              <p class="py-2">
                Wir nutzen einen spezialisierten Dienstleister, um aus der BKF-App E-Mails
                und SMS zu verschicken. Dazu übermitteln wir Ihre Daten auf Grundlage
                unserer berechtigten Interessen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO an
                einer sicheren und effizienten Verwaltung unseres Unternehmens und
                Abwicklung der Aufträge unserer Kunden an einen externen Anbieter, der der
                diese Leistungen für uns im Auftrag erbringt.
              </p>

              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">2.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">Registrierung / Nutzerkonto</p>
                </div>
              </div>
              <p class="py-2">
                Damit wir für unser Angebot ein Nutzerkonto für Sie anlegen können, müssen
                die folgenden Daten angegeben werden:
              </p>

              <div class="w-full flex flex-col">
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Name</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Adresse</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Mobiltelefonnummer</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">E-Mail-Adresse</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Geburtsdatum und –ort</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Führerscheinnummer, Ausstellungsdatum des Führerscheins,
                      Ausstellungsbehörde, Gültigkeitsdauer, Führerscheinklassen
                    </p>
                  </div>
                </div>
                <div class="w-full flex flex-col pl-2 md:pl-4">
                  <div class="w-full flex justify-start text-left text-base">
                    <div class="w-auto flex items-start pr-2">
                      <p class="text-black-600 tracking-wider">a.</p>
                    </div>
                    <div class="w-auto flex items-start pl-2">
                      <p class="text-black-600">
                        Wenn Sie das Nutzerkonto für sich persönlich anlegen, um unsere
                        Schulungen für Sie persönlich zu nutzen, gilt das folgende:<br />
                        Die Registrierung ist freiwillig und erfolgt nach Art. 6 Abs. 1 S.
                        1 lit. b DSGVO auf Grundlage Ihrer Einwilligung. Ihre Daten werden
                        für die Zwecke unseres Angebotes sowie zur Kontaktaufnahme für
                        Angebots- und registrierungsrelevante Informationen verwendet.
                        Über einen persönlichen Benutzerzugang können Sie Ihre Daten
                        einsehen und ändern. Ihre Daten werden so lange gespeichert, bis
                        Sie das Benutzerkonto löschen oder uns mit der Löschung Ihrer
                        Daten beauftragen. Sofern wir Ihre personenbezogenen Daten
                        aufgrund, insbesondere steuer- und handelsrechtlicher gesetzlicher
                        Pflichten, speichern müssen, wird die Verarbeitung Ihrer
                        personenbezogenen Daten bis zum Ablauf der Aufbewahrungsfristen
                        entsprechend eingeschränkt. Danach werden diese Daten gelöscht.
                      </p>
                    </div>
                  </div>
                  <div class="w-full flex justify-start text-left text-base">
                    <div class="w-auto flex items-start pr-2">
                      <p class="text-black-600 tracking-wider">b.</p>
                    </div>
                    <div class="w-auto flex items-start pl-2">
                      <p class="text-black-600">
                        Falls Sie bei uns ein Nutzerkonto anlegen, um als Mitarbeiter
                        einer Firma oder Organisation unsere Schulungen zu nutzen, oder
                        falls eine Firma oder Organisation uns Ihre E-Mail-Adresse
                        mitgeteilt hat, um Sie zur Nutzung unserer Schulungen einzuladen,
                        gilt das folgende: <br />
                        Die Registrierung erfolgt nach Art. 6 Abs. 1 S. 1 lit. f DSGVO auf
                        Grundlage unserer berechtigten Interessen und der berechtigten
                        Interessen der Firma oder Organisation, für die Sie tätig sind.
                        Ihre Daten werden für die Zwecke unseres Angebotes sowie zur
                        Kontaktaufnahme für Angebots- und registrierungsrelevante
                        Informationen verwendet. Über einen persönlichen Benutzerzugang
                        können Sie Ihre Daten einsehen und ändern. Ihre Daten werden so
                        lange gespeichert, bis Sie das Benutzerkonto löschen oder uns mit
                        der Löschung Ihrer Daten beauftragen. Sofern wir Ihre
                        personenbezogenen Daten aufgrund, insbesondere steuer- und
                        handelsrechtlicher gesetzlicher Pflichten, speichern müssen, wird
                        die Verarbeitung Ihrer personenbezogenen Daten bis zum Ablauf der
                        Aufbewahrungsfristen entsprechend eingeschränkt. Danach werden
                        diese Daten gelöscht.
                      </p>
                    </div>
                  </div>
                </div>
                <p class="py-2">
                  Wir stellen unseren Firmenkunden unsere Schulungen für Mitarbeiter als
                  Dienstleister zur Verfügung und verpflichten unsere Firmenkunden dabei
                  zur Einhaltung der datenschutzrechtlichen Vorschriften.
                </p>
                <p class="py-2">
                  Für die Übermittlung von Mitarbeiterdaten an uns ist die jeweilige Firma
                  oder Organisation verantwortlich. Falls Sie Fragen zur Übermittlung
                  Ihrer Daten durch eine Firma oder Organisation an uns haben, dann wenden
                  Sie sich bitte an die betreffende Firma oder Organisation.
                </p>
                <p class="py-2">
                  Wenn Sie sich für unser Angebot registrieren oder das Benutzerkonto
                  nutzen, speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen
                  Nutzungshandlung. Die Speicherung erfolgt auf Grundlage unserer und
                  Ihrer berechtigten Interessen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
                  Bereitstellung unseres Angebots und zum Schutz vor Missbrauch und
                  sonstiger unbefugter Nutzung zu schützen. Das Nutzerkonto und die in
                  diesem Zusammenhang gespeicherten Daten dienen insbesondere auch dazu,
                  die Buchung unserer Kurse und die Nutzung der BKF-App zu erleichtern und
                  auf Ihren Nutzungsverlauf zugreifen zu können. Eine Weitergabe dieser
                  Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Erfüllung
                  vertraglicher Pflichten nach Art. 6 Abs. 1 lit. b DSGVO oder zur
                  Verfolgung etwaiger uns zustehender Ansprüche erforderlich oder es
                  besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c
                  DSGVO. Die IP-Adressen werden spätestens nach 7 Tagen anonymisiert oder
                  gelöscht.
                </p>
              </div>
              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">3.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">2-Faktor-Authentifizierung</p>
                </div>
              </div>
              <p class="py-2">
                In Ihrem Nutzerkonto können Sie für erhöhte Sicherheit eine
                2-Faktor-Authentifizierung aktivieren. Wenn Sie die
                2-Faktor-Authentifizierung aktiviert haben, wird Ihnen ein QR-Code
                angezeigt, den sie mit einer gängigen Smartphone-App für Authentifizierung
                einlesen können. Dabei werden unser Firmenname und Ihre E-Mail-Adresse,
                die für das Nutzerkonto genutzt wird, an die App übermittelt. Bitte
                beachten Sie, dass wir keinerlei Einfluss darauf haben, wie die Anbieter
                solcher Apps Ihre personenbezogenen Daten verarbeiten. Mit dem aus dem
                QR-Code ausgelesenen Schlüssel kann die von Ihnen verwendete App sichere
                zufällige Zahlencodes generieren, die Sie bei der Anmeldung zusätzlich
                angeben müssen.
              </p>
              <p class="py-2">
                Falls Sie die 2-Faktor-Authentifizierung nutzen, speichern wir das in
                Ihrem Nutzerkonto. Ihre Daten werden nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
                auf Grundlage der von Ihnen erteilten Einwilligung verarbeitet. Ohne diese
                Einwilligung können Sie die 2-Faktor-Authentifizierung nicht nutzen.
              </p>
              <p class="py-2">
                Bitte speichern Sie in jedem Fall den Backup-Code, der Ihnen bei der
                Aktivierung der 2-Faktor-Authentifizierung angezeigt wird, an einem
                sicheren Ort. Sie benötigen diesen Code, wieder Zugang zu Ihrem
                Nutzerkonto zu erlangen, falls das von Ihnen zur
                2-Faktor-Authentifizierung genutzte Gerät nicht zur Verfügung steht, und
                um die 2-Faktor-Authentifizierung zu deaktivieren, wenn Sie diese
                Möglichkeit nicht mehr nutzen wollen.
              </p>

              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">4.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">Vertragsdaten</p>
                </div>
              </div>
              <p class="py-2">
                Im Zusammenhang mit und zum Zweck der Erfüllung vorvertraglicher Maßnahmen
                und vertraglicher Pflichten für unser Internetangebot, die auf Ihre
                Anforderung hin erfolgen, verarbeiten wir zur Vertragserfüllung Ihre
                Daten, die wir bei der Anlage Ihres Nutzerkontos erfasst haben und die von
                Ihnen eingegeben wurden.
              </p>
              <div class="w-full flex flex-col pl-2 md:pl-4">
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">a.</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Wenn Sie unsere Schulungen für sich selbst buchen und nutzen, ist
                      die Rechtsgrundlage für die Datenverarbeitung die Erfüllung unseres
                      Vertrages mit Ihnen gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO. Die Daten
                      werden an Dritte nur insoweit weitergegeben, als dies zur Erfüllung
                      vorvertraglicher Maßnahmen und vertraglicher Pflichten nach Art. 6
                      Abs. 1 lit. b DSGVO erforderlich ist, z.B. an Banken,
                      Zahlungsdienstleister, Kreditkartenunternehmen zur Abwicklung der
                      Zahlung und an Versanddienstleister zur Versendung von Unterlagen.
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">b.</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      Falls unser Angebot für Sie über eine Firma oder Organisation, für
                      die Sie tätig sind, gebucht wird, ist die Rechtsgrundlage für die
                      Datenverarbeitung das berechtigte Interesse Ihrer Firma oder
                      Organisation an der Schulung der Mitarbeiter und unser berechtigtes
                      Interesse an der Erbringung unserer Leistungen gemäß Art. 6 Abs. 1
                      S. 1 lit. f DSGVO. Die Daten werden an Dritte nur insoweit
                      weitergegeben, als dies zur Erfüllung unserer Pflichten erforderlich
                      ist, z.B. an Versanddienstleister zur Versendung von Unterlagen.
                    </p>
                  </div>
                </div>
              </div>
              <p class="py-2">
                Neben den Daten, die bei der Registrierung und Anlage des Nutzerkontos
                erfasst wurden, verarbeiten und speichern wir noch folgende weitere Daten
                von Ihnen, wenn Sie ihr Nutzerkonto und unsere Schulungen bei uns nutzen:
              </p>
              <div class="w-full flex flex-col">
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Von Ihnen genutzte Schulungen</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Zeitpunkt der Schulung</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">Ergebnis der Identitätsfeststellung</p>
                  </div>
                </div>
                <div class="w-full flex justify-start text-left text-base">
                  <div class="w-auto flex items-start pr-2">
                    <p class="text-black-600 tracking-wider">-</p>
                  </div>
                  <div class="w-auto flex items-start pl-2">
                    <p class="text-black-600">
                      nach Teilnahme an der Schulung Ihr Zertifikat
                    </p>
                  </div>
                </div>
              </div>
              <p class="py-2">
                Wenn Sie in der BKF-App eine Schulung nutzen, dann erfassen wir, wann Sie
                mit der Nutzung dieser Schulung begonnen haben. Während Sie die Schulung
                nutzen, senden wir Ihnen Zahlencodes per SMS an die Mobiltelefonnummer,
                die in Ihrem Nutzerkonto angegeben ist. Diese Codes müssen Sie während der
                Schulung nach Aufforderung eingeben, damit wir verifizieren können, dass
                Sie an der Schulung teilgenommen haben. Dabei speichern wir während der
                Schulung, ob Sie die richtigen Codes schnell genug eingegeben haben. Auf
                dieser Grundlage können wir Ihnen nach festgestellter erfolgreicher
                Teilnahme Ihr Zertifikat ausstellen, mit dem Sie die Teilnahme an der
                Schulung nachweisen können.
              </p>
              <p class="py-2">
                Unsere Zertifikate werden elektronisch ausgestellt und stehen Ihnen in
                Ihrem Nutzerkonto als PDFDatei zum Download zur Verfügung. Um gegenüber
                Dritten nachweisen zu können, dass das Zertifikat echt ist, enthalten die
                Zertifikate einen QR-Code. Wenn man diesen QR-Code mit einer gängigen App
                scanned, werden über unser Internetangebot die Daten aus dem Nutzerkonto
                der Person angezeigt, die an der Schulung teilgenommen hat. Falls Sie
                damit nicht einverstanden sind, können Sie ihr Zertifikat jederzeit in
                Ihrem Nutzerkonto löschen. Möglicherweise können Sie dann aber nicht mehr
                nachweisen, dass Sie an der betreffenden Schulung teilgenommen haben.
              </p>
              <p class="py-2">
                Die Daten in Ihrem Nutzerkonto bleiben bei uns so lange gespeichert, wie
                Sie bei uns ein Nutzerkonto unterhalten.
              </p>
              <p class="py-2">
                Wir nutzen einen spezialisierten Dienstleister, um aus der BKF-App E-Mails
                und SMS zu verschicken. Dazu übermitteln wir Ihre Daten auf Grundlage
                unserer berechtigten Interessen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO an
                einer sicheren und effizienten Verwaltung unseres Unternehmens und
                Abwicklung der Aufträge unserer Kunden an einen externen Anbieter, der der
                diese Leistungen für uns im Auftrag erbringt.
              </p>
              <p class="py-2">
                Wir nutzen CRM-Software, um die Vertragsdaten zu verarbeiten und zu
                verwalten. Dazu übermitteln wir Ihre Daten an einen externen Anbieter, der
                dieses System für uns im Auftrag betreibt.
              </p>
            </div>
            <div class="w-full flex justify-start text-left text-base pt-6">
              <div class="w-auto flex items-start pr-2">
                <p class="font-bold text-gray-800 tracking-wider">IV.</p>
              </div>
              <div class="w-auto flex items-start pl-2">
                <p class="font-bold text-gray-800">Kommunikation und Werbung</p>
              </div>
            </div>
            <div class="w-full flex flex-col pl-6 lg:pl-8 pt-2">
              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">1.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">
                    Kontaktformular / Sonstige Kontaktaufnahme
                  </p>
                </div>
              </div>
              <p class="py-2">
                Wenn Sie innerhalb der BKF-App das Kontaktformular verwenden, verarbeiten
                wir Ihre bei uns gespeicherte E-Mail-Adresse und/oder weitere Kontaktdaten
                und Ihre Anfrage, damit wir Sie persönlich kontaktieren und Ihre Anfrage
                bearbeiten können. Ihre Daten werden nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
                auf Grundlage der von Ihnen erteilten Einwilligung verarbeitet. Zweck der
                Verarbeitung ist die Beantwortung Ihrer Anfrage. Ohne diese Einwilligung
                können Sie keine Anfragen über das Kontaktformular an uns stellen.
              </p>
              <p class="py-2">
                Alle über unser Kontaktformular erhobenen personenbezogenen Daten werden
                nach Erledigung Ihrer Anfrage gelöscht, soweit die Daten nicht für andere
                Vorgänge erforderlich sind (z.B. Abwehr gegen uns geltend gemachter
                Ansprüche).
              </p>
              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">2.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">Postalische Werbung</p>
                </div>
              </div>
              <p class="py-2">
                Wenn Sie unsere Schulungen für sich selbst buchen und nutzen oder wenn Sie
                ein Firmenkonto für die Verwaltung von Mitarbeitern nutzen und wir Ihre
                Daten im Zusammenhang mit der Nutzung unseres Angebotes erhalten haben,
                können wir Ihre Daten (Vor- und Nachname, Adresse, ggf. Firma) zur
                postalischen Direktwerbung für von uns oder von Dritten angebotene Waren
                oder Dienstleistungen verwenden. Zu diesen Daten können wir für die
                genannten Werbezwecke noch weitere Daten hinzuspeichern, die wir
                rechtmäßig über Sie oder die Firma und/oder Organisation, für die Sie
                tätig sind, erhoben haben. Dabei kann es sich zum Beispiel um Ihre
                Nutzungshistorie oder die Art der Schulungen handeln, die Sie bei uns
                genutzt oder für Mitarbeiter gebucht haben.
              </p>
              <p class="py-2">
                Der Zweck dieser Datenverarbeitung ist, Sie möglichst zielgenau mit
                Werbung anzusprechen, die Ihren Interessen entspricht und Werbung, die Sie
                nicht anspricht, zu vermeiden. Die Rechtsgrundlage der Verarbeitung ist
                unser berechtigtes Interesse am Direktmarketing nach Art. 6 Abs. 1 lit. f
                DSGVO.
              </p>
              <p class="py-2">
                Postalische Werbung benötigt eine gewisse Vorlaufzeit vor dem Versand, um
                die Sendungen zu drucken und versandfertig zu machen. Falls Sie
                postalischer Werbung widersprechen, kann es ausnahmsweise passieren, dass
                Sie noch Werbung per Post von uns erhalten. Dazu kommt es, wenn der
                Produktionsprozess für die entsprechende Sendung bereits angelaufen war,
                als Sie widersprochen haben. Das heißt nicht, dass wir Ihren Widerspruch
                nicht berücksichtigen.
              </p>
              <div class="w-full flex justify-start text-left text-base pt-6">
                <div class="w-auto flex items-start pr-2">
                  <p class="font-bold text-gray-800 tracking-wider">3.</p>
                </div>
                <div class="w-auto flex items-start pl-2">
                  <p class="font-bold text-gray-800">E-Mail-Direktwerbung an Kunden</p>
                </div>
              </div>
              <p class="py-2">
                Wenn Sie unsere Schulungen für sich selbst buchen und nutzen und wir Ihre
                E-Mail-Adresse im Zusammenhang mit der Nutzung unseres Angebotes erhalten
                haben, können wir Ihre E-Mail-Adresse zur Direktwerbung für eigene
                ähnliche Waren oder Dienstleistungen verwenden. Dies gilt nur, sofern Sie
                nicht widersprochen haben und wir Sie bei Erhebung der E-Mail-Adresse und
                bei jeder Verwendung klar und deutlich auf die Widerspruchsmöglichkeit
                hinweisen. Für die Direktwerbung per E-Mail verarbeiten wir Ihre
                E-Mail-Adresse, Ihren Namen, falls Sie für ein Unternehmen tätig sind Ihre
                Firmenzugehörigkeit und die Art der Dienstleistungen, die Sie von uns
                bezogen haben. Rechtsgrundlage der Verarbeitung ist unser berechtigtes
                Interesse am Direktmarketing nach Art. 6 Abs. 1 lit. f DSGVO.
              </p>
            </div>
            <div class="w-full flex justify-start text-left text-base pt-6">
              <div class="w-auto flex items-start pr-2">
                <p class="font-bold text-gray-800 tracking-wider">V.</p>
              </div>
              <div class="w-auto flex items-start pl-2">
                <p class="font-bold text-gray-800">Social Media</p>
              </div>
            </div>
            <div class="w-full flex flex-col pt-2">
              <p class="py-2">
                Wir haben im Rahmen unseres Internetangebotes Links zu unseren Auftritten
                in sozialen Netzwerken gesetzt. Wenn Sie unser Internetangebot nutzen,
                werden keine Daten an die jeweiligen Anbieter übertragen. Daten werden nur
                dann übertragen, wenn Sie einen der Links nutzen, um unsere Auftritte zu
                besuchen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/components/layouts/Default";
export default {
  components: {
    DefaultLayout,
  },
};
</script>
