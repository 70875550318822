<template>
  <div
    class="mt-10 md:mt-16 w-ful grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4 lg:gap-10 justify-between relative"
  >
    <div class="w-full flex flex-col py-2">
      <h2 class="text-secondary-600 text-4xl font-semibold">{{ total }}€</h2>
      <p class="py-4 text-sm lg:text-base">{{ t("currentCoupon") }}</p>
    </div>
    <div
      @mouseleave="toggleCodeNotePopup(false)"
      class="w-full flex flex-col py-2 relative"
    >
      <h2 class="text-secondary-600 text-4xl font-semibold">{{ code }}</h2>
      <div class="py-4 flex">
        <span class="text-sm lg:text-base">{{ t("yourCode") }}</span>
        <div
          @mouseover="toggleCodeNotePopup(true)"
          class="w-auto flex items-center mx-2 cursor-pointer"
        >
          <Icon name="info-rounded" class="w-2 h-6 text-gray-700" />
        </div>
      </div>
      <transition name="slide-fade">
        <div
          v-show="codeNotePopup"
          class="absolute top-28 md:top-24 w-64 rounded bg-white z-10 right-2 p-4 border flex shadow"
        >
          <p class="text-sm lg:text-base">
            {{ t("yourCodeNote") }}
            <router-link to="/contact" class="text-blue-500 underline px-px">{{
              t("contact")
            }}</router-link>
          </p>
        </div>
      </transition>
    </div>
    <div class="w-full flex flex-col py-4">
      <ShareSection />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ShareSection from "@/components/reports/ShareSection";
import Icon from "@/components/atoms/Icon";
import translateMixin from "@/mixins/translate.js";
export default {
  components: {
    ShareSection,
    Icon,
  },
  mixins: [translateMixin],
  props: {
    code: { type: [String, Number], default: "" },
    total: { type: [String, Number], default: "0" },
  },
  setup() {
    // show popup by hover at info icon
    const codeNotePopup = ref(false);
    function toggleCodeNotePopup(val) {
      codeNotePopup.value = val;
    }

    return {
      codeNotePopup,
      toggleCodeNotePopup,
    };
  },
};
</script>
