<template>
  <DefaultLayout>
    <div class="w-full mx-auto max-w-2xl py-8">
      <div v-if="!showRecover">
        <h1 class="main-heading text-left md:text-center pt-16">
          {{ t("deleteAccount") }}
        </h1>
        <p class="w-full mx-auto sub-heading font-semibold text-left md:text-center py-4">
          {{ t("wannaLeaveUs") }}
        </p>
        <p class="w-full mx-auto sub-heading text-left md:text-center">
          {{ t("textUs") }}
        </p>
        <p class="w-full mx-auto sub-heading text-left md:text-center">
          {{ t("areYouSureWannaDeleteAccount") }}
        </p>
        <div class="w-full sm:w-1/2 mx-auto mt-8">
          <Button @click="openConfirmModal" primary :text="t('confirm')" class="w-full" />
        </div>
      </div>

      <div v-if="showRecover">
        <h1 class="main-heading text-left md:text-center pt-16">
          {{ t("recoverDelete.title") }}
        </h1>
        <h3 class="text-2xl w-full mx-auto sub-heading font-semibold text-left md:text-center py-4 mt-8 ">
          {{ t("recoverDelete.subTitle") }}
        </h3>
        <p class="mt-8 font-semibold w-full mx-auto sub-heading text-left md:text-center">
          {{ t("recoverDelete.text") }}
        </p>
        <div class="w-full sm:w-1/2 mx-auto mt-8 mb-8">
          <Button primary @click="recoverAccount" :text="t('recoverDelete.btnRecoverAccount')" class="w-full"/>
        </div>
      </div>
      <Teleport to="#global-modal" v-if="showConfirmModal && !loading">
        <div class="w-full global-modal-wrapper">
          <div
            class="w-11/12 my-2 py-4 md:w-1/2 2xl:w-1/3 max-h-screen text-center flex flex-col relative overflow-auto"
          >
            <div
            class="w-full h-full rounded bg-white shadow-lg flex p-4 md:p-6 relative"
            >
            <div class="w-full">
              <div
                @click="closeConfirmModal"
                class="w-4 h-4 absolute top-4 right-4 cursor-pointer"
              >
                <Icon name="close" class="w-4 h-4 flex" />
              </div>
              <form
                @submit.prevent="removeAccount"
                class="w-full lg:w-2/3 mx-auto grid gap-4 grid-cols-1 pt-8 pb-4 text-left"
              >
                <p class="py-4 text-lg font-semibold text-center">
                  {{ t("confirmDelete") }}
                </p>
                <p class="py-2 text-center">{{ t("toDeleteSetPasswword") }}</p>
                <TextInput
                  type="password"
                  :placeholder="t('password')"
                  :label="t('password')"
                  required
                  v-model="password"
                  name="password"
                  :errorMessage="errors['password']"
                />
                <div class="w-full sm:w-2/3 mx-auto mt-8">
                  <Button
                    type="submit"
                    primary
                    :text="t('confirmDelete')"
                    class="w-full"
                  />
                  <Button
                    type="button"
                    @click="closeConfirmModal"
                    :text="t('cancel')"
                    class="w-full text-center underline mt-2"
                  />
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </Teleport>
      <div v-if="loading" class="w-full z-50">
        <Loader  />
      </div>
      <Teleport to="#global-modal" v-if="showSuccessMessage && !loading">
        <div class="w-full global-modal-wrapper">
          <div
            class="w-11/12 my-2 py-4 md:w-1/2 2xl:w-1/3 max-h-screen text-center flex flex-col relative overflow-auto"
          >
            <div
              class="w-full h-full rounded bg-white shadow-lg flex p-4 md:p-6 relative"
            >
            <div class="w-full lg:w-2/3 mx-auto grid gap-4 grid-cols-1 pt-8 pb-4 text-left">
              <h2 class="py-4 text-xl font-bold text-center">{{ t('recoverDelete.yourAccountRecoverd')}}</h2>
              <p class="py-2 text-center">{{ t('recoverDelete.yourAccountRecoverdText')}}</p>
              <Button primary to="/login" :text="t('recoverDelete.toLogin')" class="w-1/2 mx-auto py-2"/>
            </div>
            </div>
          </div>
        </div>
      </Teleport>
    </div>
  </DefaultLayout>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import { ref } from "vue";
import DefaultLayout from "@/components/layouts/Default";
import useValidation from "@/mixins/useValidation.js";
import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/TextInput";
import Loader from "@/components/atoms/Loader";
import Icon from "@/components/atoms/Icon";
import {useStore} from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    DefaultLayout,
    Button,
    TextInput,
    Icon,
    Loader
  },
  setup() {
    const store = useStore();

    // refs
    const showConfirmModal = ref(false);
    const showSuccessMessage = ref(false)
    const recoverUrl = ref(null);
    const showRecover = ref(false);
    const loading = ref(false)
    function closeConfirmModal() {
      showConfirmModal.value = false;
    }
    function openConfirmModal() {
      showConfirmModal.value = true;
    }
    // inputs & errors initial
    const password = ref("");
    const { errors, hasErrors } = useValidation();

    function validateAll() {
      errors.value = {};
    }

    async function removeAccount() {
      this.errors = {};
      showRecover.value = false;
      validateAll();
      if (!hasErrors.value) {
        loading.value = true
        store.dispatch("profile/removeAccount", {
          password: password.value,
        }).then(response => {
            showRecover.value = true
            recoverUrl.value =response.recovery_url
            loading.value = false
            closeConfirmModal();
          }).catch(errors =>{
          loading.value = false
          this.errors = errors;
        })
      }
    }
    function recoverAccount() {
      errors.value = {}
      loading.value = true
      store.dispatch("profile/recoverDeletedAccount",
         {'recoverUrl': recoverUrl.value}
      ).then((response) => {
        if(response.status == 200) {
          showSuccessMessage.value = true
        }
        loading.value = false
      }).catch(errors => {
        loading.value = false
        errors.value = errors
      });
    }
    return {
      password,
      errors,
      hasErrors,
      validateAll,
      showConfirmModal,
      closeConfirmModal,
      openConfirmModal,
      removeAccount,
      recoverUrl,
      showRecover,
      recoverAccount,
      showSuccessMessage,
      loading
    };
  },
};
</script>
