<template>
  <div class="select-none">
    <label :for="name" class="toggle-button">
      <input
        :aria-label="name"
        :name="name"
        class="checkbox"
        :id="name"
        type="checkbox"
        :checked="isChecked"
        :value="value"
        @change="updateInput"
      />
      <span class="slider"></span>
    </label>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    value: { type: [String, Array, Boolean], default: false },
    modelValue: [String, Array, Boolean],
    name: { type: String, required: false, default: "toggle-button" },
  },
  setup(props, { emit }) {
    const isChecked = computed(() => {
      return props.modelValue === true;
    });
    function updateInput(event) {
      emit("update:modelValue", event.target.checked);
    }

    return {
      isChecked,
      updateInput,
    };
  },
};
</script>

<style>
.toggle-button {
  @apply relative flex w-14 h-7 select-none;
}
.toggle-button input {
  @apply opacity-0 w-0 absolute h-0;
}
.toggle-button .slider:before {
  @apply absolute cursor-pointer h-6 w-6 top-0.5 left-0.5 bottom-px shadow bg-white rounded-full transition duration-300 ease-in-out;
  content: "";
}
.toggle-button input:checked + .slider:before {
  @apply bg-primary-300 transform translate-x-6 border border-primary-300 right-0 ml-px left-1 transition duration-300 ease-in-out;
}
.toggle-button .slider {
  @apply absolute cursor-pointer inset-0 bg-gray-200 rounded-full;
}
</style>
