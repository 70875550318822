<template>
  <div class="w-full flex justify-start text-left mb-4">
    <div class="w-auto flex items-start pr-2">
      <p v-if="!list" class="tracking-wider">{{ number }}</p>
      <div v-else class="w-2 h-2 bg-gray-800 rounded-full mt-1.5">
      </div>
    </div>
    <div class="w-auto flex items-start pl-2">
      <p class=""><slot> </slot></p>
    </div>
  </div>
</template>

<script>
export default {
  props: { 
    number: { type: [Number, String], default: 1},
    list: { type: Boolean, default: false}
  }
};
</script>

<style></style>
