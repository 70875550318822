<template>
<div>
    <label :for="name" class="checkbox-input">
        <span class="pl-8">{{ label }}</span>
        <span class="flex items-center"><slot></slot></span>
        <input
            :aria-label="name"
            :name="name"
            class="checkbox"
            :id="name"
            type="checkbox"
            :checked="isChecked"
            :value="value"
            @change="updateInput"
        >
        <span class="checkmark">
            <div v-show="isChecked" class="w-5 h-5 p-1 text-white items-center flex justify-center">
                <Icon name="check" class="w-full stroke-current"/>
            </div>
        </span>
    </label>
    <p v-if="errorMessage" class="text-xs py-0.5 text-red-500">
        {{ errorMessage }}
    </p>
</div>
</template>

<script>
import Icon from "@/components/atoms/Icon";
import { computed } from 'vue'
export default {
    components: {
        Icon
    },
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        value: { type: [String, Array, Boolean] },
        modelValue: [String, Array, Boolean],
        name: { type: String, required: true},
        label: { type: String, default: ''},
        errorMessage: { type: String, default: '' },
    },
    setup(props, { emit }) {
        const isChecked = computed(() => {
            if (props.modelValue instanceof Array) {
            return props.modelValue.includes(props.value)
            }
            return props.modelValue === true
        })
        function updateInput(event) {
            let isChecked = event.target.checked
            if (props.modelValue instanceof Array) {
                let newValue = [...props.modelValue]
                if (isChecked) {
                newValue.push(props.value)
                } else {
                newValue.splice(newValue.indexOf(props.value), 1)
                }
                emit('update:modelValue', newValue)
            } else {
                emit('update:modelValue', isChecked)
            }
        }

        return {
            isChecked,
            updateInput
        }
    }
}
</script>
