<template>
    <EditBox ref="editBox" :title="t('bankAccount')" editbar>
        <template v-slot:content>
            <div class="w-full flex flex-col py-4">
                <div class="w-full">
                    <div class="w-full text-sm py-2 flex">
                        <p class="text-gray-600 w-32">{{ t("bankName") }}</p>
                        <p class="px-4">{{ paymentData.bank_name }}</p>
                    </div>
                    <div class="w-full text-sm py-2 flex">
                        <p class="text-gray-600 w-32">{{ t("iban") }}</p>
                        <p class="px-4">{{ paymentData.iban }}</p>
                    </div>
                    <div class="w-full text-sm py-2 flex">
                        <p class="text-gray-600 w-32">{{ t("bic") }}</p>
                        <p class="px-4">{{ paymentData.bic }}</p>
                    </div>
                </div>
            </div>
        </template>

        <!-- Edit Modal -->
        <template v-slot:modal>
            <div class="w-full">
                <form
                        @submit.prevent="SaveBankData"
                        class="w-full grid gap-4 grid-cols-1 py-8 text-left"
                >
                    <TextInput
                            :placeholder="t('bankName')"
                            :label="t('bankName')"
                            v-model="bankData.bank_name"
                            required
                            name="bank_name"
                            :errorMessage="errors['bank_name']"
                    />
                    <TextInput
                            :placeholder="bankData.ibanPlaceholder ? `IBAN ${bankData.ibanPlaceholder}` : t('iban')"
                            :label="t('iban')"
                            v-model="bankData.iban"
                            required
                            name="iban"
                            :errorMessage="errors['iban']"
                    />
                    <TextInput
                            :placeholder="t('bic')"
                            :label="t('bic')"
                            required
                            v-model="bankData.bic"
                            name="bic"
                            :errorMessage="errors['bic']"
                    />

                    <div class="w-full sm:w-1/2 mx-auto mt-8">
                        <Button type="submit" primary :text="t('confirm')" class="w-full"/>
                        <Button
                                type="button"
                                @click="closeEditModal"
                                :text="t('cancel')"
                                class="w-full text-center underline mt-2"
                        />
                    </div>
                </form>
            </div>
        </template>
    </EditBox>
</template>

<script>
    import EditBox from "@/components/atoms/EditBox";
    import translateMixin from "@/mixins/translate.js";
    import useValidation from "@/mixins/useValidation.js";
    import {computed, ref} from "vue";
    import Button from "@/components/atoms/Button";
    import TextInput from "@/components/forms/TextInput";
    import {useStore} from "vuex";

    export default {
        mixins: [translateMixin],
        components: {
            EditBox,
            TextInput,
            Button,
        },
        setup() {
            const store = useStore();
            const trans = translateMixin.methods.t;

            // refs
            const editBox = ref(null);

            function closeEditModal() {
                editBox.value.closeEditModal();
            }

            const bankData = ref({bank_name: "",iban: "", bic: "", ibanPlaceholder: ""});

            const paymentData = computed(() => {
                let data = {
                    bank_name: "",
                    iban: "",
                    bic: "",
                    ibanPlaceholder: "",
                };
                const payOutData = store.state.payOut.payOutData;
                if (payOutData) {
                    data = {
                        iban: payOutData.iban,
                        bic: payOutData.bic,
                        bank_name: payOutData.bank_name,
                        ibanPlaceholder: payOutData.iban
                    };
                    setBankData({
                      bank_name: payOutData.bank_name,
                      bic: payOutData.bic,
                      ibanPlaceholder: payOutData.iban
                      });
                      
                }
                return data;
            });

            function setBankData(data) {
                bankData.value = data
            }

            // inputs & errors initial
            const {errors, hasErrors} = useValidation();

            function validateAll() {
                errors.value = {};
            }

            async function SaveBankData() {
                this.errors = {};
                validateAll();
                // if there is no error get saveData api
                if (!hasErrors.value) {
                    try {
                        const form = bankData.value;
                        await store.dispatch("payOut/updatePayouts", {
                            iban: form.iban,
                            bic: form.bic,
                            bank_name: form.bank_name,
                            payout_service_uuid: process.env.VUE_APP_PAYOUT_SERVICE_UUID,
                        });
                        closeEditModal();
                    } catch (errors) {
                        this.errors = errors
                    }
                }
            }


            return {
                bankData,
                SaveBankData,
                errors,
                hasErrors,
                validateAll,
                editBox,
                closeEditModal,
                trans,
                paymentData,
            };
        },
    };
</script>
