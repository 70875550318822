import axios from 'axios'
const baseURL = process.env.VUE_APP_BASE_URL

const marketingChannels = {
    state: {
        marketingChannels: {},
    },
    getters: {
        marketingChannels: state => {
            return state.marketingChannels
        },
    },
    mutations: {
        setMarketingChannels(state, channels) {
            state.marketingChannels = channels
        },
    },
    actions: {
        setMarketingChannel({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/spaApi/marketing-channels`, {
                    channels: payload.channels,
                }).then((response) => {
                    dispatch("getMarketingChannels");
                    resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = {
                        'online': null,
                        'offline': null,
                    };
                    Object.keys(errors).map(function(key) {
                        const keys = key.split('.');
                        if(keys[1] === 'online'){
                            errorMessages['online'] = 'The channels profile url format is invalid.';
                        }
                        return false;
                    });

                    if ('channels.offline.description' in errors) {
                        errorMessages['offline'] = errors['channels.offline.description'][0];
                    }
                    reject(errorMessages)
                })

            })
        },

        async getMarketingChannels({commit}) {
            const response = await axios.get(`${baseURL}/spaApi/marketing-channels`);
            const channels = response.data.data
            commit('setMarketingChannels', channels)
        },
    },
    namespaced: true,

}

export default marketingChannels
