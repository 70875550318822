import axios from 'axios'
import {logOut} from "../auth/auth";

const baseURL = process.env.VUE_APP_BASE_URL

const profile = {
    state: {
        sessions: {},
    },
    getters: {
        userSessions: state => {
            return state.sessions
        },
    },
    mutations: {
        setSessions(state, sessions) {
            state.sessions = sessions;
        }
    },
    actions: {
        setProfileData({commit}, formData) {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/spaApi/user`,
                    formData
                ).then((response) => {
                    commit("setUser", response.data.data, { root: true });
                    resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if ('first_name' in errors) {
                        errorMessages['firstName'] = errors['first_name'][0]
                    }
                    if ('last_name' in errors) {
                        errorMessages['lastName'] = errors['last_name'][0]
                    }
                    if ('email' in errors) {
                        errorMessages['email'] = errors['email'][0]
                    }
                    if ('password' in errors) {
                        errorMessages['password'] = errors['password'][0]
                    }
                    reject(errorMessages)
                })
            })
        },
        removeAccount({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(`${baseURL}/spaApi/user`, {
                    data: {
                        password: payload.password,
                    }
                }).then((response) => {
                    logOut()
                    commit("setLoggedIn", false, { root: true });
                    resolve(response.data);
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if ('password' in errors) {
                        errorMessages['password'] = errors['password'][0]
                    }
                    reject(errorMessages)
                })
            })
        },
        getSessions({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/spaApi/sessions`).then((response) => {
                    commit('setSessions', response.data.data);
                }).catch(error => {
                    const {errors} = error.response.data;
                    reject(errors)
                })
            })
        },
        logOffSessions({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(`${baseURL}/spaApi/log-off-sessions`, {
                    data: {
                        password: payload.password,
                    }
                }).then((response) => {
                    commit('setSessions', response.data.data);
                    resolve(response)
                }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if ('password' in errors) {
                        errorMessages['password'] = errors['password'][0]
                    }
                    reject(errorMessages)
                })
            })
        },
        recoverDeletedAccount(state, payload) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}${payload.recoverUrl}`)
                    .then((response) => {
                        resolve(response);
                }).catch(error => {
                    const {errors} = error.response.data;
                    reject(errors)
                })
            })
        },
    },
    namespaced: true,
}

export default profile
