<template>
  <Teleport to="#global-loader">
    <div class="bkf-affiliate-loader"></div>
  </Teleport>
</template>

<script>
import {onMounted, onUnmounted} from "vue";
export default {
  setup() {
    onMounted(() => {
      document.body.style.overflow = "hidden";
    })
    onUnmounted(() => {
      document.body.style.overflow = "auto";
    })
  }
};
</script>

<style scoped>
.bkf-affiliate-loader {
  @apply w-20 h-20 rounded-full inset-0 fixed m-auto shadow-sm;
  border: 18px solid #f3f3f3;
  border-top: 18px solid #3498db;
  -webkit-animation: spin 1.7s linear infinite; /* Safari */
  animation: spin 1.7s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
