import axios from 'axios';
import router from "@/router";
import {logOut} from "./store/auth/auth";
import store from './store'

export default function setup() {
    axios.defaults.withCredentials = true;
    axios.interceptors.request.use((requestConfig) => {
        return requestConfig;
    });
    axios.interceptors.response.use(null, function (error) {
        if (error && error.response && error.response.status) {
            const status = error.response.status;
            if (status === 401) {
                logOut();
                store.commit('setLoggedIn', false);
                router.push({path: '/login'});
                throw Error
            }
            if (status === 403) {
                router.push({path: '/resend-email'});
                throw Error
            }
        }


        return Promise.reject(error);

    });
}
