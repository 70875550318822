<template>
  <div
    class="w-full flex flex-col md:flex-row justify-between text-white bg-primary-500 px-8 lg:px-16 pt-2 md:pt-16 pb-16 md:pb-10"
  >
    <div class="w-full md:w-1/2 flex flex-col md:flex-row mt-12 flex-shrink pt-px">
      <router-link class="underline md:pr-2 lg:pr-4 py-3" to="/imprint">{{
        t("impressum")
      }}</router-link>
      <router-link class="lg:px-4 md:px-2 py-3 underline" to="/policy">{{
        t("dataProtection")
      }}</router-link>
      <router-link class="lg:px-4 md:px-2 py-3 underline" to="/terms">{{
        t("termOfUse")
      }}</router-link>
      <router-link class="lg:px-4 md:px-2 py-3 underline" to="/contact">{{
        t("contact")
      }}</router-link>
    </div>
    <div class="w-full md:w-1/2 flex md:flex-col-reverse flex-col flex-shrink">
      <p class="py-3 md:text-right">© {{ year }} BKF Online-Schulungs GmbH</p>
      <div class="flex items-center pt-4 md:pt-2 md:justify-end">
        <a
          href="https://www.facebook.com/bkfonline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p class="hidden absolute">facebook</p>
          <Icon name="facebook" size="w-8 h-8" class="text-white mr-2" />
        </a>
        <a
          href="https://www.instagram.com/bkf_online/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p class="hidden absolute">instagram</p>
          <Icon name="instagram" size="w-8 h-8" class="text-white" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import translateMixin from "@/mixins/translate.js";
import Icon from "@/components/atoms/Icon";
export default {
  components: {
    Icon,
  },
  mixins: [translateMixin],
  setup() {
    const year = computed(() => {
      return new Date().getFullYear();
    });
    return { year };
  },
};
</script>
