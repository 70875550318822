<template>
  <EditBox ref="editBox" :title="t('browserSessions')">
    <template v-slot:content>
      <div class="w-full flex flex-col py-4">
        <div class="w-full flex flex-col">
          <p class="text-sm pb-1">{{ t("browserSessionsSubTitle") }}</p>
          <p class="text-sm pb-6">{{ t("browserSessionsText") }}</p>
          <div class="w-full flex flex-col font-semibold py-2 mb-3" >
            <div v-for="session in sessions" :key="session.id">
              <p class="mb-3">
                <span v-if="session.agent" class="mr-1">{{session.agent.platform}} - {{session.agent.browser}},</span>
                <span class="mr-1"> {{session.ip_address}},</span>
                <span class="mr-1" v-if="session.is_current_device">{{ t("thisDevice") }}</span>
                <span class="mr-1" v-else>{{session.last_active}}</span>
            </p>
            </div>
          </div>
        </div>
        <div class="w-auto flex">
          <Button primary @click="openEditModal" :text="t('logOffOtherSessions')" />
        </div>
      </div>
    </template>

    <!-- Edit Modal -->
    <template v-slot:modal>
      <div class="w-full text-center">
        <form
          @submit.prevent="logOffSessions"
          class="w-full lg:w-2/3 mx-auto grid gap-4 grid-cols-1 pt-8 pb-4 text-left"
        >
          <p class="py-4 text-lg font-semibold text-center">
            {{ t("logOffOtherSessions") }}
          </p>
          <p class="py-2 text-center">{{ t("confirmLogOffSessionsText") }}</p>
          <TextInput
            type="password"
            :placeholder="t('password')"
            :label="t('password')"
            required
            v-model="password"
            name="password"
            :errorMessage="errors['password']"
          />
          <div class="w-full sm:w-2/3 mx-auto mt-8">
            <Button type="submit" primary :text="t('confirm')" class="w-full" />
            <Button
              type="button"
              @click="closeEditModal"
              :text="t('cancel')"
              class="w-full text-center underline mt-2"
            />
          </div>
        </form>
      </div>
    </template>
  </EditBox>
</template>

<script>
import EditBox from "@/components/atoms/EditBox";
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import {computed, ref} from "vue";
import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/TextInput";
import {useStore} from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    EditBox,
    Button,
    TextInput,
  },
  setup() {
    const store = useStore();
    // refs
    const editBox = ref(null);
    const  sessions = computed(() => {
      return store.state.profile.sessions
    });
    function closeEditModal() {
      editBox.value.closeEditModal();
    }
    function openEditModal() {
      editBox.value.openEditModal();
    }
    // inputs & errors initial
    const password = ref("");
    const { errors, hasErrors } = useValidation();

    function validateAll() {
      errors.value = {};
    }

    async function logOffSessions() {
      this.errors= {}
      validateAll();
      if (!hasErrors.value) {
        try {
          await store.dispatch("profile/logOffSessions", {
            password: password.value,
          })
          closeEditModal();
        } catch (errors) {
          this.errors = errors
        }
      }
    }
    return {
      sessions,
      password,
      errors,
      hasErrors,
      validateAll,
      editBox,
      closeEditModal,
      openEditModal,
      logOffSessions,
    };
  },
};
</script>
