<template>
  <DefaultLayout>
    <div class="w-full flex flex-col max-w-lg mx-auto">
      <h1 class="main-heading text-left md:text-center pt-16 md:pt-32">
        {{ t("registerHeading") }}
      </h1>
      <p class="sub-heading text-lg sm:text-xl text-left md:text-center pt-1 md:pt-3">
        {{ t("registerSubheading") }}
      </p>
      <form @submit.prevent="register" class="w-full grid gap-4 grid-cols-1 pt-8 pb-20">
        <div class="w-full grid gap-4 grid-cols-1 md:grid-cols-2">
          <TextInput
            :placeholder="t('firstName')"
            :label="t('firstName')"
            v-model="registerForm.firstName"
            required
            name="firstName"
            :errorMessage="errors['firstName']"
          />
          <TextInput
            :placeholder="t('lastName')"
            :label="t('lastName')"
            required
            v-model="registerForm.lastName"
            name="lastName"
            :errorMessage="errors['lastName']"
          />
        </div>
        <TextInput
          type="email"
          :placeholder="t('emailAddress')"
          :label="t('emailAddress')"
          required
          v-model="registerForm.email"
          name="email"
          :errorMessage="errors['email']"
        />
        <TextInput
          type="password"
          :placeholder="t('password')"
          :label="t('password')"
          required
          v-model="registerForm.password"
          name="password"
          :errorMessage="errors['password']"
        />
        <TextInput
          type="password"
          :placeholder="t('confirmPassword')"
          :label="t('confirmPassword')"
          required
          v-model="registerForm.confirmPassword"
          name="confirmPassword"
          :errorMessage="errors['confirmPassword']"
        />
        <div class="w-full sm:w-1/2 mx-auto mt-2">
          <Button type="submit" primary :text="t('next')" class="w-full" />
        </div>
        <div class="flex flex-col text-center">
          <p class="text-lg mt-8">
            {{ t("loginQuestion") }}
            <router-link class="px-px underline text-secondary-400" to="/login">{{
              t("login")
            }}</router-link>
          </p>
        </div>
      </form>
    </div>
  </DefaultLayout>
</template>

<script>
// import router from "@/router";
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import { ref } from "vue";
import { useStore } from "vuex";
import DefaultLayout from "@/components/layouts/Default";
import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/TextInput";

export default {
  mixins: [translateMixin],
  components: {
    DefaultLayout,
    TextInput,
    Button,
  },
  setup() {
    const store = useStore();
    const trans = translateMixin.methods.t;

    const registerForm = ref(
      { firstName: "" },
      { lastName: "" },
      { email: "" },
      { password: "" },
      { confirmPassword: "" }
    );
    const {
      errors,
      hasErrors,
      validateEmail,
      validateRegisterPasswords,
      validateText,
    } = useValidation();

    function validateAll() {
      errors.value = {};
      validateEmail(registerForm.value.email);
      validateRegisterPasswords(
        registerForm.value.password,
        registerForm.value.confirmPassword
      );
      validateText("firstName", registerForm.value.firstName);
      validateText("lastName", registerForm.value.lastName);
    }

  async function register() {
    this.errors = {}
    validateAll();
      // if there is no error get register api
      if (!hasErrors.value) {
        const form = registerForm.value;
        try {
          await store.dispatch("register", {
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            password: form.password,
            confirmPassword: form.confirmPassword,
          })
          } catch (errors) {
            this.errors = errors
          }
        }
    }
    return {
      registerForm,
      register,
      validateEmail,
      errors,
      hasErrors,
      validateRegisterPasswords,
      validateAll,
      trans,
    };
  },
};
</script>
