<template>
  <div class="w-full flex flex-col py-4 ">
    <p class="text-lg text-left py-3">
      {{ t("brieflyIntroduce") }}
    </p>
    <textarea
      v-model="moreInfo.self_intro"
      :placeholder="`${t('introducePlacholder')} \n\nMin. 150 ${t(
        'character'
      )} \nMax. 700 ${t('character')}`"
      name="about"
      id="about"
      rows="6"
      class="w-full border focus:border-secondary-500 bg-gray-100 rounded outline-none p-4"
      :errorMessage="errors.self_intro"
    ></textarea>
    <p class="text-xs py-0.5 text-red-500" v-if="errors.self_intro">{{errors.self_intro}}</p>

    <p class="text-lg text-left py-3 mt-2">
      {{ t("recommendedWay") }}
    </p>
    <textarea
      v-model="moreInfo.marketing_plan"
      :placeholder="`Min. 150 ${t('character')} \nMax. 700 ${t('character')}`"
      name="recommendedWay"
      id="recommendedWay"
      rows="3"
      class="w-full border focus:border-secondary-500 bg-gray-100 rounded outline-none p-4"
    ></textarea>
    <p class="text-xs py-0.5 text-red-500" v-if="errors.marketing_plan">{{errors.marketing_plan}}</p>
    <p class="text-lg text-left py-3 mt-2">
      {{ t("businessField") }}
    </p>
    <textarea
      v-model="moreInfo.industry"
      :placeholder="`Max. 80 ${t('character')}`"
      name="businessField"
      id="businessField"
      rows="2"
      class="w-full border focus:border-secondary-500 bg-gray-100 rounded outline-none p-4"
      :errorMessage="errors.industry"
    ></textarea>
    <p class="text-xs py-0.5 text-red-500" v-if="errors.industry">{{errors.industry}}</p>

  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import {computed} from "vue";

export default {
  mixins: [translateMixin],
  components: {},
  props : {
    modelValue: {
      type: Object,
    },
    errors:{}
  },
  setup(props, {emit} ) {
    // *** Data
    const moreInfo = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    return {
      moreInfo,
    };
  },
};
</script>
