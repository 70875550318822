<template>
  <div class="w-full flex justify-start items-center flex-col sm:flex-row">
    <CheckboxInput
      v-model="disabled"
      :name="name"
      :label="label"
      :value="isChecked"
      class="w-full sm:w-56 pr-6 flex text-left my-1"
    />
    <input
      type="text"
      :id="name"
      :value="value || modelValue"
      :placeholder="t(placeholder)"
      :name="name"
      :disabled="!disabled || !isUrl"
      :required="disabled ? 'required' : false"
      autocomplete="off"
      @input="(e) => $emit('update:modelValue', e.target.value)"
      :class="[
        { 'bg-gray-200 cursor-not-allowed': !disabled || !isUrl},
        'w-full p-3 bg-gray-100 border focus:border-secondary-500 rounded',
      ]"
    />
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import { ref, computed, watch, onMounted } from "vue";
import CheckboxInput from "@/components/forms/CheckboxInput";

export default {
  mixins: [translateMixin],
  components: {
    CheckboxInput,
  },
  props: {
    placeholder: { type: String, default: "" },
    name: { type: String, default: "" },
    value: { type: [String,Boolean], default: "" },
    label: { type: String, default: "" },
    checked: { type: [String,Boolean], default: "" },
    modelValue: [String,Boolean],
  },
  setup(props, {emit}) {
    // *** Data
    const disabled = ref(false);
    const isChecked = computed(() => {
      return !!props.checked;
    });
    const isUrl = computed(() => {
      switch (props.name.toLowerCase()) {
        case 'whatsapp':
          return false;
        default:
          return true;
      }
    });
    // check if the text-input is not empty to check the checkinput
    onMounted(() => {
      disabled.value = !!isChecked.value;
    });
    watch(
      () => isChecked.value,
      () => {
        disabled.value = !!isChecked.value;
      }
    );
    watch(
          () => disabled.value,
          () => {
              emit('changeCheckbox', {
                name:props.name,
                disabled: disabled.value,
              })
          }
    );


    return {
      disabled,
      isChecked,
      isUrl,
    };
  },
};
</script>
