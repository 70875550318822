<template>
  <EditBox ref="editBox" :title="t('BonusPayment')" editbar>
    <template v-slot:content>
      <div class="w-full flex flex-col py-4">
        <div class="w-full">
          <div class="w-full text-sm py-2 flex">
            <p>{{ t(payoutInterval) }}</p>
          </div>
        </div>
      </div>
    </template>

    <!-- Edit Modal -->
    <template v-slot:modal>
      <div class="w-full">
        <form
          @submit.prevent="savePayTime"
          class="w-full grid gap-4 grid-cols-1 pt-8 pb-20 text-left"
        >
          <div class="grid gap-4 py-4">
            <RadioInput
              name="payTime"
              :label="t('payMonthly')"
              v-model="payTime"
              value="monthly"
            />
            <RadioInput
              name="payTime"
              :label="t('payQuarterly')"
              v-model="payTime"
              value="quarterly"
            />
            <RadioInput
              name="payTime"
              :label="t('payEverySixMonth')"
              v-model="payTime"
              value="halfYearly"
            />
            <RadioInput
              name="payTime"
              :label="t('payYearly')"
              v-model="payTime"
              value="yearly"
            />
          </div>

          <div class="w-full sm:w-1/2 mx-auto mt-8">
            <Button type="submit" primary :text="t('confirm')" class="w-full" />
            <Button
              type="button"
              @click="closeEditModal"
              :text="t('cancel')"
              class="w-full text-center underline mt-2"
            />
          </div>
        </form>
      </div>
    </template>
  </EditBox>
</template>

<script>
import EditBox from "@/components/atoms/EditBox";
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import {computed, ref} from "vue";
import Button from "@/components/atoms/Button";
import RadioInput from "@/components/forms/RadioInput";
import {useStore} from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    EditBox,
    RadioInput,
    Button,
  },
  setup() {
    const store = useStore();
    const trans = translateMixin.methods.t;

    // refs
    const editBox = ref(null);
    let payTime = ref("");

    function closeEditModal() {
      editBox.value.closeEditModal();
    }
    const  payoutInterval = computed(() => {
      let data = ""
      const payOutData = store.state.payOut.payOutData
      if(payOutData && payOutData.payout_interval){
        data = payOutData.payout_interval
        setPayTime(data)
      }
      return data;
    });

    function setPayTime(data) {
      payTime.value = data
    }
    // inputs & errors initial
    const { errors, hasErrors } = useValidation();

    function validateAll() {
      errors.value = {};
    }

    async function savePayTime() {
      this.errors = {}
      validateAll();
      // if there is no error get saveData api
      if (!hasErrors.value) {
        try {
          await store.dispatch("payOut/updatePayouts", {
             payout_interval: payTime.value,
             payout_service_uuid: process.env.VUE_APP_PAYOUT_SERVICE_UUID,
          })
          closeEditModal();
        } catch (errors) {
          this.errors = errors
        }
      }
    }

    return {
      payoutInterval,
      payTime,
      savePayTime,
      errors,
      hasErrors,
      validateAll,
      editBox,
      closeEditModal,
      trans,
    };
  },
};
</script>
