import axios from 'axios'
import Router from '../../router/index'
import {logIn, logOut} from "../auth/auth";

const baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const login = {
    state: {
        isLoggedIn: false
    },
    mutations: {
        setLoggedIn(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        }
    },
    actions: {
        login({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
                    axios.post(`${baseURL}/login`, {
                        email: payload.email,
                        password: payload.password
                    }).then((response) => {
                        const isTwoFactor = response.data.two_factor;
                           if(!isTwoFactor){
                               dispatch('getAuthUser');
                           }else{
                               Router.push({path: '/two-factor-auth'});
                           }
                        resolve(isTwoFactor);
                    }).catch(error => {
                        const {errors} = error.response.data;
                        let errorMessages = [];
                        if ('email' in errors) {
                            errorMessages['email'] = errors['email'][0];
                        }
                        reject(errorMessages);
                    })
                })
            })
        },

        forgotPassword(commit, payload) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
                    axios.post(`${baseURL}/spaApi/forgot-password`, {
                        email: payload.email,
                    }).then((response) => {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            let errorMessages = [];

                            if ('email' in errors) {
                                errorMessages['email'] = errors['email'][0]
                            }
                            reject(errorMessages);
                        } else {
                            resolve();
                        }
                    }).catch(error => {
                        const {errors} = error.response.data;
                        let errorMessages = [];
                        if ('email' in errors) {
                            errorMessages['email'] = errors['email'][0]
                        }
                        reject(errorMessages)
                    })
                })
            })
        },
        resetPassword(commit, payload) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/sanctum/csrf-cookie`).then(() => {
                    axios.post(`${baseURL}/spaApi/reset-password`, {
                        email: payload.email,
                        password: payload.password,
                        password_confirmation: payload.passwordConfirmation,
                        token: payload.token
                    }).then((res) => {
                        resolve(res.data)
                        Router.push({path: '/login'});
                    }).catch(err => {
                        reject(err);
                    })
                })
            })
        },

        resendEmail() {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/spaApi/email/verification-notification`)
                    .then((res) => {
                        resolve(res.data)
                    }).catch(err => {
                    reject(err);
                })
            })
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/spaApi/logout`)
                    .then((res) => {
                        logOut();
                        commit('setLoggedIn', false);
                        resolve(res.data)
                    }).catch(err => {
                    if (err.response.status === 401) {
                        // logOut();
                        // commit('setLoggedIn', false);
                    }
                    reject(err);
                })
            })
        },

        getAuthUser({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`${baseURL}/spaApi/user`)
                    .then((response) => {
                        const user = response.data.data;
                        const config = {
                            isVerify: String(user.is_verify),
                            isCompleted: String(user.is_completed),
                            isLoggedIn: String(true)
                        };
                        logIn(config);
                        commit('setLoggedIn', true);
                        commit('setUser', user);
                        if (!user.is_verify) {
                            Router.push({path: '/resend-email'});
                        }
                        if (!user.is_completed) {
                            Router.push({path: '/complete-data'});
                        }
                        if (user.is_completed) {
                            Router.push({path: '/'});
                        }
                        resolve(response.data);
                    }).catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        logOut();
                        Router.push({path: '/login'});
                    }
                    reject(error);
                })
            })
        },
        twoFactorAuth({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/two-factor-challenge`,{
                    code: payload.code,
                    recovery_code: payload.recovery_code
                }).then((res) => {
                    dispatch('getAuthUser');
                    resolve(res.data);
                    }).catch(error => {
                    const {errors} = error.response.data;
                    let errorMessages = [];
                    if ('code' in errors) {
                        errorMessages['code'] = errors['code'][0];
                    }
                    reject(errorMessages);
                })
            })
        },
    },
    getters: {}
};


export default login
