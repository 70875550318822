<template>
  <div class="w-full flex flex-col">
    <div class="w-full flex flex-col relative">
      <transition name="slide-fade">
        <label v-if="label" v-show="showLabel" :class="classes.label" :for="name">
          {{ label }} <span v-show="required" class="-mt-px px-px">*</span>
        </label>
      </transition>
      <div class="w-full flex">
        <textarea
          ref="textArea"
          :value="value || modelValue"
          :placeholder="placeholder"
          :name="name"
          :rows="rows"
          :maxlength="maxlength"
          :class="classes.input"
          :disabled="disabled"
          :required="required"
          @input="(e) => $emit('update:modelValue', e.target.value)"
          @blur="handleBlur"
          @focus="handleFocus"
        />
      </div>
      <p v-if="errorMessage" class="text-xs py-0.5 text-red-500">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
export default {
  props: {
    value: { type: String, default: "" },
    rows: { type: Number, default: 4 },
    maxlength: { type: Number, default: 200 },
    name: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    errors: { type: [Array, Object], default: () => null },
    required: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
    modelValue: String,
  },
  setup(props, { emit }) {
    const focused = ref(false);
    const showLabel = ref(false);

    // refs textArea element instaed of document.getElement
    const textArea = ref(null);

    // CSS Classes
    const classes = computed(() => {
      return {
        label: {
          "text-xs pb-0.5 bg-white rounded flex absolute top-1.5 left-4 transition-all duration-150 ease-in-out": true,
          "text-secondary-400": focused.value,
          "text-gray-500": !focused.value,
        },
        input: {
          "w-full border text-sm px-4 rounded transition-all duration-150 ease-in-out pt-5 outline-none": true,
          "border-gray-300": !focused.value,
          "border-secondary-400": focused.value && !props.errorMessage,
          "border-red-500": props.errorMessage,
          "cursor-not-allowed bg-gray-100 text-gray-700": props.disabled,
        },
      };
    });

    // methods
    function handleBlur() {
      if (textArea.value.value !== "" && props.label) {
        showLabel.value = true;
      } else {
        showLabel.value = false;
      }
      focused.value = false;
      emit("blur");
    }
    function handleFocus() {
      focused.value = true;
      showLabel.value = true;
      emit("focus");
    }

    // at mount the input it will detect if the input empty or already filled to toggle label on/off
    onMounted(() => {
      if (textArea.value.value !== "") {
        showLabel.value = true;
      }
    });

    return {
      focused,
      showLabel,
      classes,
      textArea,
      handleBlur,
      handleFocus,
    };
  },
};
</script>
