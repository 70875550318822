import axios from 'axios'

const baseURL = process.env.VUE_APP_BASE_URL

const twoFactorAuth = {
    state: {
        isTwoFactor:false
    },
    getters: {
        isTwoFactor: state => {
            return state.isTwoFactor
        },
    },
    mutations: {
        setIsTwoFactor(state, isTwoFactor) {
            state.isTwoFactor = isTwoFactor
        },
    },
    actions: {
        enableTwoFactorAuthentication({commit}) {
            return new Promise((resolve, reject) => {
                axios.post(`${baseURL}/spaApi/two-factor-auth`)
                    .then((response) => {
                        commit('setIsTwoFactor', true)
                        resolve(response.data.data)
                    }).catch(error => {
                      const {errors} = error.response.data;
                      reject(errors)
                    });
            });
        },
        disableTwoFactorAuthentication ({commit}) {
            return new Promise((resolve, reject) => {
               axios.delete(`${baseURL}/spaApi/two-factor-auth`)
                .then((response) => {
                    commit('setIsTwoFactor', false);
                    resolve(response)
                }).catch(error => {
                    const {errors} = error.response.data;
                    reject(errors)
                });
            });
        },
        getTwoFactor({commit}){
            return axios.get(`${baseURL}/spaApi/get-two-factor`).then(response => {
                const twoFactor =  response.data.is_two_factor;
                commit('setIsTwoFactor', twoFactor)
            }).catch(() => {
                commit('setIsTwoFactor', false);
            })
        }

    },
    namespaced: true,
}


export default twoFactorAuth