<template>
  <div class="flex flex-col ">
    <p class="text-lg text-left md:text-center pt-1 md:pt-3">
      {{ t("personalDataText") }}
    </p>
    <div class="w-full grid gap-4 grid-cols-1 py-8">
      <TextInput
        :placeholder="t('company')"
        required
        :label="t('company')"
        v-model="personalData.company"
        name="company"
        :errorMessage="errors.company"
      />
      <div class="w-full grid gap-4 grid-cols-1 md:grid-cols-2">
        <TextInput
          :placeholder="t('streetAndBuildingNr')"
          required
          :label="t('streetAndBuildingNr')"
          v-model="personalData.street"
          name="street"
          :errorMessage="errors.street"
        />
        <TextInput
          :placeholder="t('city')"
          required
          :label="t('city')"
          v-model="personalData.city"
          name="city"
          :errorMessage="errors.city"
        />
      </div>
      <div class="w-full grid gap-4 grid-cols-1 md:grid-cols-2">
        <TextInput
          :placeholder="t('zipCode')"
          required
          :label="t('zipCode')"
          v-model="personalData.zip"
          name="zip"
          :errorMessage="errors.zip"
        />
        <TextInput
          :placeholder="t('addressLine2')"
          :label="t('addressLine2')"
          v-model="personalData.additional"
          name="addressLine2"
          :errorMessage="errors.additional"
        />
      </div>
      <TextInput
        :placeholder="t('umstNr')"
        :label="t('umstNr')"
        v-model="personalData.vat_no"
        name="vat_no"
        :errorMessage="errors.vat_no"
      />
    </div>
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import {computed} from 'vue';
import TextInput from "@/components/forms/TextInput";

export default {
  mixins: [translateMixin],
  components: {
    TextInput,
  },
  props : {
    modelValue: {
      type: Object,
    },
    errors:{}
  },
  setup(props, {emit} ) {
    // *** Data
    const personalData = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    return {
      personalData,
    };
  },
};
</script>
