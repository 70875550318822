<template>
    <div class="w-full text-gray-800 text-sm">
        <div class="w-auto flex flex-col items-start pl-2 pb-4">
        <p class="font-bold text-base">Präambel</p>
        <p>
            Die BKF Online-Schulungs GmbH (im Folgenden „Anbieter“), betreibt eine
            Internetplattform, auf der Online-Schulungen für
            Berufskraftfahrer-Weiterbildungen angeboten werden. Diese Plattform ist
            derzeit unter
            <Link href="https://app.bkf.online">https://app.bkf.online</Link> zu
            erreichen. Die Schulungen bestehen unter anderem aus verschiedenen Lektionen
            in Form von Schulungsvideos, die den Teilnehmern zur Verfügung gestellt
            werden.
        </p>
        </div>
        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">1. Geltungsbereich</p>
        <OrderList number="(1)">
            Diese Teilnahmebedingungen (im Folgenden „AGB“) gelten für das
            Vertragsverhältnis zwischen der BKF Online-Schulungs GmbH,
            Carl-Friedrich-Gauß Str. 8, 72202 Nagold und den Vertragspartnern (im
            Folgenden „Partner“) des BKF-Affiliate Programms (im Folgenden
            „Partnerprogramm“).
        </OrderList>

        <OrderList number="(2)">
            Wir erbringen unsere Dienste ausschließlich auf der Grundlage dieser AGB.
            Eigene Geschäftsbedingungen des Partners bedürfen unserer ausdrücklichen,
            schriftlichen Zustimmung und finden daher auch dann keine Anwendung, wenn
            wir ihrer Geltung nicht ausdrücklich widersprechen.
        </OrderList>
        <OrderList number="(3)">
            Die AGB richten sich nur an Unternehmer im Sinne des § 14 BGB. Verbraucher
            sind von der Teilnahme am Partnerprogramm ausgeschlossen.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">2. Vertragsschluss</p>
        <OrderList number="(1)">
            Ein Vertrag zwischen uns und dem Partner über die Schaltung von Werbemitteln
            kommt ausschließlich über unser Online-Bewerbungsverfahren unter
            affiliate.bkf.online zustande. Durch seine Anmeldung gibt der Partner ein
            Angebot für die Teilnahme am Partnerprogramm ab und akzeptiert dabei die
            AGB. Ein Vertrag kommt erst zustande, wenn wir ausdrücklich die Annahme des
            Angebots erklären oder konkrete Werbemittel für den Partner freigeben.
        </OrderList>

        <OrderList number="(2)">
            Es besteht kein Anspruch auf Teilnahme an dem Partnerprogramm und auf
            Abschluss eines Vertrags mit uns. Wir können jederzeit ohne Angabe von
            Gründen einzelne Partner ablehnen.
        </OrderList>
        <OrderList number="(3)">
            Dem Partner sind die Produkte und Leistungen des Anbieters bekannt. Der
            Partner bestätigt, dass er sich diesen insbesondere den angebotenen
            Leistungen und Funktionalitäten sowie dem Nutzungsumfang an den dort
            angebotenen Inhalten, der den Endkunden eingeräumt wird, vertraut gemacht
            und dass er sich über den zukünftigen Nutzungsumfang der Werke im Klaren
            ist.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">3. Vertragsgegenstand</p>
        <OrderList number="(1)">
            Gegenstand dieses Vertrages ist die Teilnahme am Partnerprogramm, mit dem
            der Abverkauf unserer Produkte über unsere Website erhöht werden soll. Die
            Teilnahme an dem Partnerprogramm ist für den Partner kostenlos. Wir stellen
            dem Partner zu diesem Zweck nach eigenem Ermessen eine Auswahl an
            Werbemitteln zur Verfügung. Wir können dabei zeitgleich verschiedene
            Programme anbieten (im Folgenden „Kampagnen“).
        </OrderList>
        <OrderList number="(2)">
            Der Partner platziert die für ihn freigegebenen Werbemittel in eigener
            Verantwortung auf seinen im Partnerprogramm angemeldeten und freigegebenen
            Werbekanälen anbieten (im Folgenden „Kanäle“). Der Partner entscheidet frei,
            ob er und wie lange er die Werbemittel auf den Kanälen platziert. Er ist
            berechtigt, die Werbemittel jederzeit wieder zu entfernen.
        </OrderList>
        <OrderList number="(3)">
            Für die Bewerbung und erfolgreiche Vermittlung von Transaktionen (z.B.
            Bestellungen) erhält der Partner eine Vermittlungsprovision, die vom Umfang
            und realen Wert der Leistung abhängig ist. Die Einzelheiten ergeben sich aus
            der Beschreibung der jeweiligen Kampagne im Partnerprogramm und Ziff. 7
            dieser AGB.
        </OrderList>
        <OrderList number="(4)">
            Das Partnerprogramm begründet zwischen den Parteien kein sonstiges über
            diesen Vertrag hinausgehendes Vertragsverhältnis.
        </OrderList>
        </div>
        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">4. Funktionsweise des Partnerprogramms</p>
        <OrderList number="(1)">
            Der Partner muss sich mit den bei der Anmeldung abgefragten Daten für das
            Partnerprogramm registrieren. Durch die Registrierung wird für den Partner
            ein Kundenkonto eingerichtet, über das der Partner seine Partneraktivitäten
            verwalten kann.
        </OrderList>
        <OrderList number="(2)">
            Für freigegebene Kampagnen stellen wir dem Partner einen spezifischen Code
            zur Verfügung, mit der Nutzer in den Produkten des Anbieters identifiziert
            werden können. Der Partner muss diesen Code an die von ihm geworbenen Nutzer
            weitergeben und von diesen hinterlegen lassen, um das Tracking zu
            ermöglichen.
        </OrderList>
        <OrderList number="(3)">
            Über das Kundenkonto erhält der Partner auch Zugriff auf bestimmte im
            Einzelnen von uns festgelegte statistische Daten über die von ihm
            eingesetzten Werbemittel.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">5. Unsere Pflichten</p>
        <OrderList number="(1)">
            Wir stellen dem Partner nach eigenem Ermessen eine Auswahl an Werbemitteln
            (z.B. Werbebanner, Textlinks, Videos und Bilder) (im Folgenden:
            „Werbemittel“) für einzelne Kampagnen zur Verfügung.
        </OrderList>
        <OrderList number="(2)">
            Wir sorgen in geeigneter Weise für ein Tracking der Besucher, die über die
            vom Partner in seine Website integrierten Werbemittel auf unsere Website
            gelangen (im Folgenden „Partner-Leads“). Wir sorgen auch für eine Zuordnung
            etwaiger Bestellungen durch Partner-Leads (im Folgenden „Sales“) zum
            Partner.
        </OrderList>
        <OrderList number="(3)">
            Wir betreiben unsere Website sowie die darauf angebotenen Services, wie die
            Bereitstellung von Produktdaten, im Rahmen der uns zur Verfügung stehenden
            technischen Möglichkeiten nach eigenem Ermessen. Wir schulden in diesem
            Rahmen keine fehler- und/oder unterbrechungsfreie Verfügbarkeit der
            Webseite. Die Qualität und Korrektheit der auf unserer Website angebotenen
            Produkte und Werbemittel stehen in unserem alleinigen Ermessen.
        </OrderList>
        <OrderList number="(4)">
            Wir verpflichten uns darüber hinaus zur Zahlung der Vergütung gem. Ziff. 7
            unter den dort festgelegten Voraussetzungen.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">6. Rechte und Pflichten des Partners</p>
        <OrderList number="(1)">
            Der Partner darf die Werbemittel nur auf von dem Anbieter genehmigten
            Kanälen einbinden. Dem Partner ist es ausdrücklich untersagt, die zur
            Verfügung gestellten Werbemittel auf anderen Kanälen zu verbreiten und/oder
            Änderungen an den Werbemitteln vorzunehmen. Die Werbemittel dürfen nur für
            die in diesem Vertrag vorgesehenen Zwecke genutzt werden.
        </OrderList>
        <OrderList number="(2)">
            Der Partner ist für die Inhalte und den laufenden Betrieb der Kanäle selbst
            verantwortlich und wird dort während der Laufzeit dieses Vertrages keine
            Inhalte platzieren, die gegen geltendes Recht, die guten Sitten oder Rechte
            Dritter verstoßen und/oder geeignet sind, unserem Ruf zu schaden. Wir sind
            berechtigt, aber nicht verpflichtet, die Kanäle zu prüfen. Verboten sind dem
            Partner insbesondere das Verbreiten von Inhalten, die
            <OrderList list class="mt-2"> Rassismus </OrderList>
            <OrderList list>
            Gewaltverherrlichung und Extremismus irgendwelcher Art,
            </OrderList>
            <OrderList list>
            Aufrufe und Anstiftung zu Straftaten und/oder Gesetzesverstößen, Drohungen
            gegen Leib, Leben oder Eigentum,
            </OrderList>
            <OrderList list> Hetzen gegen Personen oder Unternehmen, </OrderList>
            <OrderList list>
            persönlichkeitsverletzende Äußerungen, Verleumdung, Ehrverletzung und üble
            Nachrede von Nutzern und Dritten sowie Verstöße gegen das
            Lauterkeitsrecht,
            </OrderList>
            <OrderList list>
            urheberrechtsverletzende Inhalte oder andere Verletzungen von
            Immaterialgüterrechten oder
            </OrderList>
            <OrderList list>
            sexuelle Belästigung von Nutzerinnen und Nutzern und Dritten darstellen,
            betreffen oder beinhalten. Solche Inhalte dürfen weder auf den Kanälen
            selbst integriert sein noch darf von den Kanälen aus zu entsprechenden
            Inhalten auf anderen Websites verlinkt werden.
            </OrderList>
        </OrderList>
        <OrderList number="(3)">
            Jegliche Form des Missbrauchs, d.h. die Generierung von Leads und/oder Sales
            über unlautere Methoden oder unzulässige Mittel, die gegen geltendes Recht
            und/oder diese AGB verstoßen, ist untersagt. Dem Partner ist es insbesondere
            untersagt, selbst oder durch Dritte zu versuchen, mittels einer oder
            mehrerer der folgenden Praktiken Leads und/oder Sales zu generieren oder für
            eine Zuordnung von Sales zum Partner zu sorgen:
            <OrderList list class="mt-2">
            Vortäuschung von Leads oder Sales, die in Wirklichkeit nicht stattgefunden
            haben, z.B. durch die unberechtigte Angabe fremder oder die Angabe
            falscher oder nicht existierender Daten bei Bestellungen von Waren auf
            unserer Website,
            </OrderList>
            <OrderList list>
            Verwendung von Werbeformen, die zwar ein Tracking ermöglichen, dabei
            jedoch das Werbemittel nicht, nicht wahrnehmbar oder nicht in der
            vorgegebenen Form und/oder Größe anzeigen,
            </OrderList>
            <OrderList list>
            Cookie Dropping: Cookies dürfen nicht bereits beim Besuch der Webseite
            gesetzt werden, sondern ausschließlich, wenn der Nutzer der
            Partner-Webseite zuvor in freiwilliger und bewusster Weise das Werbemittel
            angeklickt hat,
            </OrderList>
            <OrderList list>
            sonstige Formen des Affiliate Frauds (insbesondere Cookie Spamming, Forced
            Clicks, Affiliate Hopping) sowie die Nutzung von Layern, Add-ons, iFrames
            und der Postview- Technologie, um damit für eine Erhöhung von Leads zu
            sorgen,
            </OrderList>
            <OrderList list>
            Verwendung von für uns oder Dritte rechtlich, insbesondere
            markenrechtlich, geschützten Begriffen etwa in Suchmaschinen, bei
            Anzeigenschaltungen oder der Bewerbung der Kanäle ohne unsere
            ausdrückliche vorherige schriftliche Zustimmung. Dem Partner ist es
            insbesondere untersagt, Websites im Internet vorzuhalten, die zu einer
            Verwechslungsgefahr mit uns oder von uns angebotenen Produkten führen
            können. Der Partner darf insbesondere nicht unsere Website, Landingpages
            oder andere Auftritte von uns kopieren oder Grafiken, Texte oder andere
            Inhalte von uns übernehmen. Der Partner muss den Eindruck vermeiden, dass
            es sich bei der Partner- Website um ein Projekt von uns handelt oder dass
            ihr Betreiber mit uns in einer Weise wirtschaftlich verbunden ist, die
            über das Partnerprogramm und diesen Vertrag hinausgeht. Jede Verwendung
            von Materialien oder Inhalten aus unserem Auftritt sowie von unseren Logos
            oder Marken durch den Partner bedarf unserer vorherigen schriftlichen
            Freigabe.
            </OrderList>
        </OrderList>
        <OrderList number="(4)">
            Der Partner verpflichtet sich, die Kanäle auch im Übrigen im Einklang mit
            geltendem Recht zu betreiben und insbesondere ein ordnungsgemäßes Impressum
            vorzuhalten.
        </OrderList>
        <OrderList number="(5)">
            E-Mail-Werbung, die Werbemittel enthält oder auf sonstige Weise für uns
            wirbt, darf nur erfolgen, wenn dies von uns zuvor freigegeben wurde und für
            alle Adressaten eine ausdrückliche Einwilligung in die Werbung per E-Mail
            vorliegt und eine Verifikation der E-Mail-Adresse durch ein
            Double-Opt-in-Verfahren durchgeführt und dokumentiert wurde.
        </OrderList>
        <OrderList number="(6)">
            Der Partner wird unverzüglich Werbemittel von den Kanälen entfernen, wenn er
            von uns dazu aufgefordert wird. Dies gilt auch und insbesondere für Kanäle,
            auf denen wir eine Einbindung der Werbemittel gleich aus welchem Grund nicht
            oder nicht mehr wünschen.
        </OrderList>
        <OrderList number="(7)">
            Der Partner wird bei einer etwaigen Bewerbung der Kanäle auf einen Bezug zu
            uns und unseren Produkten verzichten. Insbesondere wird der Partner keine
            kontextbasierten Werbeanzeigen (insbesondere Google AdWords oder AdSense)
            schalten, die unseren Namen, Firmenschlagworte oder Marken enthalten oder
            aufgrund der Verwendung entsprechender Keywords ausgeliefert werden. Das
            Gleiche gilt für die Namen unserer Produkte.
        </OrderList>
        <OrderList number="(8)">
            Der Partner verpflichtet sich, elektronische Angriffe jeglicher Art auf
            unser Trackingsystem und/oder unsere Websites zu unterlassen. Als
            elektronische Angriffe gelten insbesondere Versuche, die
            Sicherheitsmechanismen des Trackingsystems zu überwinden, zu umgehen oder
            auf sonstige Art außer Kraft zu setzen, der Einsatz von Computerprogrammen
            zum automatischen Auslesen von Daten, das Anwenden und/oder Verbreiten von
            Viren, Würmern, Trojanern, Brute-Force-Attacken, Spam oder die Verwendung
            von sonstigen Links, Programmen oder Verfahren, die das Trackingsystem, das
            Partnerprogramm oder einzelne Beteiligte des Partnerprogramms schädigen
            können.
        </OrderList>
        </div>
        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">7. Vergütung</p>
        <OrderList number="(1)">
            Der Vertragspartner erhält von uns eine erfolgsabhängige Vergütung für
            Sales, die auf unserer Plattform von Partner-Leads durchgeführt werden.
        </OrderList>
        <OrderList number="(2)">
            Der Anspruch auf Zahlung der Vergütung entsteht nur unter folgenden
            Voraussetzungen:
            <OrderList list class="mt-2">
            durch die Werbetätigkeit des Partners ist ein Sale eines Endkunden mit uns
            zustande gekommen,
            </OrderList>
            <OrderList list>
            der Sale ist von uns protokolliert („getrackt“) worden,
            </OrderList>
            <OrderList list>
            der Sale ist von uns freigegeben und bestätigt worden und
            </OrderList>
            <OrderList list>
            es liegt kein Missbrauch im Sinne der Ziff. 6.3 dieser AGB vor.
            </OrderList>
        </OrderList>
        <OrderList number="(3)">
            Als Sale gilt eine auf unserer Plattform von einem Endkunden generierte
            vollständig durchgeführte Bestellung, die von dem Endkunden auch bezahlt
            wurde. Rückabwicklungen – gleich aus welchem Grund – gelten nicht als Sale,
            wenn der Endkunde nicht gezahlt hat oder von ihm geleistete Zahlungen
            erstattet werden. Rückabwicklungen, die mehr als 8 Wochen nach Zahlung des
            Endkunden geschehen, bleiben außer Betracht.
        </OrderList>
        <OrderList number="(4)">
            Nicht vergütungspflichtig sind Bestellungen des Partners oder seiner
            Angehörigen.
        </OrderList>
        <OrderList number="(5)">
            Nicht vergütungspflichtig sind Bestellungen, die aufgrund von Partner-Leads
            zustande kommen, die über Kanäle oder andere Werbeflächen generiert wurden,
            bei denen wir den Partner zu der Entfernung der Werbemittel aufgefordert
            haben. Dies gilt ab dem Zeitpunkt der Aufforderung.
        </OrderList>
        <OrderList number="(6)">
            Die Vergütung steht dem Partner für einen Zeitraum von drei Jahren nach
            erfolgreicher Werbung eines Kunden für die Produkte des Anbieters zu. Wirbt
            der Partner jedoch innerhalb des Zeitraumes von drei Jahren keinen weiteren
            Kunden, welcher vergütungspflichtige Umsätze erzeugt, entfallen die
            Ansprüche auf Vergütung entsprechend bei dem Erreichen des Endes des
            Zeitraums.
        </OrderList>
        <OrderList number="(7)">
            Maßgeblich für die Frage, ob ein Sale auf einem Partner-Lead beruht, ist das
            von uns eingesetzte Trackingsystem. Vorbehaltlich anderweitiger Festlegungen
            im Partnerprogramm oder bei einzelnen Kampagnen gilt das Prinzip „Last
            Cookie Wins“ bei einer Cookie-Laufzeit von 30 Tagen. Uns trifft keine
            Zahlungspflicht, wenn und soweit das Trackingsystems ausfällt oder eine
            sonstige Fehlfunktion verursacht wird, die dazu führt, dass eine Zuordnung
            von Leads oder Sales zu einzelnen Partnern nicht oder nur mit erheblichem
            Aufwand möglich ist.
        </OrderList>
        <OrderList number="(8)">
            Die Höhe der Vergütung richtet sich nach der in der jeweiligen Kampagne
            angegebenen Provision im Zeitpunkt des Sales. Sofern nichts angegeben ist,
            gilt eine Provision von 5 % des Nettopreises des jeweiligen Sales.
        </OrderList>
        <!-- todo: wording <OrderList number="(9)">
            Alle angegebenen Provisionen verstehen sich als Netto-Entgelt und werden
            zuzüglich Mehrwertsteuer ausgezahlt.
        </OrderList> -->
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">8. Abrechnung</p>
        <OrderList number="(1)">
            Wir werden dem Partner eine Abrechnung der Vergütungsansprüche in seinem
            Kundenkonto zur Verfügung stellen. Der Partner wird die Abrechnung
            unverzüglich prüfen. Hat der Partner gegen eine Abrechnung Einwendungen,
            sind diese uns gegenüber innerhalb von vier Wochen schriftlich geltend zu
            machen. Nach Verstreichen dieser Frist gilt die Abrechnung als zutreffend.
        </OrderList>

        <OrderList number="(2)">
            Vergütungsansprüche werden jeweils zwei Monate nach Ende des Monats zur
            Zahlung fällig, in den der Zahlungseingang des Endkunden für den
            betreffenden Sale fällt. Vergütungsansprüche sind nur fällig, wenn ein
            Mindestauszahlungsbetrag von 50,- Euro erreicht ist. Der Partner hat das
            Recht, auch geringere Beträge gegen Erstattung einer pauschalen
            Bearbeitungsgebühr von 10,- Euro zu verlangen. Die Gebühr wird von dem
            auszuzahlenden Betrag einbehalten.
        </OrderList>
        <OrderList number="(3)">
            Die Auszahlung erfolgt per Banküberweisung mit schuldbefreiender Wirkung an
            die vom Partner im Kundenkonto hinterlegte Bankverbindung. Etwaige
            Bankgebühren (z.B. bei Bankverbindungen im Ausland) gehen zu Lasten des
            Partners.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">9. Haftung</p>
        <OrderList number="(1)">
            Unbeschränkte Haftung: Wir haften unbeschränkt für Vorsatz und grobe
            Fahrlässigkeit sowie nach Maßgabe des Produkthaftungsgesetzes. Für leichte
            Fahrlässigkeit haften wir bei Schäden aus der Verletzung des Lebens, des
            Körpers und der Gesundheit von Personen.
        </OrderList>

        <OrderList number="(2)">
            Im Übrigen gilt folgende beschränkte Haftung: Bei leichter Fahrlässigkeit
            haften wir nur im Falle der Verletzung einer wesentlichen Vertragspflicht,
            deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst
            ermöglicht und auf deren Einhaltung der Partner regelmäßig vertrauen darf
            (Kardinalpflicht). Die Haftung für leichte Fahrlässigkeit ist der Höhe nach
            beschränkt auf die bei Vertragsschluss vorhersehbaren Schäden, mit deren
            Entstehung typischerweise gerechnet werden muss.
        </OrderList>
        <OrderList number="(3)">
            Eine weitergehende Haftung von uns besteht nicht.
        </OrderList>
        <OrderList number="(4)">
            Die vorstehende Haftungsbeschränkung gilt auch für die persönliche Haftung
            unserer Mitarbeiter, Vertreter und Organe.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">
            10. Freistellungsanspruch/Vertragsstrafe
        </p>
        <OrderList number="(1)">
            Der Partner stellt uns und unserer Mitarbeiter bzw. Beauftragten für den
            Fall der Inanspruchnahme wegen vermeintlicher oder tatsächlicher
            Rechtsverletzung und/oder Verletzung von Rechten Dritter durch vom Partner
            im Zusammenhang mit dem Partnerprogramm vorgenommenen Handlungen von
            sämtlichen sich daraus ergebenen Ansprüchen Dritter frei. Darüber hinaus
            verpflichtet sich der Partner, alle Kosten zu ersetzen, die uns durch eine
            solche Inanspruchnahme durch Dritte entstehen. Zu den erstattungsfähigen
            Kosten zählen auch die Kosten einer angemessenen Rechtsverteidigung.
        </OrderList>

        <OrderList number="(2)">
            Der Partner verpflichtet sich, für jeden Fall des Missbrauchs gem. Ziff. 6.3
            eine von uns nach billigem Ermessen festzusetzende, im Streitfall von einem
            Gericht zu überprüfende, Vertragsstrafe zu zahlen. Die Vertragsstrafe wird
            das Zwölffache des stärksten Monatsumsatzes des Partners innerhalb der
            letzten sechs Monate vor dem Missbrauch nicht übersteigen. Weitergehende
            Schadenersatzansprüche bleiben von dieser Regelung unberührt.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">11. Nutzungsrechte</p>
        <OrderList number="(1)">
            Die Werbemittel und unseren sonstigen Inhalte sind urheberrechtlich und/oder
            durch sonstige gewerbliche Schutzrechte geschützt. Wir räumen dem Partner
            für die Dauer und den Zweck dieses Vertrages ein einfaches und nicht
            ausschließliches Recht ein, die Werbemittel zu nutzen.
        </OrderList>

        <OrderList number="(2)">
            Jegliche Veränderung, Vervielfältigung, Verbreitung oder öffentliche
            Wiedergabe der Werbemittel oder eines nach Art und Umfang wesentlichen Teils
            bedarf unserer vorherigen schriftlichen Zustimmung, soweit sie über den in
            vorstehendem Absatz 1 eingeräumten Umfang hinausgeht.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">12. Vertraulichkeit</p>
        <OrderList number="(1)">
            Der Partner verpflichtet sich, alle im Rahmen des Vertragsverhältnisses
            erlangten Kenntnisse unserer Betriebs- und Geschäftsgeheimnisse oder
            sonstiger vertraulicher Informationen zeitlich unbegrenzt (auch über das
            Ende dieses Vertrages hinaus) geheim zu halten, nur für die Zwecke des
            Vertrages zu verwenden und insbesondere nicht an Dritte weiterzugeben oder
            sonst zu verwerten. Werden Informationen von uns als vertraulich bezeichnet,
            gilt die unwiderlegbare Vermutung, dass es sich um Betriebs- oder
            Geschäftsgeheimnisse handelt.
        </OrderList>

        <OrderList number="(2)">
            Der Inhalt dieses Vertrages und die dazu gehörenden Unterlagen sind vom
            Partner vertraulich (als Betriebs- und Geschäftsgeheimnis) zu behandeln.
        </OrderList>
        <OrderList number="(3)">
            Der Partner hat seine Mitarbeiter und sonstige Personen, deren er sich zur
            Erfüllung seiner vertraglichen Pflichten bedient, in einer den vorstehenden
            Absätzen 1 und 2 entsprechenden Weise zur Vertraulichkeit zu verpflichten.
        </OrderList>
        <OrderList number="(4)">
            Die Kunden, welche durch den Partner für den Anbieter geworben wurden,
            unterliegen der Exklusivität. Die Kommunikation zwischen geworbenen Kunden
            und dem Partner wird gerne gesehen, die Abwerbung von Kunden durch Partner
            für alternativ Produkte ist allerdings strikt untersagt.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">
            13. Vertragslaufzeit und Beendigung des Vertrages, Sperre
        </p>
        <OrderList number="(1)">
            Der Vertrag läuft auf unbestimmte Zeit und kann von beiden Seiten jederzeit
            ohne Einhaltung einer Kündigungsfrist und Angabe von Gründen gekündigt
            werden.
        </OrderList>

        <OrderList number="(2)">
            Daneben und darüber hinaus bleibt das Recht der Parteien, das
            Vertragsverhältnis durch außerordentliche Kündigung aus wichtigem Grund zu
            beenden, unberührt. Für uns liegt ein wichtiger Grund, der zur
            außerordentlichen Kündigung berechtigt, insbesondere in folgenden Fällen
            vor:
            <OrderList list class="mt-2">
            gravierender Verstoß des Partners gegen Pflichten dieses Vertrages,
            insbesondere ein Verstoß gegen Ziff. 6.2, 6.4 und/oder 6.8,
            </OrderList>
            <OrderList list>
            Verstoß gegen Pflichten dieses Vertrages und Nichtbehebung bzw.
            Einstellung des Verstoßes trotz unserer entsprechenden Aufforderung,
            </OrderList>
            <OrderList list>
            ein Fall von Missbrauch im Sinne von Ziff. 6.3.
            </OrderList>
        </OrderList>
        <OrderList number="(3)">
            Die Kündigung kann per E-Mail erfolgen. Eine von uns per E-Mail erklärte
            Kündigung gilt an dem Tag als zugegangen, an dem sie an die vom Partner im
            Kundenkonto angegebene E-Mail-Adresse gesendet wird. Wir können die
            Kündigung auch durch Beschränkung des Zugangs zum Kundenkonto erklären. Der
            Partner kann die Kündigung auch durch Löschung des Kundenkontos erklären.
            Der Vertrag wird mit Zugang der Kündigung beendet.
        </OrderList>
        <OrderList number="(4)">
            Nach Beendigung des Vertrages ist der Partner verpflichtet, alle Werbemittel
            und sonstigen Links und Inhalte von uns unverzüglich von der Partner-Website
            zu entfernen. Dies gilt auch für Websites oder andere Werbemedien, in denen
            der Partner die Werbemittel oder Links integriert hat, ohne dazu berechtigt
            zu sein.
        </OrderList>
        <OrderList number="(5)">
            Nach Beendigung des Vertrages generierte Leads und/oder Sales führen nicht
            zu einer Vergütungspflicht.
        </OrderList>
        <OrderList number="(6)">
            Anstelle der Kündigung können wir in den Fällen von Ziff. 13.2 auch das
            Kundenkonto sperren. Dies gilt auch, wenn lediglich ein begründeter Verdacht
            auf einen Missbrauch gem. Ziff. 6.3 besteht. Wir werden dem Partner den
            Grund für die Sperre mitteilen und die Sperre wieder aufheben, wenn die
            Gründe, die zu der Sperre geführt haben, aufgeklärt und gegebenenfalls
            beseitigt sind. Im Zeitraum der Sperre generierte Leads führen nicht zu
            einer Vergütungspflicht.
        </OrderList>
        </div>

        <div class="w-auto flex flex-col items-start pl-2">
        <p class="font-bold text-base py-1">14. Schlussbestimmungen</p>
        <OrderList number="(1)">
            Sollte der Vertrag unwirksame Regelungen enthalten, bleibt die Wirksamkeit
            des Vertrages im Übrigen unberührt.
        </OrderList>

        <OrderList number="(2)">
            Wir behalten und vor, diese AGB jederzeit anzupassen. Etwaige Änderungen
            werden dem Partner per E-Mail mitgeteilt. Sollte der Partner mit den
            Änderungen nicht einverstanden sein, ist er berechtigt, uns dies bis zum
            Ablauf von vier Wochen ab dem Zugang der Änderungsmitteilung mitzuteilen.
            Wir haben in diesem Fall ein Sonderkündigungsrecht. Erfolgt eine solche
            Mitteilung nicht binnen dieser Frist, gelten die Änderungen als akzeptiert
            und treten mit Ablauf der Frist in Kraft.
        </OrderList>
        <OrderList number="(3)">
            Auf den vorliegenden Vertrag ist ausschließlich deutsches Recht anwendbar.
        </OrderList>
        <OrderList number="(4)">
            Ist der Partner Kaufmann, juristische Person des öffentlichen Rechts oder
            öffentlichrechtliches Sondervermögen, wird Stuttgart, Deutschland als
            Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit Verträgen
            zwischen uns und dem Partner vereinbart.
        </OrderList>
        </div>
    </div>
</template>
<script>

import Link from "@/components/atoms/Link";
import OrderList from "@/components/atoms/OrderList";
export default {
  components: {
    Link,
    OrderList,
  },
};
</script>
