import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Welcome from '../views/Welcome.vue'
import Register from '../views/auth/Register.vue'
import Login from '../views/auth/Login.vue'
import ResendEmail from '../views/auth/ResendEmail.vue'
import CompleteData from '../views/auth/CompleteData.vue'
import RegisterSuccess from '../views/auth/RegisterSuccess.vue'
import Reports from '../views/Reports.vue'
import ForgetPassword from '../views/auth/ForgetPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import DeleteAccount from '../views/auth/DeleteAccount.vue'
import Contact from '../views/Contact.vue'
import Policy from '../views/Policy.vue'
import Imprint from '../views/Imprint.vue'
import Profile from '../views/Profile.vue'
import NotFound from '../views/NotFound.vue'
import Terms from '../views/Terms.vue'
import TwoFactorAuth from '../views/auth/TwoFactorAuth.vue'
import {isLoggedIn, isCompleted} from "../store/auth/auth";
import store from "../store/index";

const routes = [
    {
        path: '/dashboard',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
            isAuthPage: false
        },
    },
    {
        path: '/',
        name: 'Welcome',
        component: Welcome,
        meta: {
            requiresAuth: false,
            isAuthPage: true
        },
    },
    {
        path: '/:pathMatch(.*)',
        name: 'NotFound',
        component: NotFound,
        meta: {
            requiresAuth: false,
            isAuthPage: false
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            requiresAuth: false,
            isAuthPage: true
        },
    },
    {
        path: '/resend-email',
        name: 'ResendEmail',
        component: ResendEmail,
        meta: {
            requiresAuth: false,
            isAuthPage: false
        },
    },
    {
        path: '/complete-data',
        name: 'CompleteData',
        component: CompleteData,
        meta: {
            requiresAuth: true,
            isAuthPage: false
        },

    },
    {
        path: '/register-success',
        name: 'RegisterSuccess',
        component: RegisterSuccess,
        meta: {
            requiresAuth: true,
            isAuthPage: false
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false,
            isAuthPage: true
        },
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            requiresAuth: false,
            isAuthPage: true
        },
    },
    {
        path: '/delete-account',
        name: 'DeleteAccount',
        component: DeleteAccount,
        meta: {
            requiresAuth: true,
            isAuthPage: false
        },
    },
    {
        path: '/forget-password',
        name: 'ForgetPassword',
        component: ForgetPassword,
        meta: {
            requiresAuth: false,
            isAuthPage: true,
        }
    },
    {
        path: '/forget-password',
        name: 'ForgetPassword',
        component: ForgetPassword,
        meta: {
            requiresAuth: false,
            isAuthPage: true
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            requiresAuth: false,
            isAuthPage: false
        },
    },
    {
        path: '/policy',
        name: 'Policy',
        component: Policy,
        meta: {
            requiresAuth: false,
            isAuthPage: false
        },
    },
    {
        path: '/imprint',
        name: 'Imprint',
        component: Imprint,
        meta: {
            requiresAuth: false,
            isAuthPage: false
        },
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta: {
            requiresAuth: false,
            isAuthPage: false
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            requiresAuth: true,
            isAuthPage: false
        },
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta: {
            requiresAuth: true,
            isAuthPage: false
        },
    },
    {
        path: '/two-factor-auth',
        name: 'twoFactorAuth',
        component: TwoFactorAuth,
        meta: {
            requiresAuth: false,
            isAuthPage: true
        },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (isLoggedIn()) {
            if (!isCompleted()) {
                if(to.name !== 'CompleteData')
                router.push({
                    path: "/complete-data",
                });
            }else if(to.name === 'CompleteData')
                router.push({
                    path: "/dashboard",
                });
            next();
        } else {
            store.dispatch('logout');
            router.push({
                path: "/login",
            });
        }
    } else if(to.matched.some((record) => record.meta.isAuthPage)) {
        if (isLoggedIn()) {
            if (!isCompleted()) {
                router.push({
                    path: "/complete-data",
                });
            }else {
                router.push({
                    path: "/dashboard",
                });
            }
        } else {
            next();
        }

    }else {
        next();

    }
});

export default router
