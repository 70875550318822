<template>
    <div class="w-full flex flex-col">
        <div class="w-full flex flex-col relative">
            <transition name="slide-fade">
                <label v-if="label" v-show="showLabel" :class="classes.label" :for="name">
                    {{ label }} <span v-show="required" class="-mt-px px-px">*</span>
                </label>
            </transition>
            <div class="w-full flex">
                <div
                        @click="$emit('clickIcon')"
                        v-show="icon && iconPosition == 'start'"
                        class="w-6 h-12 flex justify-center items-center left-2 absolute cursor-pointer"
                >
                    <Icon :name="icon" class="flex w-4 h-4 mt-0.5"/>
                </div>
                <input
                        ref="textinput"
                        :value="value || modelValue"
                        :type="inputType"
                        :placeholder="fullPlaceholder"
                        :name="name"
                        :class="classes.input"
                        :disabled="disabled"
                        :required="required"
                        :autocomplete="autocomplete"
                        @input="(e) => $emit('update:modelValue', e.target.value)"
                        @blur="handleBlur"
                        @focus="handleFocus"
                />
                <div
                        @click="$emit('clickIcon')"
                        @mouseover="$emit('hoverIcon', { hover: true })"
                        @mouseleave="$emit('hoverIcon', { hover: false })"
                        v-show="icon && iconPosition == 'end'"
                        class="w-6 h-12 flex select-none justify-center items-center right-2 absolute cursor-pointer"
                >
                    <Icon :name="icon" class="flex w-4 h-4 mt-0.5"/>
                </div>
                <div
                        v-if="type === 'password'"
                        @click="togglePassword"
                        :class="classes.passwordEye"
                >
                    <Icon v-show="!showPassword" name="eye-on" class="flex w-6 h-6"/>
                    <Icon v-show="showPassword" name="eye-off" class="flex w-6 h-6"/>
                </div>
            </div>
            <p v-if="errorMessage" class="text-xs py-0.5 text-red-500">
                {{ errorMessage }}
            </p>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/atoms/Icon";
    import {computed, onMounted, ref} from "vue";

    export default {
        components: {
            Icon,
        },
        props: {
            value: {type: String, default: ""},
            type: {type: String, default: "text"},
            name: {type: String, default: ""},
            placeholder: {type: String, default: ""},
            label: {type: String, default: ""},
            disabled: {type: Boolean, default: false},
            errors: {type: [Array, Object], default: () => null},
            required: {type: Boolean, default: false},
            errorMessage: {type: [String, Boolean], default: ""},
            autocomplete: {type: String, default: "off"},
            icon: {type: String, default: ""},
            iconPosition: {type: String, default: "start"}, // or `end`
            modelValue: String,
        },
        setup(props, {emit}) {
            const focused = ref(false);
            const showLabel = ref(false);

            // refs textinput element instaed of document.getElement
            const textinput = ref(null);

            // if the input type  = password
            const showPassword = ref(false);
            const inputType = ref(props.type);


            const fullPlaceholder = computed(() => {
                if(showLabel.value) return '';
                const { placeholder, required } = props;
                if (required) return placeholder + " *";
                return placeholder
            });

            // CSS Classes
            const classes = computed(() => {
                return {
                    label: {
                        "text-xs pb-2 flex absolute top-1.5 left-4 transition-all duration-150 ease-in-out": true,
                        "text-secondary-400": focused.value,
                        "text-gray-500": !focused.value,
                    },
                    input: {
                        "w-full border text-sm px-4 h-12 rounded transition-all duration-150 ease-in-out": true,
                        "pt-3": showLabel.value && props.label,
                        "border-gray-300": !focused.value,
                        "border-secondary-400": focused.value && !props.errorMessage,
                        "border-red-500": props.errorMessage,
                        "pl-8": props.icon && props.iconPosition == "start",
                        "pr-8": props.icon && props.iconPosition == "end",
                        "cursor-not-allowed bg-gray-100 text-gray-700": props.disabled,
                    },
                    passwordEye: {
                        "w-8 h-6 absolute right-3 hover:text-primary-200 flex items-center border-gray-300 justify-end top-3 cursor-pointer border-l transition-all duration-150 ease-in-out": true,
                    },
                };
            });

            // methods
            function handleBlur() {
                if (textinput.value && textinput.value.value !== "" && props.label) {
                    showLabel.value = true;
                } else {
                    showLabel.value = false;
                }
                focused.value = false;
                emit("blur");
            }

            function handleFocus() {
                focused.value = true;
                showLabel.value = true;
                emit("focus");
            }

            // if the input type  = password
            function togglePassword() {
                if (showPassword.value) {
                    showPassword.value = false;
                    inputType.value = "password";
                } else {
                    showPassword.value = true;
                    inputType.value = "text";
                }
            }

            // at mount the input it will detect if the input empty or already filled to toggle label on/off
            onMounted(() => {
                if (textinput.value && textinput.value.value !== "") {
                    showLabel.value = true;
                }
            });

            return {
                focused,
                showLabel,
                classes,
                fullPlaceholder,
                textinput,
                handleBlur,
                handleFocus,
                showPassword,
                inputType,
                togglePassword,
            };
        },
    };
</script>
