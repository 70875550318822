<template>
  <DefaultLayout>
    <GenerateCodeSection v-if="!currentCode" @saveCode="saveCode" />
    <Suspense v-else>
      <template #default>
        <div class="w-full pb-24">
          <ReportsHeader :total="total" :code="currentCode" />
          <div class="w-full flex justify-between pt-3 items-center pb-6 md:pb-8">
            <h2 class="text-3xl lg:text-4xl font-semibold">{{ t("report") }}</h2>
            <div v-if="totalPages > defaultPerPage " class="w-48 flex justify-between">
              <div class="w-auto pr-2 flex items-center text-left">
                <p class="text-sm flex"> {{ `${currentPage} ${t('from')} ${pages} ${t('pages')}` }}</p>
              </div>
              <div class="w-2/5">
                <SelectInput :options="[20, 40, 60]" v-model="perPage" @changeOption="setPerPage" />
              </div>
            </div>
          </div>
          <ReportsTable
            :reports="userReports && userReports.data ? userReports.data : []"
            :total="totalPages"
          />
          <div class="w-full py-16" v-if="userReports.data && totalPages > perPage">
            <Pagination :pages="pages" :currentPage="currentPage" @setCurrentPage="setCurrentPage" />
          </div>
        </div>
      </template>
      <template #fallback> <Loader /> </template>
    </Suspense>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/components/layouts/Default";
import translateMixin from "@/mixins/translate.js";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import GenerateCodeSection from "@/components/reports/GenerateCodeSection";
import ReportsHeader from "@/components/reports/ReportsHeader";
import ReportsTable from "@/components/reports/ReportsTable";
import Pagination from "@/components/atoms/Pagination";
import SelectInput from "@/components/forms/SelectInput";
import Loader from "@/components/atoms/Loader";
import useFormat from "@/mixins/useFormat.js";
export default {
  mixins: [translateMixin],
  components: {
    DefaultLayout,
    GenerateCodeSection,
    ReportsHeader,
    ReportsTable,
    Loader,
    Pagination,
    SelectInput
  },
  setup() {
    const store = useStore();
    store.dispatch("getUserCode");
    store.dispatch("marketingChannels/getMarketingChannels");

    onMounted(() => {
      getUserReports()
    });

    const {currencyFromat} = useFormat();
    // if the user has no code then we will show the generate-code section
    const currentCode = computed(() => {
      return store.getters.currentCode;
    });
    const userReports = computed(() => {
      return  store.getters.userReports;
    });
    const totalPages = ref(1);
    const defaultPerPage = 20;
    const currentPage = ref(1);
    const perPage = ref(defaultPerPage)
    const pages = ref(0);
    const errors = ref(null)

    function setCurrentPage(page) {
      currentPage.value = page;
      getUserReports(page)
    }
    function setPerPage() {
      const page = 1;
      currentPage.value = page;
      getUserReports(page)
    }

    function saveCode() {
      // save the code then change the page content to show reports
    }

     async function getUserReports(page = 1) {
      try {
       const response = await store.dispatch("getUserReports",{
          page:page,
          perPage:perPage.value,
        });
       const reports =response.reports;
        currentPage.value = reports.current_page;
        perPage.value = reports.per_page;
        pages.value = reports.last_page;
        totalPages.value = reports.total;
      } catch (errors) {
        errors.value = errors
      }
    }

    const total = computed(() => {
      if (
        userReports.value &&
        userReports.value.meta &&
        userReports.value.meta.total_unpaid_commissions
      ) {
        return currencyFromat(userReports.value.meta.total_unpaid_commissions);
      } else {
        return '0';
      }
    });

    return {
      currentCode,
      saveCode,
      userReports,
      total,
      currencyFromat,
      setCurrentPage,
      currentPage,
      pages,
      perPage,
      getUserReports,
      setPerPage,
      defaultPerPage,
      totalPages,
      errors,
    };
  },
};
</script>
