<template>
  <nav aria-label="Progress" class="w-full">
    <ol role="list" class="flex items-center w-full">
      <li
        v-for="(step, index) in steps"
        :key="step.name"
        class="relative"
        :class="index + 1 < steps ? 'w-full' : 'w-auto'"
      >
        <template v-if="step < current">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div
              class="h-0.5 w-full"
              :class="
                step < current - 1
                  ? 'bg-secondary-500'
                  : 'bg-gradient-to-r from-secondary-500 to-secondary-300'
              "
            />
          </div>
          <div
            @click="setCurrentStep(step)"
            class="relative flex w-5 h-5 items-center justify-center cursor-pointer bg-secondary-500 rounded-full hover:bg-secondary-500"
          >
            <Icon name="check" class="text-white w-4 h-4 block p-0.5" />
          </div>
        </template>
        <template v-else-if="step === current || (current == 0 && step == 1)">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-gray-300" />
          </div>
          <div
            class="relative w-5 h-5 flex items-center justify-center bg-secondary-300 border-2 border-secondary-300 rounded-full"
            aria-current="step"
          ></div>
        </template>
        <template v-else>
          <div
            v-if="index + 1 < steps"
            class="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div class="h-0.5 w-full bg-gray-300" />
          </div>
          <div
            class="group relative w-5 h-5 flex items-center justify-center bg-gray-200 border-2 last:border-none border-gray-200 rounded-full"
          >
            <span class="h-2.5 w-2.5 bg-transparent rounded-full" aria-hidden="true" />
          </div>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import Icon from "@/components/atoms/Icon";
export default {
  components: {
    Icon,
  },
  props: {
    steps: { type: [Number, String], default: 0 },
    current: { type: [Number, String], default: 0 },
  },
  setup(props, { emit }) {
    function setCurrentStep(step) {
      emit("setCurrentStep", step);
    }
    return {
      setCurrentStep,
    };
  },
};
</script>
