<template>
  <component
    v-bind="options"
    :is="options.is"
    :class="classes.root"
    :disabled="disabled"
    :type="type"
  >
    <slot></slot>
    <span :class="disabled ? 'opacity-50' : ''" v-html="text" />
  </component>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    text: { type: String, default: null },
    to: { type: String, default: "" },
    href: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    primary: { type: Boolean, default: false },
    type: { type: String, default: "" },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        root: {
          "button flex justify-center rounded-full px-4 transition ease-in-out duration-300 hover-scale-102 flex-shrink-0 outline-none border-0": true,
          "bg-primary-300 py-3 font-semibold hover:bg-primary-400 focus:bg-primary-300 text-xl text-white hover:shadow-lg cursor-pointer":
            props.primary && !props.disabled,
          "bg-gray-200 py-3 font-semibold text-xl": props.primary && props.disabled,
          "cursor-not-allowed": props.disabled,
        },
      };
    });
    const options = computed(() => {
      const data = {
        is: "button",
      };
      if (props.to) {
        data.is = "router-link";
        data.to = props.to;
      }
      if (props.href) {
        data.is = "a";
        data.href = props.href;
        if (props.href[0] !== "#") {
          data.target = "_blank";
          data.rel = "noopener noreferrer";
        }
      }
      return data;
    });
    return {
      classes,
      options,
    };
  },
};
</script>
