<template>
  <div :tabindex="tabindex" @blur="open = false" class="w-full relative text-left outline-none text-sm">
    <div @click="open = !open" :class="classes.selectedArea" class="w-full pl-2 select-none cursor-pointer flex relative items-center justify-start p-2">
      <div @click="open = !open" class="w-6 absolute right-0 top-0  mr-px mt-3 cursor-pointer">
        <Icon
          @click="open = !open"
          :name="!open ? 'chev-right' : 'chev-down'"
          class="text-gray-900 w-2 h-2 p-px -mt-px"
        />
      </div>
      <p class="text-gray-900 text-left w-full pl-1">
        {{ selected }}
      </p>
    </div>
    <div v-show="options.length" :class="{'hidden': !open}" class="rounded-b overflow-hidden absolute bg-white left-0 right-0 px-3 z-30 shadow text-gray-800 border-b border-l border-r border-gray-300">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="updateInput(option)"
        class="w-full text-left text-black select-none cursor-pointer hover:bg-gray-50"
      >
        <p class="w-full border-t border-gray-300 py-2">
          {{ option }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/atoms/Icon";
import { computed, ref } from 'vue'
export default {
    components: {
        Icon
    },
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        value: { type: [String, Array, Boolean, Number]},
        modelValue:{type: [String, Array, Boolean, Number,],  default: 0},
        options: { type: [String, Array, Boolean], required: true, default: () => []},
        tabindex: { type: Number, default: 0},
    },
    setup(props, { emit }) {
        const open = ref(false)
        const selected = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });
        const classes = computed({
            get: () => {
                return {
                    selectedArea: {
                        'rounded-t border-gray-300 border-b-0 border-t border-r border-l': open.value && props.options.length,
                        'border border-gray-300 rounded': !open.value || (!props.options.length && open.value)
                    }
                }
            }
        })
        function updateInput(val) {
            selected.value = val;
            emit('update:modelValue', val)
            emit('changeOption', val)
            open.value = false
        }
        return {
            updateInput,
            selected,
            open,
            classes
        }
    }
}
</script>

