<template>
  <div class="flex flex-col">
    <p v-html="t('marktingText')" class="text-lg text-left md:text-center pt-1 md:pt-3">
    </p>
    <div class="w-full grid gap-8 py-8">
      <div class="w-24 bg-secondary-200 rounded-full text-center py-1.5 font-bold">
        {{ t("online") }}
      </div>
      <MediaInput
        v-for="(platform, index) in mediaData.online"
        v-show="index <= showListOfMedia"
        :key="index"
        v-model="platform.value"
        :label="platform.label"
        :name="platform.name"
        :checked="!platform.disabled"
        placeholder="enterProfileURL"
        disabled="false"
        @changeCheckbox="toggleMedia"
      />
      <p v-if="errors.online" class="text-xs py-0.5 text-red-500 text-center">{{t('errors.channelsProfileUrl')}}</p>
      <div @click="toggleMediaList" class="w-full cursor-pointer">
        <p class="underline">{{ showListOfMedia > 2 ? t("showLess") : t("showMore") }}</p>
      </div>
      <div class="w-24 bg-secondary-200 rounded-full text-center py-1.5 font-bold mt-4">
        {{ t("offline") }}
      </div>
      <textarea
        v-model="mediaData.offline.description"
        :placeholder="t('tellAboutIdeas')"
        name="offlineMarketingChannel"
        id="aboutIdea"
        rows="2"
        class="w-full border focus:border-secondary-500 bg-gray-100 rounded outline-none p-4"
      ></textarea>
      <p v-if="errors.offline" class="text-xs py-0.5 text-red-500 ">{{errors.offline}} </p>
    </div>
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import { computed, ref } from "vue";
import MediaInput from "@/components/forms/MediaInput";

export default {
  mixins: [translateMixin],
  components: {
    MediaInput,
  },
  props: {
    modelValue: {
      type: Object,
    },
    marketingData: {
      type: Object,
    },
    errors: {},
  },
  setup(props, { emit }) {
    // *** Data

    const mediaData = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const showListOfMedia = ref(2);

    // *** Methods
    function toggleMediaList() {
      if (showListOfMedia.value === 2) {
        showListOfMedia.value = mediaData.value.online.length;
      } else {
        showListOfMedia.value = 2;
      }
    }
    function toggleMedia(data) {
      emit("toggleMediaItem", data);
    }
    return {
      mediaData,
      showListOfMedia,
      toggleMediaList,
      toggleMedia,
    };
  },
  computed: {},
};
</script>
