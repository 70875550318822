<template>
  <div
    class="w-full rounded border bg-white overflow-hidden hover:shadow-lg hover:border-0 relative transform duration-200"
  >
    <div class="absolute left-0 w-1.5 h-full top-0 bottom-0 bg-secondary-300"></div>
    <div class="w-full flex flex-col py-4 px-6">
      <h2
        class="text-secondary-600 text-4xl md:text-5xl lg:text-4xl xl:text-5xl font-semibold tracking-tight"
      >
        {{ title }}
      </h2>
      <p class="py-1 text-sm">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: [Number, String], default: "" },
    text: { type: String, default: "" },
  },
};
</script>
