<template>
  <div
    class="welcome-box bg-white block w-full justify-center items-center rounded cursor-pointer"
  >
    <component
      :target="href !== '#' ? '_blank' : ''"
      :is="is"
      :to="to"
      :href="href"
      class="w-full flex flex-col p-8"
      rel="noopener noreferrer"
    >
      <div class="w-full flex justify-center items-center">
        <Icon :name="icon" class="w-11 h-11 text-primary-100" />
      </div>
      <p class="text-center text-gray-900 text-lg font-semibold pt-3">{{ t(text) }}</p>
    </component>
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
import Icon from "@/components/atoms/Icon";
import { computed } from "vue";
export default {
  mixins: [translateMixin],
  components: {
    Icon,
  },
  props: {
    href: { type: String, default: "#" },
    text: { type: String, default: "" },
    icon: { type: String, default: "" },
    to: { type: String, default: "" },
  },
  setup(props) {
    const is = computed(() => {
      if (props.to && props.to !== "") {
        return "router-link";
      } else {
        return "a";
      }
    });
    return {
      is,
    };
  },
};
</script>
