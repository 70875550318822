import {createStore} from 'vuex'
import register from './modules/register'
import login from './modules/login'
import user from './modules/user'
import marketingChannels from "./modules/marketingChannels";
import contact from "./modules/contact"
import profile from './modules/profile'
import twoFactorAuth from './modules/twoFactorAuth'
import payOut from './modules/payOut'
import VuexPersistence from 'vuex-persist'


const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        register,
        login,
        user,
        marketingChannels,
        contact,
        profile,
        twoFactorAuth,
        payOut
    },
    plugins: [vuexLocal.plugin]
})
