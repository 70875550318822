<template>
    <div class="w-full bg-white">
        <div class="w-full max-w-screen-xl flex justify-between mx-auto px-4 xl:px-8 2xl:px-0">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
