<template>
    <div
            ref="headerContainer"
            :class="stickyHeader ? 'sticky-navbar' : ''"
            class="w-full flex bg-secondary-100 justify-between md:h-36 items-center z-10"
    >
        <div class="w-full max-w-screen-xl flex justify-between mx-auto relative">
            <router-link class="py-4 px-4 xl:px-8 2xl:pl-0" :to="logoUrl">
                <img
                        src="@/assets/images/bkf-logo.svg"
                        alt="bkf"
                        width="144"
                        height="32"
                        class="w-36"
                />
            </router-link>

            <!-- As Desktop -->
            <div v-if="!asGuest" class="hidden md:flex justify-end items-center pr-4 lg:pr-8">
                <router-link
                        v-for="(item, index) in menu"
                        class="py-2 px-4 lg:px-6 transform hover:scale-101"
                        :to="item.path"
                        :key="`desktop-header-item${index}`"
                        :class="item.path === activePath ? 'active-header-item' : ''"
                >
                    {{ item.name }}
                </router-link>

                <!-- profile menu as desktop -->
                <div v-click-outside="closeProfileDropdown" class="w-auto pl-4 lg:pl-6 relative">
                    <div
                            @click="toggleProfileDropdown"
                            class="w-16 h-16 rounded-full flex items-center relative bg-green-200 overflow-hidden cursor-pointer object-cover"
                    >
                       
                        <Avatar />
                    </div>
                    <div
                            v-show="showProfileDropdown"
                            class="absolute top-16 mt-1 w-auto flex flex-col rounded-lg shadow px-2 bg-white right-0 py-2"
                    >
                        <div
                                @click="closeProfileDropdown"
                                class="py-2 border-b px-4"
                                :class="activePath === '/profile' ? 'active-header-item' : ''"
                        >
                            <router-link to="/profile">{{ t("setting") }}</router-link>
                        </div>
                        <div @click="closeProfileDropdown" class="py-2 px-4">
                            <span class="cursor-pointer" @click="logout">{{ t("logout") }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- As Mobile -->
            <div v-if="!asGuest" class="flex md:hidden items-center mr-2">
                <div
                        @click="setMenuVisibility"
                        class="flex md:hidden w-12 h-auto relative items-center justify-center"
                >
                    <div v-if="!showMenu" class="absolute m-auto -top-4">
                        <Icon name="menu" size="h-5 w-5" class="text-primary-400"/>
                    </div>
                    <p v-if="!showMenu" class="text-primary-400 text-2xs font-bold absolute top-1">
                        {{ t("menu") }}
                    </p>
                    <div v-if="showMenu" class="absolute m-auto -top-4">
                        <Icon name="close" size="h-5 w-5" class="text-primary-400"/>
                    </div>
                </div>
            </div>
            <!-- dropdown menu as mobile -->
            <transition name="slide-fade" mode="out-in">
                <div
                        v-show="showMenu"
                        class="w-full z-10 flex md:hidden flex-col absolute right-0 left-0 top-2 mt-16 bg-secondary-100 pt-4"
                >
                    <div
                            @click="closeMenu"
                            v-for="(item, index) in menu"
                            :class="item.path === activePath ? 'active-header-item' : ''"
                            class="header-item"
                            :key="`mobile-header-item${index}`"
                    >
                        <router-link :to="item.path">{{ item.name }}</router-link>
                    </div>
                    <div class="w-full flex flex-col relative profile-item">
                        <div
                                @click="toggleProfile"
                                class="flex relative justify-between items-center"
                        >
                            <p>{{ t("profile") }}</p>
                            <Icon
                                    v-show="!showProfile"
                                    name="chev-right"
                                    size="h-5 w-5 mr-2"
                                    class="text-primary-400"
                            />
                            <Icon
                                    v-show="showProfile"
                                    name="chev-down"
                                    size="h-5 w-5 mt-2 mr-2"
                                    class="text-primary-400"
                            />
                        </div>
                        <div v-if="showProfile" class="w-full flex flex-col pt-4">
                            <div
                                    class="py-4"
                                    @click="closeMenu"
                                    :class="activePath === '/profile' ? 'active-header-item' : ''"
                            >
                                <router-link to="/profile">{{ t("setting") }}</router-link>
                            </div>
                            <div @click="closeMenu" class="py-4">
                                <span class="cursor-pointer" @click="logout">{{ t("logout") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import translateMixin from "@/mixins/translate.js";
    import vClickOutside from "click-outside-vue3";
    import {computed, onMounted, ref} from "vue";
    import {useRoute} from "vue-router";
    import Icon from "@/components/atoms/Icon";
    import Avatar from "@/components/atoms/Avatar";
    import {useStore} from "vuex";
    import Router from "../../router";

    export default {
        components: {
            Icon,
            Avatar
        },
        mixins: [translateMixin],
        setup() {
            const route = useRoute();
            const store = useStore();
            const trans = translateMixin.methods.t;

            const asGuest = computed(() => {
                return !store.state.login.isLoggedIn;
            });
            const isLoginPage = computed(() => {
                return activePath.value == "/login";
            });

            const logoUrl = computed(() => store.state.login.isLoggedIn ? '/' : '/dashboard');

            const showMenu = ref(false);
            const showProfileDropdown = ref(false);
            const menu = ref([
                {name: trans("dashboard"), path: "/dashboard"},
                {name: trans("reports"), path: "/reports"}
            ]);

            // toggle & close main menu as mobile
            function setMenuVisibility() {
                showMenu.value = !showMenu.value;
            }

            function closeMenu() {
                showMenu.value = false;
                showProfile.value = false;
            }

            // close & toggle profile dropdown menu as desktop
            function closeProfileDropdown() {
                showProfileDropdown.value = false;
            }

            function toggleProfileDropdown() {
                showProfileDropdown.value = !showProfileDropdown.value;
            }

            // toggle profile dropdown menu as mobile
            const showProfile = ref(false);

            function toggleProfile() {
                showProfile.value = !showProfile.value;
            }

            const activePath = computed(() => {
                return route.path;
            });

            // the header will be sticky by scrolling up as mobile & desktop
            const stickyHeader = ref(false);
            const headerContainer = ref(null);

            // detect scroll diriction up or down
            function setHeaderPosition() {
                const st = window.pageYOffset || document.documentElement.scrollTop;
                stickyHeader.value = st > headerContainer.value.offsetTop;
            }

            onMounted(() => {
                window.addEventListener("scroll", setHeaderPosition);
            });

            async function logout() {
                await store.dispatch("logout");
                Router.push({path: '/login'})
            }

            return {
                asGuest,
                logoUrl,
                showMenu,
                showProfileDropdown,
                showProfile,
                setMenuVisibility,
                menu,
                closeMenu,
                closeProfileDropdown,
                toggleProfileDropdown,
                activePath,
                toggleProfile,
                setHeaderPosition,
                headerContainer,
                stickyHeader,
                isLoginPage,
                trans,
                logout,
            };
        },
        directives: {
            clickOutside: vClickOutside.directive,
        },
    };
</script>
