<template>
  <div class="w-full h-full">
    <img
        v-if="!!profilePhoto"
        :src="profilePhoto"
        alt="profile-image"
        height="64"
        width="64"
        class="w-full h-full flex object-cover rounded-full"
    />
    <div v-else class="w-full h-full bg-gray-200 flex justify-center items-center">
        <p v-if="userName" :class="`${fontClass} font-bold text-primary-200 uppercase`">{{userName}}</p>
    </div>
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
export default {
    props: {
        fontClass: { type: String, default: "text-3xl"}
    },
    setup() {
        const store = useStore();
        const profilePhoto = computed(() => {
            const user = store.state.user.user;
            let imageSrc = "";
            if(user){
                imageSrc = user.profile_photo??imageSrc;
            }
            return imageSrc;
        });
        const userName = computed(() => {
            const user = store.state.user.user;
            let name = "";
            if(user && user.first_name && user.last_name){
                name = user.first_name.substring(0,1)+ user.last_name.substring(0,1);
            }
            return name;
        });
        return {
            profilePhoto,
            userName
        }
    }
}
</script>

<style>

</style>