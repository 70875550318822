<template>
  <div class="w-full">
      <div class="w-full flex bg-contain bg-two-persons-cut bg-no-repeat bg-top min-w-full h-48">
      </div>
      <div class="w-full text-center flex flex-col text-lg sm:text-xl justify-center">
          <p class="py-2">{{t('noPayouts')}}</p>
          <p>{{t('putYourCode')}}</p>
      </div>
  </div>
</template>

<script>
import translateMixin from "@/mixins/translate.js";
export default {
  name: "CookieBanner",
  components: {
  },
  mixins: [translateMixin]
}
</script>
