import { ref, computed } from 'vue'
import translateMixin from "@/mixins/translate.js";
export default function useValidation() {
    
    const errors = ref({})
    const hasErrors = computed(() => {
      return Object.keys(errors.value).length > 0;
    });

    // translate function
    const trans = translateMixin.methods.t

    const  validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        errors.value.email = `E-Mail-Adresse${trans("errors.isInvalid")}`;
      }
    }

    // validate the password & confirmation if equal & strong
    const validateRegisterPasswords = (password, confirmPassword) => {
      let strongPassword = new RegExp(
        "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
      );
      if (!strongPassword.test(password)) {
        errors.value.password = trans("errors.passwordFormat");
      }
      if (!!confirmPassword && password !== confirmPassword) {
        errors.value.confirmPassword = trans("errors.bothPasswordsNotEqual");
      }
    }
    // validate the value if has number (here only string should be)
    const validateText = (name, value) => {
      if (/[^a-züößä ]/i.test(value)) {
        errors.value[name] = `${trans("errors.wrongFormat")}`;
      }
    }
    const validateNumber = (name, value) => {
      if (/\D/.test(value)) {
        errors.value[name] = `${trans("errors.wrongFormat")}`;
      }
    }
    // validate textarea if min-length is less than required 
    const validateMinLength = (name, length, value) => {
      if(value.length < length) {
        errors.value[name] = `${trans("errors.notEnoughCharacter")}`;
      }
    }
    // validate check input if is-checked
    const validateCheckBox = (name, value, errorMessage) => {
      if(!value) {
        errors.value[name] = trans(errorMessage)
      }
    }
    // validate check input if is-checked
    const validateCode = (slug, name, value) => {
      const codeRegex =  /^[0-9a-zA-Z_-]+$/;
      if(!value) {
        errors.value[slug] = `${name} ${trans("errors.emptyInput")}`;
      } else if(!codeRegex.test(String(value))) {
        errors.value[slug] = `${name} ${trans("errors.wrongFormat")}`;
      }
      else if(value && value.length < 6) {
        errors.value[slug] = `${trans("errors.notEnoughCharacter")}`;
      }
    }
    return {
      errors,
      hasErrors,
      validateEmail,
      validateRegisterPasswords,
      validateText,
      validateMinLength,
      validateCheckBox,
      validateCode,
      validateNumber
    }
}