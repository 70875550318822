<template>
  <EditBox ref="editBox" :title="t('loginAndPassword')" editbar>
    <template v-slot:content>
      <div class="w-full flex flex-col py-4">
        <div :class="classes.imageContainer">
          <img
            v-if="!!personalData.imageSrc"
            :src="personalData.imageSrc"
            alt="profile-image"
            height="112"
            width="112"
            class="w-full h-full flex object-cover rounded-full"
          />
          <Avatar v-else  fontClass="text-5xl" />
          
        </div>
        <div class="w-full pt-4">
          <div class="w-full text-sm py-2">
            <p class="text-gray-600 py-1">{{ t("name") }}</p>
            <p>{{ `${personalData.firstName} ${personalData.lastName}` }}</p>
          </div>
          <div class="w-full text-sm py-2">
            <p class="text-gray-600 py-1">{{ t("emailAddress") }}</p>
            <p>{{ personalData.email }}</p>
          </div>
          <div class="w-full text-sm py-2">
            <p class="text-gray-600 py-1">{{ t("password") }}</p>
            <p>*************</p>
            <p></p>
          </div>
        </div>
      </div>
    </template>

    <!-- Edit Modal -->
    <template v-slot:modal>
      <div class="w-full">
        <form
          @submit.prevent="saveData"
          class="w-full grid gap-4 grid-cols-1 pt-8 pb-20 text-left"
        >
          <div class="w-28 h-28 relative mx-auto">
            <div v-if="showProfileImage && loginData.imageSrc" @click="deleteProfileImage" :class="classes.deleteContainer">
              <Icon name="delete" class="w-2 h-2 flex text-gray-800 cursor-pointer" />
            </div>
            <div :class="classes.imageContainer">
              <img
                v-if="showProfileImage && loginData.imageSrc"
                :src="loginData.imageSrc"
                alt="profile-image"
                height="112"
                width="112"
                class="w-full h-full flex object-cover rounded-full"
              />
              <label
                for="profile_img"
                v-else
                class="w-28 h-28 flex items-center justify-center bg-gray-200 rounded-full"
              >
                <Icon name="camera" class="w-10 h-10 flex cursor-pointer text-gray-800" />
                <input
                  aria-label="profile_img"
                  type="file"
                  id="profile_img"
                  accept="image/png, image/gif, image/jpeg"
                  class="hidden"
                  @change="onImageChange"
                />
              </label>
            </div>
          </div>
          <div class="w-full" v-if="errors && errors.profile_photo">
            <p class="text-xs text-red-500 text-center">{{errors.profile_photo}}</p>
          </div>
          <div class="w-full grid gap-4 grid-cols-1 md:grid-cols-2 mt-4">
            <TextInput
              :placeholder="t('firstName')"
              :label="t('firstName')"
              v-model="loginData.firstName"
              required
              name="firstName"
              :errorMessage="errors['firstName']"
            />
            <TextInput
              :placeholder="t('lastName')"
              :label="t('lastName')"
              required
              v-model="loginData.lastName"
              name="lastName"
              :errorMessage="errors['lastName']"
            />
          </div>
          <TextInput
            type="email"
            :placeholder="t('emailAddress')"
            :label="t('emailAddress')"
            required
            v-model="loginData.email"
            name="email"
            :errorMessage="errors['email']"
          />
          <TextInput
            type="password"
            :placeholder="t('password')"
            :label="t('password')"
            v-model="loginData.password"
            name="password"
            :errorMessage="errors['password']"
          />
          <TextInput
            type="password"
            :placeholder="t('confirmPassword')"
            :label="t('confirmPassword')"
            v-model="loginData.confirmPassword"
            name="confirmPassword"
            :errorMessage="errors['confirmPassword']"
          />
          <div class="w-full sm:w-1/2 mx-auto mt-8">
            <Button type="submit" primary :text="t('confirm')" class="w-full" />
            <Button
              type="button"
              @click="closeEditModal"
              :text="t('cancel')"
              class="w-full text-center underline mt-2"
            />
          </div>
        </form>
      </div>
    </template>
  </EditBox>
</template>

<script>
import EditBox from "@/components/atoms/EditBox";
import translateMixin from "@/mixins/translate.js";
import useValidation from "@/mixins/useValidation.js";
import { ref, computed } from "vue";
import Button from "@/components/atoms/Button";
import TextInput from "@/components/forms/TextInput";
import Icon from "@/components/atoms/Icon";
import Avatar from "@/components/atoms/Avatar";
import {useStore} from "vuex";
export default {
  mixins: [translateMixin],
  components: {
    EditBox,
    TextInput,
    Button,
    Icon,
    Avatar,
  },
  setup() {
    const store = useStore();
    const trans = translateMixin.methods.t;

    // refs
    const editBox = ref(null);
    function closeEditModal() {
      editBox.value.closeEditModal();
    }
    // later will depend if there is a photo
    const showProfileImage = ref(true);
    function deleteProfileImage() {
      showProfileImage.value = false;
      createImage()
    }
    let loginData = ref(
      { firstName: "" ,
        lastName: "" ,
        email: "" ,
        password: "" ,
        confirmPassword: "",
        imageSrc:"",
        profilePhoto:""
      }
    );

    const personalData = computed(() => {
      const user = store.state.user.user;
      let profilePhoto = "";

      let data = {
        firstName: "",
        lastName: "",
        email: "",
        imageSrc: profilePhoto,
      }
      if(user){
        data = {
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          imageSrc: user.profile_photo??profilePhoto,
        }
        setLoginData(data)
      }
      return data;
    });

    function setLoginData(data) {
      loginData.value = data
    }
    // upload image
    function onImageChange(e) {
      // reset image error every upload
      errors.value.profile_photo = null
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length){
        return;
      } else {
        // if the image size > 1MB
        if (files[0].size > 1024 * 1024) {
          errors.value.profile_photo = trans('errors.imageMaxSize')
        } else {
          createImage(files[0]);
        }
      }

    }
    function createImage(file) {
      if(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          loginData.value.imageSrc = e.target.result;
          loginData.value.profilePhoto = file;
        };
        reader.readAsDataURL(file);
        showProfileImage.value = true;
      }
    }

    //style
    const classes = computed(() => {
      return {
        imageContainer: {
          "w-28 h-28 rounded-full bg-cover flex justify-center items-center overflow-hidden": true,
        },
        deleteContainer: {
          "absolute -bottom-1 right-2 w-7 h-7 flex justify-center rounded-full bg-gray-200 items-center border border-gray-400": true,
          "cursor-not-allowed": !showProfileImage.value,
        },
      };
    });


    const {
      errors,
      hasErrors,
      validateEmail,
      validateRegisterPasswords,
      validateText,
    } = useValidation();

    function validateAll() {
      errors.value = {};
      validateEmail(loginData.value.email);
      validateText("firstName", loginData.value.firstName);
      validateText("lastName", loginData.value.lastName);
    }

   async function saveData() {
     this.errors = {}
     validateAll();
      // if there is no error get saveData api
      if (!hasErrors.value) {
        try {
          const form = loginData.value;
          const formData = new FormData();
          formData.append('first_name',form.firstName)
          formData.append('last_name',form.lastName)
          formData.append('email',form.email)
          if(form.confirmPassword){
            formData.append('password',form.password)
            formData.append('password_confirmation',form.confirmPassword)
          }
          if(form.profilePhoto){
            formData.append('profile_photo',form.profilePhoto)
          }
          formData.append('_method','PUT')
          await store.dispatch("profile/setProfileData", formData)
          showProfileImage.value = true;
          closeEditModal();
        } catch (errors) {
          this.errors = errors
        }
      }
    }
    return {
      classes,
      onImageChange,
      deleteProfileImage,
      createImage,
      showProfileImage,
      loginData,
      saveData,
      validateEmail,
      errors,
      hasErrors,
      validateRegisterPasswords,
      validateAll,
      editBox,
      closeEditModal,
      trans,
      personalData,
    };
  },
};
</script>
