<template>
  <div class="w-full pb-4 " >
    <!-- section 1 Personal Data -->
    <div :class="classes.sectionContainer">
      <div :class="classes.editorContainer">
        <Badge>{{ t("personalData") }}</Badge>
        <div @click="goToStep(1)" :class="classes.iconContainer">
          <Icon name="edit-pin" :class="classes.icon" />
        </div>
      </div>
      <div class="w-full">
        <p>{{user.company}}</p>
        <p>{{user.street}}</p>
        <p>{{user.city}}</p>
        <p>{{user.zip}}</p>
        <p>{{user.vat_no}}</p>
      </div>
    </div>

    <!-- section 2 Bank account -->
    <div :class="classes.sectionContainer">
      <div :class="classes.editorContainer">
        <Badge>{{ t("bankAccount") }}</Badge>
        <div @click="goToStep(2)" :class="classes.iconContainer">
          <Icon name="edit-pin" :class="classes.icon" />
        </div>
      </div>
      <div class="w-full">
        <div class="flex flex-col sm:flex-row">
          <p class="font-bold w-20">{{ t("iban") }}</p>
          <p>{{ typeof payOutData != "undefined" ?  payOutData.iban : ''}}</p>
        </div>
        <div class="flex flex-col sm:flex-row">
          <p class="font-bold w-20">{{ t("bic") }}</p>
          <p>{{ typeof payOutData != "undefined" ? payOutData.bic : ''}}</p>
        </div>
      </div>
    </div>

    <!-- section 3 BonusPayment -->
    <div :class="classes.sectionContainer">
      <div :class="classes.editorContainer">
        <Badge>{{ t("BonusPayment") }}</Badge>
        <div @click="goToStep(2)" :class="classes.iconContainer">
          <Icon name="edit-pin" :class="classes.icon" />
        </div>
      </div>
      <div class="w-full">
        <p>{{ typeof payOutData != "undefined" ? t(payOutData.payout_interval) : ''}}</p>
      </div>
    </div>

    <!-- section 4 More Info -->
    <div :class="classes.sectionContainer">
      <div :class="classes.editorContainer">
        <Badge>{{ t("beAcquaintedWith") }}</Badge>
        <div @click="goToStep(3)" :class="classes.iconContainer">
          <Icon name="edit-pin" :class="classes.icon" />
        </div>
      </div>
      <div class="w-full py-2 break-all">
        <p class="font-bold py-1">{{ t("brieflyIntroduceLabel") }}</p>
        <p class="pb-3">
          {{user.self_intro}}
        </p>
        <p class="font-bold py-1">{{ t("howDidYouKnowUs") }}</p>
        <p class="pb-3">
          {{user.marketing_plan}}
        </p>
        <p class="font-bold py-1">{{ t("businessField") }}</p>
        <p class="pb-3">
          {{user.industry}}
        </p>
      </div>
    </div>
    <!-- section 5 More Info -->
    <div :class="classes.sectionContainer">
      <div :class="classes.editorContainer">
        <Badge>{{ t("marktingChannels") }}</Badge>
        <div @click="goToStep(4)" :class="classes.iconContainer">
          <Icon name="edit-pin" :class="classes.icon" />
        </div>
      </div>
      <div class="w-full py-2">
        <p class="font-bold">
          {{ t("online") }}
        </p>
        <div class="w-full flex flex-col md:flex-row items-center py-2" v-for="item in onlineMarketingChannel" :key="item.name" >
          <p class="flex w-48">{{item.name}}</p>
          <div class="w-full text-gray-800 bg-gray-100 rounded p-2 flex">
            <p>{{item.profile_url}}</p>
          </div>
        </div>
        <p class="font-bold mt-4">
          {{ t("offline") }}
        </p>
        <div v-if="offlineMarketingChannel && offlineMarketingChannel.description" class="w-full text-gray-800 bg-gray-100 rounded p-2 flex my-3 break-all">
          <p>
            {{offlineMarketingChannel.description}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Badge from "@/components/atoms/Badge";
import Icon from "@/components/atoms/Icon";
import translateMixin from "@/mixins/translate.js";
import {computed, watch, ref} from "vue";
import {useStore} from "vuex";

export default {
  mixins: [translateMixin],
  components: {
    Badge,
    Icon,
  },
  setup(props, { emit }) {
    const store = useStore();

    // *** Data
    const user = computed(() => {
      return store.state.user.user
    });
    const payOutData = computed(() => {
      return store.state.payOut.payOutData
    });
    const marketingChannels = computed(() => {
      return store.state.marketingChannels.marketingChannels
    });
    let offlineMarketingChannel = ref({});
    let onlineMarketingChannel = ref([]);
    watch(
            () => marketingChannels.value,
            () => {
              onlineMarketingChannel.value = [];
              offlineMarketingChannel.value = {};
               marketingChannels.value.map((item) =>{
                if(item.type === 'online'){
                  onlineMarketingChannel.value.push({name : item.name,profile_url:item.profile_url});
                  }
                 if(item.type === 'offline'){
                   offlineMarketingChannel.value.description = item.description
                 }
              });
            }
    );

    // *** computed
    // style of sections
    const classes = computed({
      get: () => {
        return {
          sectionContainer: {
            "w-full flex flex-col py-4 border-b border-gray-400": true,
          },
          editorContainer: {
            "w-full flex justify-between items-center py-4": true,
          },
          iconContainer: {
            "w-auto cursor-pointer": true,
          },
          icon: {
            "w-5 h-5": true,
          },
        };
      },
    });

    // *** Methods
    function goToStep(step) {
      emit("setCurrentStep", step);
    }

    return {
      user,
      classes,
      payOutData,
      offlineMarketingChannel,
      onlineMarketingChannel,
      goToStep,
    };
  },
};
</script>
