<template>
  <DefaultLayout>
    <div class="w-full pb-16 text-sm md:text-base sm:px-6">
      <div class="py-8">
        <h1 class="text-3xl font-bold text-gray-800 leading-tight py-8">
          Nutzungsbedingungen für das BKF-Partnerprogramm
        </h1>
        <TermsBody />
      </div>
    </div>
  </DefaultLayout>
</template>
<script>
import DefaultLayout from "@/components/layouts/Default";
import TermsBody from "@/components/atoms/TermsBody";

export default {
  components: {
    DefaultLayout,
    TermsBody,
  },
};
</script>
