<template>
  <a :href="href" class="text-secondary-500" target="_blank" rel="noopener noreferrer">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: ["href"],
};
</script>
